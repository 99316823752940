import { LOCALES } from 'i18n-translations/locales.js';

const keys = {
	[LOCALES.GERMAN]: {
		conversation: 'Gespräch',
		files: 'Dateien',
		health: 'Die Gesundheit',
		visits: 'Besuche',
		roundingVisits: 'Besuche',
		healthRecords: 'Gesundheitsakten',
		hello: 'Hallo',
		hideProfile: 'Profil von {value} ausblenden',
		showProfile: 'Profil von {value} anzeigen',
		familyPhysician: 'Hausarzt',
		assignedToMe: 'Mir zugewiesen',
		otherRequests: 'Andere Anfragen',
		requestSentFrom: 'Anfrage gesendet von',
		healthConcernsDescription: 'Beschreibung der gesundheitlichen Bedenken',
		symptomsSigns: 'Symptome und Anzeichen',
		viewProfile: 'Profil anzeigen',
		viewAlerts: 'Benachrichtigungen anzeigen',
		requestedAppointment: 'Wunschtermin',
		meetingPlace: 'Treffpunkt',
		location: 'Ort',
		dateOfBirth: 'Geburtsdatum',
		sex: 'Geschlecht',
		accepted: 'Akzeptiert',
		acceptRequest: 'Annehmen',
		wrapUp: 'Einpacken',
		rejected: 'Abgelehnt',
		canceled: 'Abgesagt',
		completed: 'Abgeschlossen',
		unassigned: 'Nicht zugewiesen',
		rejectRequest: 'Anfrage ablehnen',
		male: 'Männlich',
		female: 'Weiblich',
		nonBinary: 'Nicht-binär',
		gender: 'Geschlecht',
		helloHealthAppointmentRequest: 'hellocare Terminanfrage',
		appointmentConfirmation: 'Terminbestätigung',
		areYouSureAcceptFollowingAppointment: 'Sind Sie sicher, dass Sie den folgenden Termin annehmen möchten?',
		addToGoogleCalendar: 'Zu Google Kalender hinzufügen',
		addToOutlookCalendar: 'Zum Outlook-Kalender hinzufügen',
		clientNotAuthenticated: 'Client nicht authentifiziert',
		appointments: 'Termine',
		calendarOverview: 'Kalenderübersicht',
		appointmentRequests: 'Terminanfragen',
		appointmentsHistory: 'Terminverlauf',
		nextAppointmentAt: 'Nächster Termin um',
		noUpcomingAppointment: 'Sie haben keinen anstehenden Termin',
		yesterday: 'Gestern',
		today: 'Heute',
		lastAmountOfDays: 'Letzte {value} Tage',
		lastMonth: 'Im vergangenen Monat',
		lastYear: 'Letztes Jahr',
		lastOnline: 'Zuletzt Online',
		monthToDate: 'Diesen Monat',
		quarterToDate: 'Bisheriges Quartal',
		yearToDate: 'Der bisherige Jahresverlauf',
		firstQuarterOf: '1. Quartal von {value}',
		secondQuarterOf: '2. Quartal von {value}',
		thirdQuarterOf: '3. Quartal von {value}',
		fourthQuarterOf: '4. Quartal von {value}',
		customRange: 'Benutzerdefinierten Bereich',
		selectRange: 'Wählen Sie Bereich aus',
		jul: 'Juli',
		unauthorize: 'Autorisierung aufheben',
		authorize: 'Autorisieren',
		connectGoogleAccount: 'Google-Konto verbinden',
		connectOutlookAccount: 'Outlook-Konto verbinden',
		googleAccount: 'Google Benutzerkonto',
		outlookAccount: 'Outlook-Konto',
		calendarAccounts: 'Kalenderkonten',
		waitingRoom: 'Wartezimmer',
		primaryCare: 'Grundversorgung',
		healthcareProgressAndDiagnosisChecking: 'Gesundheitsfortschritt und Diagnosekontrolle',
		yourMobileDevice: 'Ihr mobiles Gerät',
		clinic: 'Klinik',
		days: 'Tage',
		anErrorOccurred: 'Ein Fehler ist aufgetreten.',
		assignCompanionDeviceError: 'Für jeden Begleitmodus kann jeweils nur ein Gerät ausgewählt werden.',
		recaptchaLoadError: 'Fehler beim Laden des Recaptcha-Skripts.',
		noAppointmentsToShowClickAuth:
			'Keine Termine zum Anzeigen. Bitte klicken Sie auf „Autorisieren“, um die mit Ihrem Konto verknüpften Termine zu erhalten.',
		noAppointmentsToShowClickCreateAppointment:
			'Sie haben kein Termin. Bitte klicken Sie auf "Termin erstellen", um den Termin zu erstellen.',
		createAppointment: 'Termin erstellen',
		inAMoment: 'In einem Moment',
		personsProfile: '{value}s Profil',
		topic: 'Thema',
		inviteMember: 'Mitglied einladen',
		fullName: 'Vollständiger Name',
		facility: 'Einrichtung',
		profession: 'Beruf',
		birthday: 'Geburtstag',
		address: 'Adresse',
		phone: 'Telefon',
		audioCall: 'Audioanruf',
		videoCall: 'Videoanruf',
		cameraFeed: 'Kamera-Feed',
		writeMessage: 'Nachricht schreiben',
		rapidCall: 'Schneller Anruf',
		rapidCallDescription:
			'Wenn dies aktiviert ist, ist die Option zum Einleiten eines Schnellanrufs auf der Warenkorbseite verfügbar.',
		regularCall: 'Regelmäßiger Anruf',
		regularCallDescription:
			'Wenn dies aktiviert ist, ist auf der Warenkorbseite die Option zum Einleiten eines regulären Anrufs verfügbar.',
		admissionCall: 'Zulassungsaufruf',
		admissionCallDescription:
			'Wenn dies aktiviert ist, ist auf der Warenkorbseite die Option zum Initiieren eines Zulassungsanrufs verfügbar.',
		submitNewCase: 'Neuen Fall einreichen',
		submitNewCaseDescription:
			'Wenn dies aktiviert ist, wird auf dem Bildschirm nur eine Option angezeigt, um eine Sitzung zu initiieren.',
		sharedQueue: 'Gemeinsame Warteschlange',
		sharedQueueDescription:
			'Wenn dies aktiviert ist, werden initiierte Anrufe an alle verfügbaren Ärzte in einem Krankenhaus gesendet.',
		sessionAlreadyReceived: 'Diese Sitzung wurde bereits von einem anderen Anbieter erhalten.',
		join: 'Beitreten',
		type: 'Typ',
		name: 'Name',
		dateTime: 'Terminzeit',
		sentBy: 'Gesendet von',
		noFilesFound: 'Sie haben keine Dateien mit {value} geteilt',
		noFilesParagraph: 'Versuchen Sie es, indem Sie ein Bild, ein Video oder ein Dokument senden!',
		deleteMsgTitle: 'Nachricht für mich löschen',
		deleteMsgWarning: 'Möchten Sie diese Nachricht wirklich löschen?',
		deleteMediaTitle: 'Medien für mich selbst löschen',
		deleteMediaWarning: 'Möchten Sie dieses Medium wirklich löschen?',
		actionUndone: 'Diese Aktion kann nicht rückgängig gemacht werden.',
		summary: 'Zusammenfassung',
		heart: 'Herz',
		heartRate: 'Herzschlag',
		beatsPerMinute: 'Schläge pro Minute',
		mmOfMercury: 'Millimeter Quecksilbersäule',
		percentage: 'Prozentsatz',
		bloodPressure: 'Blutdruck',
		perfusionIndex: 'Durchblutungsindex',
		activity: 'Aktivität',
		steps: 'Schritte',
		flightsClimbed: 'steigende Treppen',
		walkingRunningDistance: 'Geh- und Laufstrecke',
		bodyMeasurements: 'Körpermaße',
		weight: 'Gewicht',
		scale: 'Skala',
		pinFeed: 'An Hauptbildschirm anheften',
		unpinFeed: 'Vom Hauptbildschirm lösen',
		bodyTemperature: 'Körpertemperatur',
		leanBodyMass: 'Magere Körpermasse',
		bodyFatPercentage: 'Körperfettanteil',
		bmi: 'Body-Mass-Index',
		bMI: 'BMI',
		respiratory: 'Atmung',
		oxygenSaturation: 'Sauerstoffsättigung',
		respiratoryRate: 'Atemfrequenz',
		sleep: 'Schlafen',
		sleepAnalysis: 'Schlafanalyse',
		bloodGlucose: 'Blutzucker',
		stethoscope: 'Stethoskop',
		noVisits: 'Für diesen Raum gibt es keine Besuche.',
		hasVisited: '{value1} hat {value2} besucht',
		selectVisitsDate: 'Besuchsdatum auswählen',
		enterDetailsOfVisitReason: 'Schreiben Sie den Grund auf, warum der Patient hier ist. ',
		allergy: 'Allergie',
		medication: 'Medikation',
		condition: 'Bedingung',
		observation: 'Überwachung',
		immunization: 'Immunisierung',
		procedure: 'Verfahren',
		device: 'Gerät',
		carePlan: 'Pflegeplan',
		manifestation: 'Manifestation',
		criticality: 'Kritisch',
		substance: 'Substanz',
		status: 'Status',
		medicationName: 'Medikamentenname',
		dateWritten: 'Datum geschrieben',
		startDate: 'Anfangsdatum',
		endDate: 'Endtermin',
		prescriber: 'Verschreiber',
		diagnosis: 'Diagnose',
		dateRecorded: 'Aufnahmedatum',
		severity: 'Schwere',
		category: 'Kategorie',
		value: 'Wert',
		report: 'Bericht',
		performer: 'Dienstleister',
		date: 'Datum',
		vaccineType: 'Impfstofftyp',
		procedureName: 'Prozedurname',
		deviceType: 'Gerätetyp',
		deviceModel: 'Gerätemodell',
		deviceUDI: 'Geräte-UDI',
		deviceStatus: 'Gerätestatus',
		expiryDate: 'Verfallsdatum',
		diseases: 'Krankheiten',
		categories: 'Kategorien',
		goals: 'Ziele',
		appointmentDate: 'Termin',
		noAppointmentsAvailableSelectDifferentDate: 'Es sind keine Termine verfügbar, bitte wählen Sie ein anderes Datum aus.',
		setUpAvailabilityHours: 'Bitte richten Sie die Verfügbarkeitszeiten ein, bevor Sie einen Termin vereinbaren.',
		whereMeetingPlace: 'Wo soll dieses Treffen stattfinden',
		recurringMeeting: 'Wiederkehrendes Treffen',
		recurrenceEndDate: 'Enddatum der Wiederholung',
		description: 'Beschreibung',
		unavailable: 'Nicht verfügbar',
		selectAnotherColor: 'Wählen Sie eine andere Farbe',
		interfaceThemeColor: 'Farbe des Interface-Designs',
		authNone: 'Keiner',
		authExternalProvider: 'Authentifikator',
		authEmail: 'Email',
		invalidCode: 'Ungültiger Code. Bitte versuche es erneut!',
		emailChanged:
			'Ihre hellocare-E-Mail wurde erfolgreich geändert. Bitte melden Sie sich mit Ihrer neuen E-Mail-Adresse erneut an.',
		goToLoginPage: 'Klicken Sie hier, um zur Anmeldeseite zu gelangen',
		currentEmail: 'Aktuelle E-Mail',
		newEmail: 'Neue e-mail',
		enableMultipleFeedAudio: 'Audio für mehrere Feeds aktivieren',
		available: 'Verfügbar',
		changeLanguage: 'Sprache ändern',
		english: 'Englisch',
		albanian: 'Albanisch',
		editProfile: 'Profil bearbeiten',
		accountSettings: 'Account Einstellungen',
		logOut: 'Ausloggen',
		noNotifications: 'Sie haben keine Benachrichtigungen',
		noConversations: 'Sie haben keine Gespräche mit Patienten',
		toggledPatientCamera: 'Sie haben die Kamera des Patienten ausgeschaltet.',
		turnCameraOn: 'Kamera einschalten',
		turnCameraOff: 'Kamera ausschalten',
		initiatingCall: 'Ruf einleiten',
		endCall: 'Anruf beenden',
		turnOnMic: 'Mikrofon einschalten',
		turnOffMic: 'Mikrofon aus',
		hidePTZ: 'PTZ ausblenden',
		showPTZ: 'PTZ anzeigen',
		showHealth: 'Gesundheit anzeigen',
		hideHealth: 'Gesundheit ausblenden',
		takeScreenshot: 'Mach ein Bildschirmfoto',
		otoscopeCamera: 'Otoskop',
		selectBackground: 'Hintergrund auswählen',
		streamSettings: 'Stream-Einstellungen',
		streamSettingsModalTitle: 'Überprüfen Sie Ihre Audio- und Videoeinstellungen',
		yourAudioAndVideoSettings: 'Ihre Audio- und Videoeinstellungen',
		usingDifferentMic: 'Die Verwendung unterschiedlicher Mikrofon- und Lautsprechergeräte kann Echos verursachen.',
		streamSettingsSpeaker: 'Lautsprecher',
		filterNone: 'Keiner',
		filterBlur: 'Verwischen',
		tvControls: 'TV-Steuerung',
		startScreenSharing: 'Bildschirmfreigabe starten',
		stopScreenSharing: 'Bildschirmfreigabe beenden',
		presenting: 'Präsentieren',
		you: 'Sie',
		patient: 'Geduldig',
		disableNightvision: 'night vision deaktivieren',
		enableNightvision: 'night vision aktivieren',
		aiControls: 'AI-Steuerung',
		connect: 'Verbinden',
		measure: 'Messen',
		connecting: 'Verbinden',
		measuring: 'Messung',
		overview: 'Überblick',
		seeMoreData: 'Weitere Daten anzeigen',
		historicalData: 'Historische Daten',
		first: 'Erste',
		second: 'zweite',
		third: 'dritte',
		daily: 'Täglich',
		weeklyOn: 'Wöchentlich am',
		monthlyThe: 'Monatlich die',
		annuallyOn: 'Jährlich am',
		everyWorkday: 'Jeden Werktag (Montag bis Freitag)',
		prev: 'Zurück',
		next: 'Nächste',
		switchHealthSystem: 'Gesundheitssystem wechseln',
		createNewHealthSystem: 'Neues Gesundheitssystem schaffen',
		editHealthSystem: 'Gesundheitssystem bearbeiten',
		createHospital: 'Erstellen Sie ein Krankenhaus',
		communicateWithARoom: 'Um mit einem Raum zu kommunizieren',
		monitorRoom: 'Um Räume zu überwachen',
		clickAddIconInTree: 'klicken Sie in der Baumansicht auf das Symbol „Hinzufügen“ neben dem Raumnamen',
		openHospitalChooseDepartment: 'Bitte eröffnen Sie ein Krankenhaus, wählen Sie die Abteilung',
		clickFloorSelectRoom: 'Klicken Sie auf eine Etage und wählen Sie einen Raum aus',
		scheduleMeeting: 'Ein Treffen planen',
		makeAppointment: 'Einen Termin machen',
		switchHSorRegion: 'Gesundheitssystem oder Region wechseln',
		selectHealthSystem: 'Gesundheitssystem auswählen',
		selectIntegrationType: 'Integrationstyp auswählen',
		selectGroupRole: 'Gruppenrolle auswählen',
		selectHealthSystemApply: 'Gesundheitssystem auswählen, um Active Directory anzuwenden',
		selectGroupRoleApply: 'Rolle auswählen, um Active Directory anzuwenden',
		selectRegion: 'Region wählen',
		newDevice: 'Neues Gerät',
		enterSerialNumber: 'Seriennummer eingeben',
		deviceName: 'Gerätename',
		deviceMode: 'Gerätemodus',
		deviceOfflineStatus: 'Einige Geräte sind möglicherweise offline.',
		version: 'Ausführung',
		deviceFamilyName: 'Gerätefamilienname',
		deviceLocation: 'Gerätestandort',
		activationCode: 'Aktivierungscode',
		savedSessions: 'Gespeicherte Ansichten',
		updateSession: 'Sitzung aktualisieren',
		saveSession: 'Speichern Sie diese Ansicht',
		currentSession: 'Aktuelle Ansicht',
		loadingSession: 'Sitzung wird geladen',
		alreadyMonitoringPatients: 'Sie befinden sich bereits in einem Anruf oder überwachen Patienten.',
		tryAgainAfterSessionEnds: 'Bitte versuchen Sie es erneut, nachdem Sie diese Sitzung beendet haben.',
		disconnectedFromMonitoring: 'Sie wurden von der Überwachung getrennt',
		youCantSwitchCam:
			'Sie können nicht zu {value} zurückwechseln, da die physischen Datenschutzschaltflächen aktiviert wurden. Diese Schaltflächen können nur manuell deaktiviert werden. Bitte wenden Sie sich an Ihren Administrator.',
		cameraBlockedTitle: 'Erlaube hellocare, ihre Kamera zu verwenden',
		microphoneBlockedTitle: 'Erlaube hellocare, ihr Mikrofon zu verwenden',
		cameraAndMicrophoneBlockedTitle: 'Erlaube hellocare, ihre Kamera und Mikrofon zu verwenden',
		cameraBlockedMessage:
			'hellocare benötigt Zugriff auf Ihre Kamera, damit Patienten Sie sehen können. Überprüfen Sie die obere linke Seite des Bildschirms und klicken Sie auf Zulassen.',
		microphoneBlockedMessage:
			'hellocare benötigt Zugriff auf Ihr Mikrofon, damit Patienten Sie hören können. Überprüfen Sie die obere linke Seite des Bildschirms und klicken Sie auf Zulassen.',
		cameraAndMicrophoneBlockedMessage:
			'hellocare benötigt Zugriff auf Ihre Kamera und Ihr Mikrofon, damit Patienten Sie hören können. Überprüfen Sie die obere linke Seite des Bildschirms und klicken Sie auf Zulassen.',
		microphoneBlockedTitleGeneric: 'Mikrofon ist blockiert',
		microphoneBlockedMessageGeneric:
			'hellocare benötigt Zugriff auf Ihr Mikrofon. Klicken Sie in der Adressleiste Ihres Browsers auf das Symbol „Kamera blockiert“ und aktualisieren Sie dann.',
		cameraBlockedTitleGeneric: 'Kamera ist blockiert',
		cameraBlockedMessageGeneric:
			'hellocare benötigt Zugriff auf deine Kamera. Klicken Sie in der Adressleiste Ihres Browsers auf das Symbol „Kamera blockiert“ und aktualisieren Sie dann.',
		cantFindMicrophoneTitle: ' Ihr Mikrofon kann nicht gefundet werden',
		cantFindMicrophoneMessage:
			'Bitte überprüfen Sie auf Ihrem System, ob ein Mikrofon angeschlossen ist und Sie Mikrofonberechtigungen erteilt haben. Wenn nicht, schließen Sie einen an oder erlauben Sie Berechtigungen und starten Sie Ihren Browser neu. Solange Sie das nicht tun, können die Patienten Sie nicht hören.',
		cantFindCameraTitle: 'Ihre Kamera kann nicht gefundet werden',
		cantFindCameraMessage:
			'Bitte überprüfen Sie auf Ihrem System, ob eine Kamera angeschlossen ist und Sie Kameraberechtigungen zugelassen haben. Wenn nicht, schließen Sie einen an oder erlauben Sie Berechtigungen und starten Sie Ihren Browser neu. Solange Sie das nicht tun, können die Patienten Sie nicht sehen.',
		popUpCameraBlockedTitle: 'Ihre Kamera ist blockiert',
		popUpCameraBlockedMessage: 'Klicken Sie oben rechts auf Ihrem Bildschirm auf das Symbol „Kamera blockiert',
		popUpMicrophoneBlockedTitle: 'Ihr Mikrofon ist gesperrt',
		popUpMicrophoneBlockedMessage: 'Klicken Sie oben rechts auf Ihrem Bildschirm auf das Symbol „Mikrofon blockiert',
		popUpCameraAndMicrophoneBlockedTitle: 'Ihre Kamera ist blockiert',
		popUpCameraAndMicrophoneBlockedMessage: 'Klicken Sie oben rechts auf Ihrem Bildschirm auf das Symbol „Kamera blockiert',
		deviceUnavailable: 'Gerät nicht verfügbar',
		virtualCaseProviderIsInCall:
			'Ein Virtual Care Provider führt mit diesem Gerät ein Gespräch. Sie können diese Steuerelemente verwenden, sobald der Anruf beendet ist.',
		browserPeerConnectionsLimitReached:
			'Browser-Limit für maximale Anzahl von Verbindungen erreicht. Bitte schließen und öffnen Sie alle Hellocare-Tabs neu.',
		sessionName: 'Namen anzeigen',
		enterSessionName: 'Geben Sie den Ansichtsnamen ein',
		delete: 'Löschen',
		deleteSession: 'Löschen Sie diese Ansicht',
		sureDeleteSession: 'Möchten Sie diese Ansicht wirklich löschen?',
		failedToReconnect: 'Verbindung zum Netzwerk konnte nicht wiederhergestellt werden.',
		sessionEndedByAdmin: 'Diese Sitzung wurde von einem Administrator beendet.',
		disconnectedByCall:
			'Dieser Feed wurde durch eine Anrufsitzung abgebrochen und wird wieder verbunden, sobald der Anruf endet.',
		maximumNumberOfMonitoringSessions:
			'Das Gerät wird von {value} überwacht. Die maximale Anzahl für diesen Raum wurde erreicht.',
		beingMonitoredBy: 'Dieser Patient wird überwacht von',
		pleaseRefreshThePage: 'Bitte aktualisieren Sie die Seite.',
		sessionUpdatedSuccessfully: 'Sitzung erfolgreich aktualisiert',
		save: 'Speichern',
		cancel: 'Absagen',
		apply: 'Anwenden',
		suspiciousBehavior: 'Verdächtiges Verhalten',
		suicideWatch: 'Selbstmord-Uhr',
		watch: 'Die Uhr',
		aIWithCamera: 'KI mit Kamera',
		falls: 'Stürze',
		behavioral: 'Verhalten',
		isolation: 'Isolation',
		seizure: 'Krampfanfall',
		hospice: 'Hospiz',
		detox: 'Entgiftung',
		fallRiskCheckPatient: 'Sturzrisiko. Bitte überprüfen Sie den Patienten.',
		multiPersonFallDetection: 'Sturzerkennung bei mehreren Personen',
		fallDetection: 'Sturzerkennung',
		turnOffPrivacyMode: 'Deaktivieren Sie den Datenschutzmodus',
		turnOnPrivacyMode: 'Aktivieren Sie den Datenschutzmodus',
		allowPermission: 'Erlaubnis zulassen',
		mute: 'Stumm',
		unmute: 'Stummschaltung aufheben',
		muteAudio: 'Audio stumm',
		unMuteAudio: 'Stummschaltung aufheben',
		reinitiateCallPermissions: 'Um diesen Anruf erneut zu initiieren, müssen Sie Tonberechtigungen zulassen',
		for: 'zum',
		myRequests: 'Meine Anfragen',
		newRequest: 'Neue Anfrage',
		acceptedRequests: 'Akzeptierte Anfragen',
		pendingRequests: 'Offene Anfragen',
		rejectedRequests: 'Abgelehnte Anfragen',
		pending: 'Ausstehend',
		requestSentTo: 'Anfrage gesendet an',
		viewRequestHistory: 'Anforderungsverlauf anzeigen',
		healthConcern: 'Gesundheitliche Anliegen',
		reassignRequest: 'Anfrage neu definieren',
		welcomeMessage: 'Willkommensnachricht',
		visitCost: 'Besuchskosten',
		languagesSpoken: 'Gesprochene Sprachen',
		professionalEducation: 'Berufsausbildung',
		yearsOfExperience: 'Langjährige Erfahrung',
		fellowship: 'Gemeinschaft',
		requestHistory: 'Anforderungsverlauf',
		youHaveAcceptedRequest: 'Sie haben die Anfrage von {value} angenommen',
		acceptedYourRequest: 'Ihre Anfrage akzeptiert',
		assignedTheRequestTo: 'hat die Anfrage zugewiesen an',
		rejectedYourRequest: 'Ihre Anfrage abgelehnt',
		general: 'Allgemein',
		profileInformation: 'Profil Information',
		passwordProtection: 'Passwortschutz',
		notifications: 'Benachrichtigungen',
		measurementUnits: 'Messeinheiten',
		integratedApps: 'Integrierte Apps',
		dependents: 'Angehörige',
		insurance: 'Versicherung',
		paymentInformation: 'Zahlungsinformationen',
		activityHistory: 'Aktivitätsverlauf',
		noPageFound: 'Keine Seite gefunden',
		saveChanges: 'Änderungen speichern',
		phoneNumber: 'Telefonnummer',
		emailAddress: 'E-Mail-Addresse',
		language: 'Sprache',
		cameraNames: 'Kameranamen',
		customRolesDisplayName: 'Angezeigter Name für benutzerdefinierte Rollen',
		enterCustomRoleNameHere: 'Geben Sie hier den benutzerdefinierten Rollennamen ein',
		customRoleNameRequired: 'Benutzerdefinierter Rollenname erforderlich',
		informationMessageIsRequired: 'Informationsnachricht ist erforderlich',
		informationMessageForMonitoring: 'Informationsnachricht für die Überwachung',
		enterAMessageHere: 'Geben Sie hier eine Nachricht ein',
		displayAs: 'Anzeigen als',
		externalCamera: 'Externe Kamera',
		edit: 'Bearbeiten',
		timeTrackerValue: '{value} Zeiterfassung',
		enable: 'Ermöglichen',
		disable: 'Deaktivieren',
		disabled: 'Behinderte',
		recent: 'letze',
		myDevices: 'Meine Geräte',
		online: 'Online',
		offline: 'Offline',
		send: 'Senden',
		healthCategories: 'Gesundheitskategorien',
		addTab: 'Tab hinzufügen',
		addFavTabQuickerAccess: 'Fügen Sie Ihre bevorzugten Apps oder Dateien als Registerkarten für einen schnelleren Zugriff hinzu',
		searchAppsFiles: 'Apps oder Dateien suchen',
		myApps: 'Meine Applikationen',
		otherApps: 'Andere Apps',
		addNewHealthSystem: 'Neues Gesundheitssystem hinzufügen',
		resendInvite: 'Einladung erneut senden',
		cancelInvite: 'Einladung abbrechen',
		addNewField: 'Neues Feld hinzufügen',
		viewYourBaby: 'Sehen Sie Ihr Baby',
		enablePrivacyButtons:
			'Datenschutzschaltflächen sind auf dem {value}-Gerät aktiviert. Sie können das Baby sehen, wenn die Privatsphäre-Tasten deaktiviert sind.',
		somethingWentWrong: 'Etwas ist schief gelaufen! Bitte versuchen Sie es erneut.',
		successfullySaved: 'Erfolgreich gespeichert.',
		usersAddedOnHelloHealth: 'Die Benutzer wurden erfolgreich zu hellocare eingeladen!',
		patientAssignedAsDeviceOwner: 'Patient wurde erfolgreich als Gerätebesitzer zugewiesen',
		manageHealthSystems: 'Gesundheitssysteme verwalten',
		enterHealthSystem: 'Geben Sie das Gesundheitssystem ein',
		healthSystem: 'Gesundheitssystem',
		assignation: 'Abtretung',
		unAssignableLevel: 'Nicht zuweisbare Ebene',
		selectAssignationLevel: 'Wählen Sie Zuweisungsebene(n) aus',
		downloadCSV: 'CSV-Datei herunterladen',
		watchRecording: 'Aufzeichnung ansehen',
		recordedBy: 'Aufgenommen von',
		assignLevel: 'Ebene zuweisen',
		signsSymptoms: 'Anzeichen und Symptome',
		selectSignsSymptomsAssociatedWithHealthConcerns:
			'Bitte wählen Sie die Anzeichen und Symptome aus, die mit Ihren gesundheitlichen Anliegen zusammenhängen.',
		mostRecentMeasurementByDate: 'Diese Ansicht zeigt die letzte Messung sortiert nach Datum und Stunde an.',
		searchForSymptoms: 'Suche nach Symptomen',
		noSymptomsFound: 'Keine Symptome gefunden',
		mySymptoms: 'Meine Symptome',
		pleaseWriteYourHealthConcerns: 'Bitte schreiben Sie Ihre gesundheitlichen Bedenken!',
		errorOccurred: 'Ein Fehler ist aufgetreten',
		pleaseSelectTheRequiredFields: 'Bitte wählen Sie die erforderlichen Felder aus!',
		sendRequest: 'Anfrage senden',
		scheduleAppointment: 'Termin vereinbaren',
		writeYourFirstName: 'Bitte schreiben Sie hier Ihren Vornamen...',
		writeYourLastName: 'Bitte geben Sie hier Ihren Nachnamen ein...',
		writeYourEmail: 'example@googlemail.com',
		confirmEmail: 'Bitte bestätigen Sie hier Ihre E-Mail...',
		writePassword: 'Bitte geben Sie hier Ihr Passwort ein...',
		search: 'Suche',
		selectSerialNumber: 'Seriennummer auswählen',
		writeCurrentLocation: 'Bitte geben Sie hier Ihren aktuellen Standort ein...',
		writeHeight: 'Bitte geben Sie hier Ihre Körpergröße ein...',
		writeHeightUnit: 'Höheneinheit',
		writeWeight: 'Bitte tragen Sie hier Ihr Gewicht ein...',
		writeWeightUnit: 'Gewichtseinheit',
		healthSystemName: 'Name des Gesundheitssystems',
		regionName: 'Regionsname',
		confirmPassword: 'Passwort bestätigen',
		firstName: 'Vorname',
		lastName: 'Nachname',
		password: 'Passwort',
		email: 'Email',
		enterYourInfo: 'Ihre Informationen',
		verifyCellPhone: 'Mobiltelefon',
		licenseNumber: 'Amtliches Kennzeichen',
		medicalSchool: 'Medizinschule',
		chooseADate: 'Wählen Sie ein Datum',
		companyName: 'Name der Firma',
		positionTitle: 'Position/Titel',
		specify: 'Angeben',
		suffix: 'Suffix',
		maidenName: 'Mädchenname',
		npiNumber: 'NPI-Nummer',
		internshipInstitutionName: 'Name der Praktikumseinrichtung',
		internshipSpecialty: 'Praktikum Spezialität',
		residencyInstitutionName: 'Name der Aufenthaltsinstitution',
		residencySpecialty: 'Residenz Spezialität',
		fellowshipInstitutionName: 'Name der Stipendieneinrichtung',
		fellowshipSpecialty: 'Fellowship-Spezialität',
		searchForBoard: 'Geben Sie hier ein, um nach Board zu suchen..',
		medicaidProviderNumber: 'Nummer des Medicaid-Anbieters',
		medicareProviderNumber: 'Nummer des Medicare-Anbieters',
		birthDate: 'Geburtsdatum',
		socialSecurityNumber: 'Sozialversicherungsnummer',
		yourAnswer: 'Ihre Antwort',
		noResultsFound: 'Keine Ergebnisse gefunden!',
		moreThanCharacters: 'Sie müssen mindestens {value} Buchstaben eingeben, um eine Suche durchzuführen.',
		patientNotFound: 'Der gesuchte Patient existiert nicht!',
		showAllResults: 'Alle Ergebnisse anzeigen',
		advancedSearch: 'Erweiterte Suche',
		filterType: 'Filter Typ',
		all: 'Alle',
		patients: 'Patienten',
		lowercaseToday: 'heute',
		lowercaseTomorrow: 'Morgen',
		myHealthConcerns: 'Meine gesundheitlichen Anliegen',
		describeYourHealthConcerns: 'Beschreiben Sie Ihre gesundheitlichen Anliegen',
		thereAreAppointmentsFor: 'Es gibt {value} verfügbare Termine für',
		typeAppointmentTopic: 'Terminthema eingeben',
		whereWouldYouLikeMeetingTakePlace: 'Wo soll dieses Treffen stattfinden?',
		firstNameCannotBeEmpty: 'Vorname darf nicht leer sein',
		parentNameCannotBeEmpty: 'Übergeordneter Name darf nicht leer sein',
		idNumberCannotBeEmpty: 'ID-Nummer darf nicht leer sein',
		patientHealthCardNumberCannotBeEmpty: 'Patienten-Gesundheitskartennummer darf nicht leer sein',
		lastNameCannotBeEmpty: 'Nachname darf nicht leer sein',
		professionCannotBeEmpty: 'Beruf darf nicht leer sein',
		emailCannotBeEmpty: 'Die E-Mail-Adresse darf nicht leer sein',
		rpmCannotBeEmpty: 'Das RPM-Programm darf nicht leer sein',
		enterFirstName: 'Bitte Vornamen eingeben',
		enterLastName: 'Nachnamen eingeben',
		virtualNurse: 'Virtuelle Krankenpflege',
		virtualCaseManager: 'Virtueller Fallmanager',
		virtualIntensivist: 'Virtueller Intensivist',
		virtualPatient: 'Virtueller Patient',
		manualPatient: 'Manueller Patient',
		ehrPatient: 'EHR Patient',
		memberSuccessfullyModified: 'Das Mitglied wurde erfolgreich geändert!',
		somethingWentWrongTryAgain: 'Etwas ist schief gelaufen! Bitte versuchen Sie es erneut.',
		fileReadingWasAborted: 'Lesen der Datei wurde abgebrochen',
		fileReadingHasFailed: 'Das Lesen der Datei ist fehlgeschlagen',
		errorDeletingPhoto: 'Fehler beim Löschen des Fotos.',
		dragAndDropOrSelectPhotos:
			'Ziehen Sie einige Fotos hierher und legen Sie sie dort ab, oder klicken Sie, um Fotos auszuwählen',
		dragAndDropOrSelectAttachments:
			'Ziehen Sie einige Dateien hierher und legen Sie sie dort ab, oder klicken Sie, um Dateien auszuwählen',
		photos: 'Fotos',
		attachments: 'Anhänge',
		fiveAttachmentsMaximumUpload: 'Sie können maximal 5 Dateien hochladen.',
		fileUploadMaximumSize: 'Die maximale Dateigröße sollte 5 MB betragen',
		noPhotosForThisUser: 'Es gibt keine Fotos für diesen Benutzer.',
		uploadPhotos: 'Fotos hochladen',
		dateSent: 'Datum gesendet',
		invitedBy: 'Eingeladen von',
		role: 'Rolle',
		addAdminUsers: 'Admin-Benutzer hinzufügen',
		addSuperAdminUsers: 'Super-Admin-Benutzer hinzufügen',
		deleteAlertSubscription: 'Alarmabonnement löschen',
		areYouSureYouWantToDeleteSubscription: 'Sind Sie sicher, dass Sie das Abonnement für {value} löschen möchten?',
		setupDeviceAlerts: 'Benachrichtigungen für Gerät einrichten',
		deviceAlerts: 'Gerätebenachrichtigungen',
		receiveAlertsFrom: 'Benachrichtigungen empfangen von',
		addAnotherEmailAddress: 'Weitere E-Mail-Adresse hinzufügen',
		setupAlertsFor: 'Benachrichtigungen einrichten für',
		recipients: 'Empfänger',
		assignationLevel: 'Zuweisungsebene',
		alertType: 'Alarmtyp',
		invitationDate: 'Einladungsdatum',
		deviceIsOffline: 'Gerät ist offline',
		anAlertMustBeSelected: 'Ein Alarm muss ausgewählt werden',
		couldNotFindThisEmail: 'Diese E-Mail konnte nicht gefunden werden',
		alreadyAddedThisEmail: 'Sie haben diese E-Mail bereits hinzugefügt',
		formShouldContainOneEmail:
			'Das Formular sollte mindestens eine gültige E-Mail-Adresse enthalten. Wenn das Eingabefeld ausgefüllt ist, drücken Sie bitte die Enter-Taste, um die E-Mail-Adresse hinzuzufügen.',
		confirmation: 'Bestätigung',
		areYouSureCancelInvitation:
			'Die maximale Anzahl an Überwachungssitzungen für diesen Raum wurde erreicht, bitte versuchen Sie es später erneut!',
		overwrite: 'Überschreiben',
		warning: 'Warnung',
		featureOverwrite: 'Funktion überschreiben',
		exclude: 'Ausschließen',
		applyToAll: 'Bewerben Sie sich für alle',
		endedTime: 'Endzeit',
		startTime: 'Startzeit',
		roomsWithDifferentConfigs: 'Räume mit unterschiedlichen Feature-Flags',
		waringOverwriteDescription:
			'Sie versuchen, Änderungen an einem Sektor vorzunehmen, in dem es bereits Geräte mit anderen Funktionen gibt als denen, die Sie festlegen möchten.',
		bySavingThisDescription:
			'Wenn Sie die Funktionsflags für die oben genannten Räume überschreiben möchten, wählen Sie bitte „Auf alle anwenden“ aus.Wenn Sie diese Räume ausschließen möchten, wählen Sie bitte Ausschließen.',
		areYouSureResendInvitation:
			'Möchten Sie diese Einladung wirklich erneut senden? Der von Ihnen eingeladene Benutzer erhält eine weitere E-Mail, in der er aufgefordert wird, hellocare beizutreten.',
		areYouSureTwoFaReset:
			'Möchten Sie die Zwei-Faktor-Authentifizierung wirklich zurücksetzen? Der Benutzer erhält eine E-Mail mit der Aufforderung, die Zwei-Faktor-Authentifizierung zurückzusetzen.',
		userName: 'Nutzername',
		dateInvited: 'Datum der Einladung',
		dateAdded: 'Datum hinzugefügt',
		healthSystems: 'Gesundheitssysteme',
		areYouSureDeleteMember: 'Sind Sie sicher Löschmitglied',
		areYouSureDeleteThisMember: 'Sind Sie sicher, dass Sie dieses Mitglied löschen möchten?',
		userInfo: 'Benutzerinformation',
		userPhotos: 'Benutzerfotos',
		enterEmail: 'Email eingeben',
		writeEmailOfRoleToInvite: 'Bitte schreiben Sie die E-Mails von {role}, die Sie einladen möchten.',
		inviteMax5PeopleAtOnce: 'Sie können maximal 5 Personen gleichzeitig einladen!',
		selectAtLeastOneHSToAddRole: 'Bitte wählen Sie mindestens ein Gesundheitssystem aus, um einen {value} hinzuzufügen',
		writeEmailsYouWishToInvite: 'Bitte schreiben Sie die E-Mail-Adressen des {value}, den Sie einladen möchten.',
		addSomething: 'hinzufügen {value}',
		userManagement: 'Benutzerverwaltung',
		fleetManagement: 'Flottenmanagement',
		fleetDashboardDescription:
			'Wenn dies aktiviert ist, kann der Benutzer Echtzeitstatus der Geräte im ausgewählten Gesundheitssystem sehen.',
		fleetDashboard: 'Flottenmanagement',
		setupAlerts: 'Benachrichtigungen einrichten',
		ofTheRooms: 'der Zimmer',
		deviceAssignation: 'Gerätezuweisung',
		connection: 'Verbindung',
		clearFilter: 'Filter löschen',
		searchByRoomOrSN: 'Suche nach Raumnamen oder SN',
		ethernet: 'Ethernet',
		wireless: 'Kabellos',
		devicesAssigned: 'Zugewiesene Geräte',
		members: 'Mitglieder',
		invitedMembers: 'Eingeladene Mitglieder',
		invitedMember: 'Eingeladenes Mitglied',
		active: 'Aktiv',
		notActive: 'Nicht aktiv',
		patientPosition: 'Der Patient war 10 Sekunden lang inaktiv.',
		railDownMessage: 'Bettgitter ist unten. Bitte überprüfen Sie den Patienten.',
		alertCheckThePatient: 'Alarm! Untersuchen Sie den Patienten.',
		fallRiskCheckThePatient: 'Sturzrisiko. Bitte kontrolloieren Sie den Patienten.',
		pleaseCheckPatient: 'Bitte kontrollieren Sie den Patienten.',
		concerningMeasurements: 'Der Patient hat besorgniserregende Messungen.',
		alertCheckTheBaby: 'Alarm! Untersuchen Sie das Baby.',
		alertBabyExitingCrib: 'Alarm! Baby verlässt Krippe.',
		missingTools: 'Fehlende Werkzeuge',
		surgicalToolsDetection: 'Erkennung von chirurgischen Werkzeugen',
		objectDetection: 'Objekterkennung',
		babyDetection: 'Baby-Erkennung',
		patientPositionDetection: 'Patientenpositionserkennung',
		hospitalTimeZone: 'Krankenhaus-Zeitzone',
		localTimeZone: 'Lokale Zeitzone',
		bedRailDetection: 'Bett- und Schienenerkennung',
		actionRecognition: 'Aktionserkennung',
		skeletonFallDetection: 'Skelettsturzerkennung',
		privacyOnSkeletonDetection: 'Datenschutz bei Skeletterkennung',
		otoscopeConnected: 'Otoskop wurde angeschlossen',
		switchView: 'Ansicht wechseln',
		areYouSureRebootHuddleCam: `Sind Sie sicher, dass Sie die {value}-Kamera neu starten möchten? In 30 Sekunden kehrt die Ansicht zur Perspektive {value} zurück.`,
		chooseHealthSystemYouWantToManage: 'Wählen Sie das Gesundheitssystem aus, das Sie verwalten möchten.',
		chooseRegionFromHSManageHospitals:
			'Wählen Sie oben eine Region aus dem Gesundheitssystem aus, um die Krankenhäuser darin zu verwalten.',
		togglePatientTVPowerOn: 'Schalten Sie den Fernseher des Patienten ein',
		volume: 'Volumen',
		switchPatientHDMI: 'HDMI des Patienten umschalte',
		heartRateTooltip:
			'Die Herzfrequenz ist die Geschwindigkeit des Herzschlags, gemessen an der Anzahl der Kontraktionen (Schläge) des Herzens pro Minute (bpm). Die American Heart Association gibt an, dass die normale menschliche Ruheherzfrequenz eines Erwachsenen 60–100 bpm beträgt.',
		bloodPressureTooltip:
			'Der Blutdruck ist der Druck des zirkulierenden Blutes an den Wänden der Blutgefäße. Der größte Teil dieses Drucks ist auf die Arbeit des Herzens zurückzuführen, indem es Blut durch das Kreislaufsystem pumpt. Ohne weitere Spezifizierung verwendet, bezieht sich "Blutdruck" normalerweise auf den Druck in großen Arterien des systemischen Kreislaufs.',
		perfusionIndexTooltip:
			'Der Perfusionsindex oder PI ist das Verhältnis des pulsierenden Blutflusses zum nicht pulsierenden statischen Blutfluss im peripheren Gewebe eines Patienten, wie z. B. in einer Fingerkuppe, einem Zeh oder einem Ohrläppchen. Perfusionsindex ist ein Hinweis auf die Pulsstärke an der Sensorstelle.',
		stepsTooltip: 'Eine Treppe wird mit ungefähr 3 Metern Höhengewinn (ungefähr 16 Stufen) berechnet.',
		flightsClimbedTooltip: 'Eine Treppe wird als ungefähr 10 Fuß (3 Meter) Höhenunterschied (ungefähr 16 Stufen) gezählt.',
		bodyFatPercentageTooltip:
			'Der Körperfettanteil ist das Gewicht des Fetts in Ihrem Körper im Vergleich zu Ihrem Gesamtkörpergewicht, das Muskeln, Knochen, Wasser usw. umfasst.',
		bodyMassIndexTooltip:
			'Der BMI ist eine Berechnung Ihrer Größe, die Ihre Größe und Ihr Gewicht berücksichtigt. Es kann Ihnen sagen, ob Sie untergewichtig, normal, übergewichtig oder fettleibig sind. Es kann auch Ihr Risiko für Krankheiten abschätzen, die mit mehr Körperfett auftreten können.',
		bodyTemperatureTooltip:
			'Die normale Körpertemperatur variiert je nach Person, Alter, Aktivität und Tageszeit. Die durchschnittliche normale Körpertemperatur wird allgemein mit 37 °C (98,6 °F) angenommen. Eine Temperatur über 38 °C (100,4 °F) bedeutet meistens, dass Sie Fieber haben, das durch eine Infektion oder Krankheit verursacht wird. Die Körpertemperatur ändert sich normalerweise im Laufe des Tages.',
		oxygenSaturationTooltip:
			'Die Sauerstoffsättigung ist ein Maß für die Sauerstoffmenge im Protein (Hämoglobin) in Ihren roten Blutkörperchen. Um richtig zu funktionieren, benötigt Ihr Körper ein bestimmtes Maß an Sauerstoff, der im Blut zirkuliert. Ihre roten Blutkörperchen sind in der Lunge mit Sauerstoff beladen (gesättigt) und transportieren ihn durch Ihren Körper.',
		sleepAnalysisTooltip:
			'Die Schlafanalyse gibt Einblick in Ihre Schlafgewohnheiten. Schlaf-Tracker und -Monitore können Ihnen helfen, die Zeit zu bestimmen, die Sie im Bett liegen und schlafen. Diese Geräte schätzen Ihre Bett- und Schlafzeit, indem sie Änderungen der körperlichen Aktivität analysieren, einschließlich Bewegungen während der Nacht. Sie können Ihren Schlaf auch verfolgen, indem Sie Ihre eigene Schätzung Ihrer Schlafenszeit und Schlafzeit manuell eingeben. \n Der „Im Bett“-Zeitraum spiegelt den Zeitraum wider, in dem Sie mit der Absicht zu schlafen im Bett liegen. Für die meisten Menschen beginnt es, wenn Sie das Licht ausschalten, und es endet, wenn Sie aus dem Bett steigen. Die „Schlaf“-Periode spiegelt die Periode(n) wider, in denen Sie schlafen.',
		bloodGlucoseTooltip:
			'Blutzucker, auch Blutzucker genannt, ist der Hauptzucker in Ihrem Blut. Es kommt aus der Nahrung, die Sie essen, und ist die Hauptenergiequelle Ihres Körpers.',
		stethoscopeTooltip:
			'Das Stethoskop ist ein akustisches medizinisches Gerät zur Auskultation oder zum Abhören der inneren Geräusche eines tierischen oder menschlichen Körpers. Mit einem Stethoskop können die Geräusche von Herz, Lunge oder Darm sowie der Blutfluss in Arterien und Venen abgehört werden.',
		respiratoryRateTooltip:
			'Die Atemfrequenz ist Ihre Atemfrequenz (Einatmen und Ausatmen) innerhalb einer bestimmten Zeit (normalerweise 60 Sekunden).',
		noDataFound: 'Für diesen Zeitraum sind keine Daten verfügbar',
		noDataAvailable: 'Keine Daten verfügbar',
		tryOtherDateRange: 'Versuchen Sie es, indem Sie einen anderen Datumsbereich auswählen!',
		requestAccepted: 'Anfrage akzeptiert',
		requestRejected: 'Anfrage abgelehnt',
		clickAllowToMakeCall: 'Klicken Sie auf Zulassen, um einen Anruf zu tätigen',
		allowPermissionToMakeCall:
			'Um einen Anruf auf hellocare zu tätigen, müssen Sie Berechtigungen für Mikrofon und Kamera zulassen.',
		participantHearAndSeeYou: 'Dadurch können die anderen Teilnehmer Sie hören und sehen.',
		canTurnOffMicCameraLater: 'Sie können Ihr Mikrofon oder Ihre Kamera später jederzeit ausschalten.',
		turnOnMicCamera: 'Mikrofon und Kamera einschalten',
		deviceNotFound: 'Gerät nicht gefunden',
		videoCallTurnOnCamera: 'Um einen Videoanruf mit hellocare zu tätigen, müssen Sie Ihre Kamera einschalten.',
		audioCallTurnOnMicRefreshPage:
			'Für einen Audioanruf müssen Sie Ihr Mikrofon einschalten. Versuchen Sie es erneut, indem Sie die Seite aktualisieren.',
		deviceMayBeInUse: 'Gerät wird möglicherweise verwendet',
		camOrMicAnotherApp: 'Kamera oder Mikrofon werden möglicherweise von einer anderen Anwendung verwendet.',
		closeOtherAppRefresh: 'Bitte schließen Sie die andere Anwendung und aktualisieren Sie die Seite.',
		noData: 'Kein Datum',
		pleaseReachAdmin: 'Bitte wenden Sie sich an den Administrator, um diese Konfigurationen verfügbar zu haben.',
		overviewDescription:
			'Wenn dies aktiviert ist, werden Übersichtsdaten für Überwachung, Rundung und rollenbasierte Anzeige angezeigt.',
		providerCentricReportDescription:
			'Wenn dies aktiviert ist, werden Anbieterdaten zur Anzahl der Sitzungen, Patienten und Sitzungen',
		patientCentricReportDescription:
			'Wenn dies aktiviert ist, werden Patientendaten wie Anzahl der Sitzungen, Interventionen und durchgeführte Aktionen angezeigt.',
		plsReachAdministratorToHaveThisFeatureAvailable:
			'Bitte wenden Sie sich an Ihren Administrator, um diese Funktion verfügbar zu haben.',
		noConnectedDoctor: 'Sie haben keine angeschlossenen Ärzte!',
		noActionsFound: 'Keine Aktionen gefunden',
		noHelloIsSelected: 'Sie haben kein verbundenes {value}-Gerät!',
		tryBySelectingOne: 'Probieren Sie es aus, indem Sie eines auswählen!',
		optionInitiateAudioCall:
			'Diese Option leitet einen Audioanruf an den Patienten ein, er kann Sie hören und Sie können ihn hören. Während des Anrufs können Sie Ihre Kamera oder die des Patienten einschalten oder sogar Ihren Bildschirm teilen.',
		myPatients: 'Meine Patienten',
		myHellos: 'Meine {value}s',
		noConnectedPatientsYet: 'Sie haben noch keine angeschlossenen Patienten!',
		noConnectedDoctorsYet: 'Sie haben noch keine angeschlossenen Ärzte!',
		editDepartmentDetails: 'Abteilungsdetails bearbeiten',
		deleteDepartment: 'Abteilung löschen',
		floorsInThisDepartment: 'Etagen in dieser Abteilung',
		roomsInThisDepartment: 'Zimmer in dieser Abteilung',
		enrolledDevices: 'Registrierte Geräte',
		enrolledCompanionDevices: 'Begleitgeräte',
		devices: 'Geräte',
		rooms: 'Räume',
		floors: 'Etagen',
		deviceSerialNumber: 'Seriennummer des Geräts',
		tvSerialNumber: 'Seriennummer des Fernsehers',
		deviceEnrolled: 'Gerät registriert',
		deviceEnrollment: 'Geräteregistrierung',
		enrollment: 'Einschreibung',
		companyDeviceEnrollment: 'Geräteregistrierung auf Unternehmensebene',
		actions: 'Aktionen',
		roomNames: 'Zimmernamen',
		roomNumber: 'Zimmernummer',
		writeRoomNumber: 'Schreiben Sie die Zimmernummer',
		floorNames: 'Etagennamen',
		unassign: 'Zuweisung aufheben',
		unassignDevice: 'Gerätezuweisung aufheben',
		unassignPatient: 'Patientenzuweisung aufheben',
		sureToUnassignDevice:
			'Möchten Sie die Zuweisung von Gerät {value} wirklich aufheben? Weder Sie noch der Patient können Anrufe tätigen.',
		sureToUnassignPatient:
			'Möchten Sie die Zuweisung von {value} von diesem Gerät wirklich aufheben? Weder Sie noch der Patient können Anrufe tätigen.',
		sureToDeleteOrg:
			'Möchten Sie {value} wirklich löschen? Alle damit verbundenen Geräte werden gelöscht und weder Virtual Care Provider noch Patienten können Anrufe tätigen.',
		editFloorDetails: 'Etagendetails bearbeiten',
		deleteFloor: 'Etage löschen',
		roomsInThisFloor: 'Zimmer in dieser Etage',
		signInToYourAccount: 'Melden Sie sich bei Ihrem Konto an',
		editHospitalDetails: 'Krankenhausdetails bearbeiten',
		deleteHospital: 'Krankenhaus löschen',
		departmentsInThisHospital: 'Abteilungen in diesem Krankenhaus',
		floorsInThisHospital: 'Etagen in diesem Krankenhaus',
		roomsInThisHospital: 'Zimmer in diesem Krankenhaus',
		departmentNames: 'Abteilungsnamen',
		departments: 'Abteilungen',
		editRoomDetails: 'Zimmerdetails bearbeiten',
		familyMember: 'Familienmitglied',
		familyMembers: 'Familienmitglieder',
		assignFamilyMember: 'Familienmitglied zuweisen',
		babyRoom: 'Babyzimmer',
		roomDetails: 'Zimmerdetails',
		roomType: 'Zimmertyp',
		deleteRoom: 'Raum löschen',
		addDevice: 'Gerät hinzufügen',
		addCompanionDevice: 'Begleitgerät hinzufügen',
		roomDevice: 'Raumgerät',
		assignDevice: 'Gerät zuweisen',
		assignCompanionDevice: 'Begleitgerät zuweisen',
		selectSerialNumberAssociateWithRoom: 'Wählen Sie eine Seriennummer aus, die diesem Zimmer zugeordnet werden soll.',
		unitPhoneNumberMust10: `Die Telefonnummer der Einheit muss 10 Stellen lang sein`,
		selectCompanionMode: 'Wählen Sie den Begleitmodus',
		selectCompanionDevice: 'Wählen Sie das Begleitgerät aus',
		theSectorTypeRoomLocatedAt: 'Dieses Zimmer befindet sich in {value}.',
		download: 'Herunterladen',
		document: 'Dokument',
		errorWhileFetchingPatients: 'Fehler beim Abrufen von Patienten',
		selectPatientHelloDevice: 'Wählen Sie ein Patienten/{value} Gerät aus!',
		noConnectedPatients: 'Keine verbundenen Patienten',
		joinHELLOHealth: 'Tritt hellocare bei.',
		haveVisitInMinutes: 'Besuchen Sie uns in wenigen Minuten.',
		createUserAccount: 'Benutzerkonto erstellen',
		createProviderAccount: 'Anbieterkonto erstellen',
		createHealthSystemProfile: 'Erstellen Sie ein Gesundheitssystemprofil',
		addRegionsForThisHealthSystem: 'Regionen für dieses Gesundheitssystem hinzufügen',
		addNew: 'Neue hinzufügen',
		healthSystemCreatedSuccessfully: 'Ihr Gesundheitssystemprofil wurde erfolgreich erstellt.',
		accountBeingCreatedMayTakeMinutes: 'Ihr Konto wird erstellt. Das kann ein paar minuten dauern!',
		voiceCommandForEmergencyAssistance: 'Notfallhilfe',
		voiceCommandForCareEvents: 'Pflegeereignisse',
		voiceCommandForPrivacyEnabling: 'Privatsphäre',
		voiceCommandForCallNurse: 'Pflegekraft anrufen',
		vCEADesc: `Wenn dies aktiviert ist, kann jeder im Raum es aktivieren, indem er "Hellocare Code Blue" sagt`,
		vCCEDesc: `Wenn dies aktiviert ist, kann jeder im Raum es aktivieren, indem er "Hellocare open Care Events" sagt`,
		vCPDesc: `Wenn dies aktiviert ist, kann jeder im Raum es aktivieren, indem er "Hellocare Enable privacy" sagt`,
		vCCNDesc: `Wenn dies aktiviert ist, kann jeder im Raum die {roleNameNurse}s anrufen, indem er "Hellocare Call Nurse" oder "Hellocare Call the Nurse"" sagt.`,
		voiceCommands: 'Sprachbefehle',
		invalidInvitation: 'Dies ist eine ungültige Einladung',
		inviteAlreadyAccepted: 'Einladung wurde bereits angenommen',
		inviteCancelled: 'Einladung wurde vom Systemadministrator storniert',
		inviteExpired: 'Einladung ist abgelaufen',
		checkInvitationLinkValidity:
			'Gehen Sie zurück zu Ihrer E-Mail und stellen Sie sicher, dass der Link, auf den Sie geklickt haben, gültig ist. Wenden Sie sich außerdem an Ihren Administrator, um sicherzustellen, dass Sie diese Einladung noch nicht angenommen haben.',
		anotherNursePickedUpCall: 'Ein anderer Krankenpfleger nahm diesen Anruf entgegen',
		waitAttemptingReconnect: 'Bitte warten ... Versucht sich wieder mit dem Netzwerk zu verbinden.',
		manageHealthSystem: 'Gesundheitssystem verwalten',
		changesWillBeAppliedToThisHS:
			'Alle Änderungen, die Sie vornehmen, werden auf dieses System angewendet. Sie können dieses System umbenennen oder löschen.',
		pressEnterToSave: 'Drücken Sie die Entertaste, um zu speichern',
		regions: 'Regionen',
		availableRegionsInThisHS:
			'Dies sind die verfügbaren Regionen in diesem Gesundheitssystem. Erstellen Sie weitere Regionen, benennen Sie sie um oder löschen Sie sie.',
		addNewRegion: 'Neue Region hinzufügen',
		areYouSureToDeleteRegion: 'Möchten Sie diese Region wirklich löschen?',
		areYouSureToDeleteHS: 'Möchten Sie dieses Gesundheitssystem wirklich löschen?',
		sectorIsLocatedAt: 'Dieser {value2} befindet sich am {value}',
		boardType: 'Bretttyp',
		selectBoard: 'Brett auswählen',
		rowsPerPage: 'Zeilen pro Seite',
		minttiStethoscope: 'Mintti Stethoskop',
		littmannStethoscope: 'Littmann Stethoskop',
		manuallyAdded: 'Manuell hinzugefügt',
		sipEndpoint: 'SIP-Endpunkt',
		addData: 'Daten hinzufügen',
		systolic: 'Systolisch',
		diastolic: 'diastolisch',
		selectStartDate: 'Startdatum auswählen',
		selectEndDate: 'Enddatum auswählen',
		allRecordedData: 'Alle aufgezeichneten Daten',
		deleteAllDataRecords: 'Alle Datensätze löschen',
		add: 'Hinzufügen',
		addDataManually: 'Daten manuell hinzufügen',
		dateAndTime: 'Datum (und Uhrzeit',
		inBed: 'Im Bett',
		inChair: 'Im Vorsitz',
		asleep: 'Schlafend',
		selectState: 'Staat wählen',
		done: 'Fertig',
		deleteThisDataRecord: 'Diesen Datensatz löschen',
		dataDetails: 'Datendetails',
		dateOfMeasurement: 'Datum der Messung',
		source: 'Quelle',
		manufacturer: 'Hersteller',
		model: 'Modell',
		areYouSureDeleteThisRecord: 'Möchten Sie diesen Datensatz wirklich löschen?',
		areYouSureDeleteAllRecords: 'Möchten Sie wirklich alle Datensätze löschen?',
		documentName: 'Dokumentname',
		creationDate: 'Erstellungsdatum',
		attachment: 'Anhang',
		reboot: 'Neustart',
		rebootCamera: 'Kamera neu starten',
		patientsTV: 'Patientenfernseher',
		togglePatientTVtoHDMI: 'Fernseher des Patienten auf HDMI umschalten',
		maxCharacterLengthExceeded: 'Maximale Zeichenanzahl überschritten',
		firstNameMustStartWithCapitalLetter: 'Vorname muss mit einem Großbuchstaben beginnen',
		pleaseWriteFirstName: 'Bitte schreiben Sie Ihren Vornamen',
		lastNameMustStartWithCapitalLetter: 'Nachname muss mit einem Großbuchstaben beginnen',
		pleaseWriteLastName: 'Bitte schreiben Sie Ihren Nachnamen',
		emailAlreadyRegistered: 'Die angegebene E-Mail-Adresse ist bereits registriert.',
		newEmailDifferent: 'Neue E-Mail muss anders sein als alte E-Mail.',
		invalidEmail: 'Angegebene E-Mail ist ungültig',
		pleaseWriteEmail: 'Bitte schreiben Sie Ihre E-Mail',
		emailsMustMatch: 'E-Mail-Adressen müssen übereinstimmen',
		passwordsMustMatch: 'Die Passwörter müssen übereinstimmen',
		pleaseConfirmYourEmail: 'Bitte bestätigen Sie Ihre Email',
		pleaseWriteDateOfBirth: 'Bitte geben Sie Ihr Geburtsdatum an',
		passwordCannotBeLessThan8Chart:
			'Ihr Passwort sollte mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten',
		pleaseWritePassword: 'Bitte geben Sie Ihr Passwort ein',
		pleaseWriteConfirmPassword: 'Bitte bestätigen Sie Ihr Passwort',
		haveToAgreeToTermsAndConditions: 'Sie müssen unseren Allgemeinen Geschäftsbedingungen zustimmen!',
		haveToAgreePrivacyPolicy: 'Sie müssen unserer Datenschutzerklärung zustimmen!',
		locationMustStartWithCapitalLetter: 'Standort muss mit einem Großbuchstaben beginnen',
		pleaseWriteCurrentLocation: 'Bitte geben Sie Ihren aktuellen Standort an',
		pleaseSelectDateOfBirth: 'Bitte wählen Sie Ihr Geburtsdatum',
		pleaseSelectSex: 'Bitte wählen Sie ein Geschlecht',
		pleaseEnterHeight: 'Bitte geben Sie Ihre Körpergröße ein',
		pleaseEnterWeight: 'Bitte geben Sie Ihr Gewicht ein',
		pleaseSelectOne: 'Bitte wählen Sie eine aus',
		pleaseSelectHealthInsurancePlan: 'Bitte wählen Sie Ihre Krankenkasse aus',
		pleaseWriteSubscriberId: 'Bitte geben Sie Ihre Abonnenten-ID an',
		pleaseWritePrimarySubscriberFirstName: 'Bitte schreiben Sie den Vornamen Ihres primären Abonnenten',
		pleaseWritePrimarySubscriberLastName: 'Bitte geben Sie den Nachnamen Ihres Hauptabonnenten ein',
		pleaseWritePrimarySubscriberBirthDate: 'Bitte geben Sie das Geburtsdatum Ihres Hauptabonnenten an',
		serviceKeyLearnMore:
			'Möglicherweise erhalten Sie von Ihrem Arbeitgeber, Ihrer Krankenversicherung oder Ihrem Gesundheitsdienstleister einen Serviceschlüssel, um einen neuen Dienst zu aktivieren. Es ist optional und nicht erforderlich.',
		numberTimesFallen: 'Anzahl der Fälle',
		lowest: 'am niedrigsten',
		average: 'Durchschnitt',
		highest: 'Höchste',
		changePresence: 'Anwesenheit ändern',
		height: 'Größe',
		width: 'Breite',
		bodyMassIndex: 'Body-Mass-Index',
		primaryDiagnosis: 'Primärdiagnose',
		headInjury: 'Kopfverletzung',
		basicInformation: 'Grundinformation',
		fillUpFormOnRightSide: 'Füllen Sie das Formular auf der rechten Seite mit Ihren echten Daten aus!',
		currentLocation: 'Aktueller Standort',
		back: 'Zurück',
		continue: 'Fortsetzen',
		signUp: 'Anmeldung',
		alreadyHaveAnAccount: 'Sie haben bereits ein Konto',
		clickHereToSignIn: 'Hier klicken um sich anzumelden',
		personalInformation: 'Persönliche Informationen',
		agreeToThe: 'Ich stimme dem zu',
		termsAndConditions: 'Geschäftsbedingungen',
		privacyPolicy: 'Datenschutz-Bestimmungen',
		healthInsurance: 'Krankenversicherung',
		doYouHaveHealthInsurance: 'Haben Sie eine Krankenversicherung?',
		yes: 'Ja',
		no: 'Nein',
		serviceKey: 'Dienstschlüssel',
		enterReceivedKey:
			'Wenn Sie einen Schlüssel von Ihrem Arbeitgeber oder einer anderen Gruppe erhalten haben, geben Sie ihn hier ein.',
		learnMore: 'Lern mehr',
		finish: 'Fertig',
		yourAccountHasBeenSuccessfullyCreated: 'Ihr Konto wurde erfolgreich erstellt!',
		checkEmailToActivateAccount: 'Überprüfen Sie Ihre E-Mail, um Ihr Konto zu aktivieren.',
		yourAccountActivated: 'Dein Konto wurde aktiviert!',
		continueToSignIn: 'Weiter zur Anmeldung',
		accountActivationFailedTryAgain: 'Kontoaktivierung fehlgeschlagen! Bitte versuche es erneut',
		nurse: 'Krankenschwester',
		outgoing: 'abgehend',
		incoming: 'Eingehend',
		viewAll: 'Alle ansehen',
		initiatorDevice: 'Initiatorgerät',
		callInitiator: 'Rufinitiator',
		room: 'Zimmer',
		dateSlashTime: 'Terminzeit',
		callOrigin: 'Ursprung anrufen',
		callStatus: 'Anrufstatus',
		callDuration: 'Gesprächsdauer',
		successful: 'Erfolgreich',
		successfulDescription: 'Alle Teilnehmer konnten beitreten.',
		partiallySuccessful: 'Teilweise erfolgreich',
		partiallySuccessfulDescription: 'Einige der Teilnehmer konnten aufgrund von Fehlern nicht beitreten.',
		failed: 'Fehlgeschlagen',
		failedDescription: 'Keiner der Teilnehmer konnte sich aufgrund von Fehlern verbinden.',
		disrupted: 'Unterbrochen',
		disruptedDescription:
			'Der Anruf wurde erfolgreich hergestellt, aber während des Anrufs verursachen die Handlungen von jemandem, dass alle die Fähigkeit verlieren, sich zu hören und zu sehen.',
		partiallyDisrupted: 'Teilweise unterbrochen',
		partiallyDisruptedDescription:
			'Der Anruf wurde erfolgreich hergestellt, aber während des Anrufs verursachen die Handlungen von jemandem, dass einige Teilnehmer die Fähigkeit verlieren, sich zu hören und zu sehen.',
		missed: 'Verpasst',
		missedDescription: 'Der Anruf wurde erfolgreich durchgeführt, aber der eingeladene Teilnehmer hat den Anruf verpasst.',
		aborted: 'Abgebrochen',
		abortedDescription: 'Der Anruf wurde beendet, während andere noch verbinden oder der Anruf noch nicht hergestellt wurde.',
		dropped: 'Abgebrochen',
		droppedDescription:
			'Eine Konferenz wird als abgebrochen markiert, wenn der Anruf hergestellt ist, aber der Teilnehmer die Verbindung trennt und dadurch die gesamte Konferenz endet.',
		excludeDurationValidation: 'Der Dauerausschluss sollte zwischen 1 und 60 Sekunden liegen!',
		excludeStatusValidation: 'Sie können nicht alle Anrufstatus ausschließen!',
		excludeDurationInfo:
			'Wenn der Dauerfilter angewendet wird, werden verpasste, abgebrochene und fehlgeschlagene Anrufe aus dem Bericht ausgeschlossen.',
		excludeCalls: 'Anrufe ausschließen',
		under: 'unter',
		withStatus: 'mit Status',
		audioUsed: 'Verwendetes Audio',
		videoUsed: 'Verwendetes Video',
		contentSharing: 'Teilen von Inhalten',
		queue: 'Warteschlange',
		export: 'Export',
		region: 'Region',
		hospital: 'Krankenhaus',
		department: 'Abteilung',
		floor: 'Etage',
		queueOfSomethingsThatWereCalled: 'Warteschlange der {value}s, die angerufen wurden',
		generalSettings: 'Allgemeine Einstellungen',
		closeSession: 'Sitzung schließen',
		selectMemberToInvite: 'Wählen Sie ein einzuladendes Mitglied aus',
		selectMeetingPlaceDevice: 'Wählen Sie einen Treffpunkt oder ein Gerät aus',
		deviceOnCall: 'Gerät bei einem anderen Anruf.',
		deviceIsBeingMonitoredBy: 'Dieses Gerät wird überwacht von',
		deviceOffline: 'Gerät offline',
		initiatingMonitoring: 'Überwachung wird eingeleitet...',
		notAnswering:
			'Verbindung fehlgeschlagen! Bitte schließen Sie das Fenster und öffnen Sie es erneut, um die Überwachung fortzusetzen.',
		leftCall: 'Verbindung zum Netzwerk konnte nicht wiederhergestellt werden. Bitte fügen Sie diesen Feed erneut hinzu.',
		disconnected: 'Der Patient hat den Anruf beendet.',
		connected: 'Verbunden',
		reconnecting: 'Bitte warten ... Versuch, sich wieder mit dem Netzwerk zu verbinden.',
		participantRemoved: 'Diese Sitzung wurde von einem Administrator beendet.',
		addToMonitoringView: 'Zur Überwachungsansicht hinzufügen',
		organization: 'Organisation',
		callLogs: 'Anrufprotokolle',
		collapse: 'Zusammenbruch',
		recentLowerCase: 'letzte',
		hospitals: 'Krankenhäuser',
		rounding: 'Visite',
		patientRequests: 'Patientenanfragen',
		solutions: 'Lösungen',
		providers: 'Anbieter',
		monitoring: 'Überwachung',
		close: 'Schliessen',
		addHospital: 'Krankenhaus hinzufügen',
		create: 'Schaffen',
		createMeeting: 'Meeting erstellen',
		monday: 'Montag',
		tuesday: 'Dienstag',
		wednesday: 'Mittwoch',
		thursday: 'Donnerstag',
		friday: 'Freitag',
		saturday: 'Samstag',
		sunday: 'Sonntag',
		bloating: 'Blähungen',
		cough: 'Husten',
		diarrhea: 'Durchfall',
		dizziness: 'Schwindel',
		fatigue: 'Ermüdung',
		fever: 'Fieber',
		headache: 'Kopfschmerzen',
		muscleCramp: 'Muskelkrampf',
		nausea: 'Brechreiz',
		throatIrritation: 'Halsschmerzen',
		runnyNose: 'laufende Nase',
		throwingUpVomiting: 'Erbrechen (Erbrechen)',
		soreThroat: 'Halsschmerzen',
		headPressure: 'Kopfdruck',
		lowBodyTemperature: 'Niedrige Körpertemperatur',
		highBodyTemperature: 'Hohe Körpertemperatur',
		backPain: 'Rückenschmerzen',
		appetiteChanges: 'Appetit ändert sich',
		earache: 'Ohrenschmerzen',
		sweating: 'Schwitzen',
		sneezing: 'Niesen',
		fainting: 'Ohnmacht',
		skinRash: 'Hautausschlag',
		insomnia: 'Schlaflosigkeit',
		itchySkin: 'Juckende Haut',
		jointPainAndSwelling: 'Gelenkschmerzen und -schwellungen',
		kneePain: 'Knieschmerzen',
		legPain: 'Beinschmerzen',
		limbNumbness: 'Gliedertaubheit',
		muscleAchesAndPains: 'Muskelschmerzen und Schmerzen',
		nosebleeds: 'Nasenbluten',
		shoulderPain: 'Schulterschmerzen',
		stomachPain: 'Magenschmerzen',
		swellingInJoints: 'Schwellung (in Gelenken)',
		tummyPain: 'Bauchschmerzen',
		addDepartment: 'Abteilung hinzufügen',
		support: 'Unterstützung',
		sla: 'SLA',
		na: 'N/V',
		addFloor: 'Etage hinzufügen',
		addRoom: 'Zimmer hinzufügen',
		goBackToHealthApp: 'Gehen Sie zurück zur hellocare App',
		fullScreen: 'Ganzer bildschirm',
		exitFullScreen: 'Beenden sie den vollbildmodus',
		fitToScreen: 'Vollbild',
		screenshare: 'Bildschirm teilen',
		sound: 'Klang',
		turnPatientTVOff: 'Patienten-TV ausschalten',
		turnPatientTVOn: 'Patientenfernseher einschalten',
		changeEmail: 'Ändern Sie die E-Mail',
		changePassword: 'Passwort ändern',
		currentPassword: 'Jetziges Passwort',
		enterCurrentPassword: 'Geben Sie Ihr aktuelles Passwort ein',
		enterYourCurrentPassword: 'Geben Sie Ihr aktuelles Passwort ein',
		enterNewPassword: 'Neues Passwort eingeben',
		reEnterNewPassword: 'Neues Passwort erneut eingeben',
		updatePassword: 'Passwort aktualisieren',
		editChangeAccountInfo: 'Bearbeiten oder ändern Sie Ihre Kontoinformationen',
		statusCurrentlyOnline: 'Ihr Status ist derzeit online',
		statusCurrentlyOffline: 'Ihr Status ist derzeit offline',
		passwordChangedSuccessfully: 'Ihr Passwort wurde erfolgreich geändert',
		emailChangedSuccessfully: 'Ihre E-Mail wurde erfolgreich geändert',
		oldPasswordIncorrect: 'Ihr aktuelles Passwort ist falsch',
		errorDuringRequestProcessing: 'Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten',
		passwordRequired: 'Passwort wird benötigt',
		atLeastCharacters: 'Passwort muss mindestens sein',
		characters: 'Figuren',
		notMatchCurrentPassword: 'Ihr neues Passwort muss sich von Ihrem vorherigen Passwort unterscheiden',
		mustMatchPassword: 'Die Passwörter müssen übereinstimmen',
		passwordConfirmationRequired: 'Passwortbestätigung ist erforderlich',
		participantCameraOff: '{value} hat die Kamera ausgeschaltet.',
		modifyUserInformation: 'Benutzerinformationen ändern',
		zipCode: 'Postleitzahl',
		country: 'Land',
		city: 'Stadt',
		state: 'Staat',
		visitCostCurrency: 'Kostenwährung besuchen',
		dateOfBirthRequired: 'Geburtsdatum ist erforderlich',
		countryRequired: 'Land ist erforderlich',
		stateRequired: 'Staat ist erforderlich',
		cityRequired: 'Stadt ist erforderlich',
		zipCodeRequired: 'Postleitzahl ist erforderlich',
		addressRequired: 'Adresse ist erforderlich',
		enterCity: 'Geben Sie Ihre Stadt ein',
		enterZipCode: 'Geben Sie Ihre Postleitzahl ein',
		enterAddress: 'Geben Sie Ihre Adresse ein',
		enterVisitCost: 'Geben Sie Ihre Besuchskosten ein',
		writeWelcomeMessage: 'Schreiben Sie Ihre Willkommensnachricht',
		profileEditedSuccessfully: 'Ihr Profil wurde erfolgreich bearbeitet',
		profilePicture: 'Profilbild',
		updatePicture: 'Bild aktualisieren',
		shouldUploadProfilePicture: 'Sie sollen ein Profilbild hochladen',
		acceptedFilesTypesAre: 'Akzeptierte Dateitypen sind',
		select: 'Auswählen',
		visitCostRequired: 'Besuchskosten sind erforderlich',
		visitCostCurrencyRequired: 'Besuchskostenwährung ist erforderlich',
		welcomeMessageRequired: 'Begrüßungsnachricht erforderlich',
		from: 'aus',
		to: 'zu',
		requestDoctor: 'Fordern Sie einen Arzt an',
		thankYou: 'Vielen Dank',
		requestSentSuccessfully: 'Ihre Anfrage wurde erfolgreich gesendet',
		goToThe: 'Geh zum',
		toGetStarted: 'um anzufangen',
		requests: 'Anfragen',
		noConversation: 'Sie haben kein Gespräch mit {value}',
		startConvo: 'Starten Sie das Gespräch, indem Sie eine Nachricht schreiben oder eine Datei anhängen',
		noRequestsFound: 'Keine Anfragen gefunden!',
		noMatchesFound: 'Keine Treffer gefunden',
		noDoctorsBasedOnSymptoms: 'Es wurden keine Ärzte gefunden, die Ihren Symptomen und Gesundheitsproblemen entsprechen',
		noHealthDataFound: 'Keine Gesundheitsdaten gefunden!',
		noHealthDataCurrently: 'Derzeit haben Sie keine zusätzlichen Gesundheitsdaten',
		newConnection: 'Neue Verbindung',
		isNowConnection: 'ist jetzt eine Verbindung.',
		removeConnection: 'Verbindung entfernen',
		callViewMessage:
			'Über das Patientenmenü können Sie die Gesundheitsdaten von {value} anrufen, Nachrichten senden oder anzeigen',
		goToPatient: 'Gehe zu Patient',
		newConfiguration: 'Neue Konfiguration',
		addPrivacyConfiguration: 'Datenschutzkonfiguration hinzufügen',
		editPrivacyConfiguration: 'Datenschutzkonfiguration bearbeiten',
		selectPrivacy: 'Wählen Sie Datenschutz',
		createdBy: 'Erstellt von',
		dateCreated: 'Erstellungsdatum',
		isHealthSystemActive: 'Ist das Gesundheitssystem aktiv',
		isPrivateModeOn: 'Ist der Datenschutzmodus aktiviert',
		healthCareId: 'Gesundheitsausweis',
		privacyModeIsOn: 'Der Datenschutzmodus ist aktiviert',
		configurations: 'Konfigurationen',
		turnOffSurgicalTools: 'OP-Modus ausschalten',
		turnOnSurgicalTools: 'OP-Modus einschalten',
		turnOffBabyDetection: 'Babymodus ausschalten',
		turnOnBabyDetection: 'Babymodus einschalten',
		turnOffPatientPosition: 'Patientenposition ausschalten',
		turnOnPatientPosition: 'Patientenposition einschalten',
		turnOffBedAndRails: 'Bett und Schienen ausschalten',
		turnOffSkeletonAndPrivacy: 'Schalte den Skelettmodus aus',
		turnOnBedAndRails: 'Schalten Sie Bett und Schienen ein',
		turnOnSkeletonAndPrivacy: 'Schalte den Skelettmodus ein',
		turnOnObjectDetection: 'Objekterkennung einschalten',
		turnOffObjectDetection: 'Objekterkennung ausschalten',
		surgicalToolsMissing: 'Fehlende chirurgische Instrumente',
		railDown: 'Schiene unten wurde erkannt',
		breathsPerMin: 'Atemzüge/Minute',
		unknownDevice: 'Unbekanntes Gerät',
		activities: 'Aktivitäten',
		inputValue: 'Wert',
		privateMode: 'Privater Modus',
		superUserRoleValidation: 'Die Superuser-Rolle kann nicht mehreren Gesundheitssystemen zugewiesen werden',
		superUserHealthSystemValidation: 'Der Superuser muss einem Gesundheitssystem zugewiesen sein',
		superUser: 'Super Benutzer',
		sentOnList: 'Gefühl',
		registeredOnList: 'Eingetragen',
		pendingOnList: 'Ausstehend',
		cancelledOnList: 'Abgesagt',
		failedOnList: 'Gescheitert',
		virtualSitter: 'Virtueller Sitter',
		undefinedOnList: 'Nicht definiert',
		successfulOnList: 'Erfolgreich',
		partiallySuccessfulOnList: 'Teilweise erfolgreich',
		disruptedOnList: 'Gestört',
		partiallyDisruptedOnList: 'Teilweise gestört',
		missedOnList: 'verpasst',
		abortedOnList: 'abgebrochen',
		droppedOnList: 'Fallen gelassen',
		on: 'An',
		off: 'Aus',
		fitnessTracker: 'Fitness-Tracker',
		bodyWeightScale: 'Körpergewichtswaage',
		weightScale: 'Gewichtsskala',
		weightScaleDevice: 'Gewichtsskala',
		noEvents: 'Es sind keine Veranstaltungen vorhanden.',
		glucometer: 'Glukometer',
		infraredEarThermometer: 'Infrarot-Ohrthermometer',
		thermometer: 'Thermometer',
		iphone: 'iPhone',
		pulseOximeter: 'Pulsoximeter',
		hours: 'Std',
		timeOfMeasurement: 'Zeitpunkt der Messung',
		fallDetected: 'Ein Sturz wurde erkannt',
		custom: 'Benutzerdefiniert',
		auto: 'Auto',
		audioSettings: 'Audio-Einstellungen',
		audioTuning: 'Audio-Anpassung',
		selectValue: 'Wert auswählen',
		selectOneOfTheOptions: 'Wählen Sie eine der Optionen',
		selectCustomValue: 'Benutzerdefinierten Wert auswählen',
		hdmiSyncDelay: 'HDMI-Systemverzögerung',
		detected: 'erkannt',
		hasMeasured: 'hat gemessen',
		callPatient: 'Patient anrufen',
		callPatientDescription: 'Wenn dies aktiviert ist, können Benutzer den Patienten anrufen, während sie ihn überwachen.',
		patientCommunicationDescription:
			'Wenn dies aktiviert ist, können Patienten eine Nachricht auf dem {helloName}-Display sehen, die besagt, dass sie überwacht werden.',
		patientCommunication: 'Nachricht zur Überwachung',
		monitoringPrecautionsLegend: 'Legende zu den Vorsichtsmaßnahmen bei der Überwachung',
		acknowledge: 'anerkennen',
		hasAcceptedYourRequest: 'hat Ihre Anfrage für die Kategorie {value} akzeptiert',
		hasRejectedYourRequest: 'hat Ihre Anfrage für die Kategorie {value} abgelehnt',
		hasCanceledRequest: 'hat die Anfrage für die Kategorie {value} storniert',
		hasSentRequest: 'hat Ihnen eine Anfrage für die Kategorie {value} gesendet',
		unread: 'Ungelesen',
		earlier: 'früher',
		alerts: 'Warnungen',
		upload: 'Hochladen',
		zipCodeDigitsMax: 'Postleitzahl muss kleiner oder gleich 10 Ziffern sein',
		quickOptions: 'Schnelloptionen',
		addDependents: 'Abhängige hinzufügen',
		measureYourVitalSigns: 'Vitalzeichen messen',
		updateProfile: 'Profil aktualisieren',
		upcomingAppointments: 'Anstehende Termine',
		goToMyAppointments: 'Geh zu meinen Terminen',
		goToMyRequests: 'Alle Anfragen anzeigen',
		vitalSigns: 'Vitalfunktionen',
		ewsAiVitalSigns: 'EWS AI Vitalzeichen',
		inputterFirstName: 'Vorname des Eingabeberechtigten',
		inputterLastName: 'Nachname des Eingabeberechtigten',
		goToMyHealthData: 'Gehen Sie zu meinen Gesundheitsdaten',
		limitedSelectedSymptoms: 'Sie können nicht mehr als 5 Symptome auswählen',
		comingSoon: 'Demnächst',
		pleaseTryAgainLater: 'Bitte versuchen Sie es später noch einmal',
		currentlyNoHealthDataMainAnalysis: 'Derzeit keine Gesundheitsdaten Hauptanalyse',
		currentlyNoVitalSigns:
			'{value} liegen derzeit keine zusätzlichen Gesundheitsdaten aus der Hauptanalyse vor. Klicken Sie auf „Go to the Vital Signs“, um alle Daten anzuzeigen.',
		extremelyHighTemperature: 'Der Patient hat extrem hohe Temperatur!',
		highTemperature: 'Der Patient hat hohe Temperatur!',
		lowTemperature: 'Der Patient hat niedrige Temperatur!',
		highBloodPressure: 'Der Patient hat Bluthochdruck!',
		extremelyHighPressure: 'Der Patient hat einen extrem hohen Druck!',
		extremelyLowBloodPressure: 'Der Patient hat einen extrem niedrigen Druck!',
		lowBloodPressure: 'Der Patient hat niedrigen Blutdruck!',
		lowHeartRate: 'Der Patient hat eine sehr niedrige Herzfrequenz!',
		highHeartRate: 'Der Patient hat eine sehr hohe Herzfrequenz!',
		highBloodGlucose: 'Der Patient hat einen sehr hohen Blutzucker!',
		overweight: 'Der Patient ist übergewichtig!',
		obese: 'Der Patient ist fettleibig!',
		underweight: 'Der Patient ist untergewichtig!',
		sleepDeficient: 'Der Patient hat Schlafmangel!',
		privacyMode: 'Datenschutzmodus',
		microphoneMuted: 'Mikrofon stumm',
		microphoneOn: 'Mikrofon an',
		cameraOn: 'Kamera an',
		cameraOff: 'Kamera aus',
		privacyModeOff: 'Datenschutzmodus deaktiviert',
		privacyModeOn: 'Datenschutzmodus aktiviert',
		checkDateOfBirth: 'Sie müssen mindestens 18 Jahre alt sein, um sich anzumelden',
		greaterThanEighteen: 'Sie müssen mindestens 18 Jahre alt sein',
		homePage: 'Startseite',
		newPatientRequests: 'Neue Patientenanfragen',
		noNewRequests: 'Sie haben keine neuen Anfragen',
		about: 'Um',
		termsOfUse: 'Nutzungsbedingungen',
		goTo: 'Gehe zu',
		domain: 'Domain',
		domainRequired: 'Domain ist erforderlich',
		validGroupName: 'Gültiger Gruppenname',
		group: 'Gruppe',
		allCaughtUp: 'Ihr seid alle eingeholt',
		noNewAppointmentsOrRequests: 'Sie haben keine neuen bevorstehenden Termine oder keine neuen Anfragen!',
		age: 'Das Alter',
		failedToLoad: 'Laden fehlgeschlagen',
		hvrIndex: 'HVR-Index',
		stressLevel: 'Belastungsniveau',
		mildlyCalm: 'Leicht ruhig',
		highlyTense: 'Hoch angespannt',
		slightlyTense: 'Leicht angespannt',
		quietlyRelaxed: 'Ruhig entspannt',
		deeplyRelaxed: 'Tiefenentspannt',
		vitalsCamera: 'Vitalkamera',
		cameraInUseHeader: 'Kamera wird bereits verwendet - Bitte schließen Sie andere Apps',
		cameraInUseParagraph1: 'Um am Meeting mit der Kamera teilzunehmen, deaktivieren Sie die Kamera in anderen Anwendungen',
		cameraInUseParagraph2: 'Aktualisieren Sie die Seite, um sie zu initialisieren',
		noAppointmentRequests: 'Sie haben keine Terminanträge!',
		noAcceptedAppointments: 'Sie haben keine akzeptierten Anfragen!',
		scheduleAvailability: 'Verfügbarkeit planen',
		schedulingAvailability: 'Planung basierend auf Ihrer Verfügbarkeit',
		addTopic: 'Thema hinzufügen',
		setupAccount: 'Einen Account erstellen',
		noSetupAccount:
			'Anscheinend haben Sie noch kein Kalenderkonto eingerichtet, klicken Sie auf die Schaltfläche unten, um fortzufahren',
		setupCalendarAccount: 'Richten Sie Ihr Kalenderkonto ein',
		viewSomeonesProfile: 'Profil von {value} ansehen',
		reSchedule: 'Umplanen',
		chooseAvailableTime: 'Wählen Sie Ihre verfügbare Zeit für ',
		letAvailbleSchedule: 'Lassen Sie die Patienten wissen, wann Sie verfügbar sind, um Besprechungen anzunehmen',
		setup: 'Konfiguration',
		availability: 'Verfügbarkeit',
		noUpcomingAppointments: 'Sie haben keine anstehenden Termine!',
		roomNoDeviceAssigned: 'Diesem Raum ist kein Gerät zugewiesen',
		roomsNeedDevice: 'Räumen muss ein Gerät zugewiesen sein',
		inOrderToCall: 'um anzurufen',
		createCompanyProfile: 'Unternehmensprofil erstellen',
		companyWebsite: 'Unternehmenswebseite',
		createCompany: 'Unternehmen erstellen',
		owner: 'Eigentümer',
		companyType: 'Unternehmenstyp',
		website: 'Webseite',
		companyId: 'Firmen-ID',
		goBack: 'Geh zurück',
		companySuccessfullyCreated: 'Unternehmen erfolgreich gegründet',
		meetingTime: 'Verabredungszeitpunkt',
		meetingDate: 'Datum des Treffens',
		noPendingAppointments: 'Sie haben keine ausstehenden Anfragen!',
		noRejectedAppointments: 'Sie haben keine abgelehnten Anfragen!',
		patientEmail: 'Patienten-E-Mail',
		familyMemberEmail: 'E-Mail-Adresse des Familienmitglieds',
		patientFirstName: 'Vorname des Patienten',
		familyMemberFirstName: 'Vorname des Familienmitglieds',
		patientLastName: 'Nachname des Patienten',
		familyMemberLastName: 'Nachname des Familienmitglieds',
		assignPatient: 'Patient zuordnen',
		assignmentMethod: 'Zuordnungsmethode',
		modalLogsTitle: 'Geräteprotokolle',
		modalLogsFailure: 'Geräteprotokolle konnten nicht abgerufen werden. Gerät ist offline.',
		modalLogsWentWrong: 'Etwas ist schief gelaufen. Bitte versuchen Sie es erneut',
		modalLogsSuccess: 'Auf dem Solaborate-System registrierte Geräteprotokolle. Sie können diese Nachricht schließen.',
		modalLogsButton: 'Geräteprotokolle abrufen',
		rebootExportLogsButton: 'Neustartprotokolle exportieren',
		rebootExportLogsNoLogsYet: 'Dieses Gerät hat noch keine Neustartprotokolle.',
		exportAsCSV: 'Als CSV exportieren',
		endpointWhitelisting: 'Endpunkt-Whitelisting',
		csv: 'CSV',
		reportedOn: 'Berichtete über',
		id: 'AUSWEIS',
		allowed: 'Erlaubt',
		denied: 'Bestritten',
		protocol: 'Protokoll',
		response: 'Antwort',
		searchPatient: 'Patient suchen',
		newPatient: 'Neuer Patient',
		existingPatient: 'bestehender Patient',
		requiredField: 'Pflichtfeld',
		selectedPatient: 'Ausgewählter Patient',
		nextDays: 'Tage',
		nextTime: 'Im nächsten',
		nextHours: 'Std',
		nextHour: 'Stunde',
		nextMinutes: 'Protokoll',
		availableHours: 'Verfügbare Stunden',
		availableDays: 'Verfügbare Tage',
		break: 'Pause',
		checkSelectedBreaks: 'Bitte überprüfen Sie die ausgewählten Pausenzeiten',
		sundays: 'sonntags',
		mondays: 'montags',
		tuesdays: 'dienstags',
		wednesdays: 'mittwochs',
		thursdays: 'donnerstags',
		fridays: 'freitags',
		saturdays: 'samstags',
		month: 'Monat',
		week: 'Woche',
		day: 'Tag',
		remote: 'Fernbedienung',
		clearData: 'Daten löschen',
		resetDevice: 'Rückstelleinrichtung',
		th: 'th',
		fileUploadError: 'Datei {value} existiert bereits',
		conversationHistory: 'Gesprächsverlauf',
		noDevices: 'Keine Geräte',
		oneDevice: '{value} Gerät',
		moreThanOneDevice: '{value} Geräte',
		healthSystemRequired: 'Gesundheitssystem ist erforderlich',
		hospitalRequired: 'Krankenhaus ist erforderlich',
		emailRequired: 'E-Mail ist erforderlich',
		userAlreadyExists: 'Dieser Benutzer existiert bereits',
		privacy: 'Privatsphäre',
		enablePrivacyMode: 'Datenschutzmodus aktivieren',
		privacyDescription: 'Wenn dies aktiviert ist, sieht jeder, der dieses Gerät anruft, eine unscharfe Ansicht der Kamera.',
		futureConfigurations: 'Zukünftige Konfigurationen',
		true: 'Stimmt',
		false: 'Falsch',
		forwarded: 'Weitergeleitet',
		acknowledged: 'Bestätigt',
		rotate: 'Drehen',
		filter: 'Filter',
		appointment: 'geplanter Termin',
		editMeeting: 'Besprechung bearbeiten',
		companyDetails: 'Firmendetails',
		companySettings: 'Firmeneinstellungen',
		companyAddress: 'Firmenanschrift',
		remotePatientMonitoring: 'Patienten-Fernüberwachung',
		continuousPatientMonitoring: 'Kontinuierliche Patientenüberwachung',
		numberOfPatients: 'Anzahl der Patienten',
		highRisk: 'Hohes Risiko',
		memberStatus: 'Mitgliedsstatus',
		disableAccess: 'Zugriff deaktivieren',
		moderateRisk: 'Moderates Risiko',
		searchByNameOrHello: 'Suchen Sie nach Patienten nach Name oder {value}',
		optional: 'Optional',
		uploadPicture: 'Bild hochladen',
		profileImageDescription: `Dieses Foto wird als Ihr Profilbild verwendet und ist für Patienten auf unserer Website und in der mobilen App sichtbar.
		Image can be .jpg or .png format and a size limit of 2MB.`,
		pleaseUploadPicture: 'Bitte laden Sie ein Profilbild hoch',
		maxSizePic: 'Bildgröße darf 600 KB nicht überschreiten.',
		commandNotSupported: 'Dieser Befehl wird auf dem Fernseher dieses Patienten nicht unterstützt.',
		userIdleConfigurations: 'Benutzerleerlaufkonfigurationen',
		minutes: 'Protokoll',
		editedBy: 'Bearbeitet von',
		dateEdited: 'Bearbeitungsdatum',
		historyFor: 'Geschichte für',
		backToConfigs: 'Gehen Sie zurück zu Konfigurationen',
		setTimeOut: 'Zeitüberschreitung festlegen',
		viewHistory: 'Siehe Verlauf',
		minutesGreaterThanZero: 'Minuten müssen größer als 0 sein',
		minutesRequired: 'Minuten sind erforderlich',
		userIdleFormTitle: 'Der Benutzer muss sich nach einer Zeit der Inaktivität erneut anmelden',
		selectHealthSystemOrAll: 'Gesundheitssystem auswählen oder Alle auswählen, um Inaktivitätszeitraum anzuwenden',
		setInActivityPeriod: 'Zeitraum der Inaktivität festlegen',
		userIdleNotifedPeriod:
			'Der Benutzer wird 30 Sekunden lang benachrichtigt, wenn er seine Anwesenheit nicht bestätigt, wird er automatisch abgemeldet',
		configurationSuccessfullyAdded: 'Konfiguration erfolgreich hinzugefügt',
		imHere: 'Ich bin hier',
		idleMessage: 'Sie waren {value} Minuten untätig.',
		idleMessageDescription:
			'Du wirst abgemeldet, falls du deine Anwesenheit nicht innerhalb der nächsten {value} Sekunden bestätigst',
		seeDoctorNow: 'Jetzt zum Arzt gehen',
		availableNow: 'ist sofort verfügbar',
		unAvailableForCallNow: 'ist derzeit nicht für einen Anruf verfügbar',
		hasPatientsInWaitingList: ' hat {value} andere Patienten auf der Warteliste',
		goToWaitingRoom: 'Geh ins Wartezimmer',
		pleaseSpecifySymptoms: 'Bitte geben Sie Ihre Symptome an, um den für Sie am besten geeigneten Arzt zu finden.',
		liveCallExpectedToStart: 'Ihr Live-Gespräch mit Dr. {value} beginnt voraussichtlich in',
		orLess: 'oder weniger',
		searchForDoctors: 'Arzt suchen und auswählen',
		additionalHealthData: 'Zusätzliche Gesundheitsdaten',
		additionalNotes: 'Zusätzliche Bemerkungen',
		clickHere: 'Klick hier',
		orDragAndDrop: 'oder ziehen und ablegen, um eine Datei hochzuladen',
		leaveWaitingRoom: 'Wartezimmer verlassen ',
		liveCallRequest: 'Live-Call-Anfrage',
		requestTime: 'Anfragezeit',
		notNow: 'Nicht jetzt',
		hasRequestedAliveCall: 'hat um ein Live-Gespräch mit Ihnen gebeten',
		haveRequestedAliveCall: 'haben um ein Live-Gespräch mit Ihnen gebeten',
		andNumberOthers: 'und {value} andere',
		typeHere: 'Geben Sie hier ein',
		notSpecified: 'Unbestimmt',
		changeDoctor: 'Arzt wechseln',
		diagnoses: 'Diagnosen',
		procedures: 'Verfahren',
		medicalInfoProcedures: 'Verfahren',
		notes: 'Notizen',
		medicalInfoNotes: 'Notizen',
		notesDescription: 'Wenn dies aktiviert ist, können Benutzer Notizen schreiben, während sie einen Patienten überwachen.',
		showNotesOnExpand: 'Notizen nur beim Erweitern anzeigen',
		showNotesUnderTile: 'Zeigen Sie Notizen unter jeder Kachel an',
		defaultVideoMonitoring: 'Standard-Videoüberwachung',
		defaultAmbientMonitoring: 'Standardumgebungsüberwachung',
		customize: 'Anpassen',
		prescriptions: 'Rezepte',
		medicalInfoPrescriptions: 'Rezepte',
		configurationExists: 'Diese Konfiguration existiert bereits!',
		deviceSettings: 'Geräteeinstellungen',
		languages: 'Sprachen',
		aiModels: 'AI-Modelle',
		selectMode: 'Auswahlmodus',
		mainDisplay: 'Hauptanzeige',
		secondDisplay: 'Zweites Display',
		secondaryDisplay: 'Sekundäranzeige',
		approval: 'Die Genehmigung',
		approvalNeeded: 'Um diese Funktion erfolgreich umzuschalten, benötigen Sie die Zustimmung des Patienten.',
		changeSettingsRequestAccepted: 'Patient {patient} hat Ihre Anfrage {acceptedOrRejected}',
		updateRequested: 'Aktualisierung angefordert',
		updateNow: 'Jetzt aktualisieren',
		softwareUpdate: 'Software-Update',
		markAllAsRead: 'Alles als gelesen markieren',
		selectDoctor: 'Arzt auswählen',
		tvMode: 'TV-Modus',
		gamingMode: 'Spielmodus',
		cleanMode: 'Reinigungsmodus',
		widgetMode: 'Widget-Modus',
		autoAnswer: 'Automatische Antwort',
		appInstaller: 'App-Installer',
		audioLevel: 'Audiopegel',
		portraitMode: 'Portraitmodus',
		landscape: 'Landschaft',
		portrait: 'Porträt',
		portraitReverse: 'Anzeigeausrichtung',
		deviceOrientation: 'Display Orientation',
		connectPatientsHelloToWifi: 'Verbinden Sie den Patienten {value} mit Wifi',
		and: 'und',
		maxSymptomsNo: 'Die maximale Anzahl der Symptome beträgt 10',
		haveCompleted: ' haben beendet',
		haveCanceled: 'haben abgesagt',
		yourRequestWith: 'Ihre Anfrage mit',
		yourLiveCallWith: 'Ihr Live-Anruf mit',
		deleteHealthSystemMember: 'Möchten Sie dieses Gesundheitssystem wirklich von diesem Mitglied löschen?',
		dateValidation: 'Bitte wählen Sie ein früheres Startdatum als das Enddatum aus.',
		twentyHrsBetween: 'Bitte wählen Sie 23',
		notAvailableNow: 'Leider ist der Anbieter derzeit nicht verfügbar. Bitte wählen Sie unten eine der Verfügbarkeitszeiten aus:',
		notAvailableToday: 'Leider ist der Anbieter heute nicht erreichbar. Bitte wählen Sie ein anderes Datum aus.',
		showForm: 'Formular anzeigen',
		priority: 'Priorität',
		selectPriority: 'Priorität auswählen',
		changesSaved: 'Änderungen gespeichert.',
		poolRoleRequired: 'Pool-Rolle ist erforderlich.',
		requestSentToAllDoctors: 'Anfrage an alle Ärzte versandt',
		firstNameMinLength: 'Vorname muss mindestens 2 Zeichen lang sein',
		lastNameMinLength: 'Nachname muss mindestens 2 Zeichen lang sein',
		pleaseWritePhoneNumber: 'Bitte schreiben Sie Ihre Handynummer',
		pleaseSelectPrimarySpecialty: 'Bitte wählen Sie eine Hauptfachrichtung aus',
		pleaseWriteLicenseNumber: 'Bitte schreiben Sie eine Lizenznummer',
		pleaseWriteNPINumber: 'Bitte geben Sie Ihre NPI-Nummer an',
		npiMaxLength: 'NPI-Nummer darf höchstens 10 Zeichen lang sein',
		atLeastOneState: 'Mindestens ein Staat sollte ausgewählt werden',
		selectedItems: 'Ausgewählte Gegenstände',
		valueItemSelected: '{value} ausgewählt',
		roles: 'Rollen',
		activeDirectoryInvite: 'Active Directory Einladung',
		doctor: 'Arzt',
		superAdmin: 'Höchster Vorgesetzter',
		superuser: 'Super User',
		noAdded: 'Sie haben keinen zusätzlichen {value}',
		addAnother: 'Füge einen weiteren {value} hinzu',
		cancelVisit: 'Besuch abbrechen',
		submitWrapUp: 'Zusammenfassung senden',
		addNote: 'Notiz hinzufügen',
		addDiagnose: 'Diagnose hinzufügen',
		addProcedure: 'Verfahren hinzufügen',
		addedNotes: 'Notizen hinzugefügt',
		title: 'Titel',
		selectPharmacy: 'Apotheke auswählen',
		pharmacy: 'Apotheke',
		noMoreHealthSystems: 'Es gibt keine anderen Gesundheitssysteme.',
		whereToSendPrescriptions: 'Wohin sollen Ihre Rezepte geschickt werden?',
		findPharmacy: 'Apotheke finden',
		miles: 'Meilen',
		homeDelivery: 'Hauslieferung',
		pickUp: 'Mitnehmen',
		errorLoading: 'Ladefehler',
		searchPharmacies: 'Apotheken suchen',
		startCall: 'Anruf starten',
		changePharmacy: 'Apotheke wechseln',
		enterDescription: 'Beschreibung hier eingeben',
		enterTopic: 'Thema hier eingeben',
		editAppointment: 'Termin bearbeiten',
		discardChanges: 'Änderungen verwerfen?',
		discard: 'Verwerfen',
		discardDescription: 'Durch das Schließen des Modals verwerfen Sie alle von Ihnen eingegebenen Daten.',
		dosage: 'Dosierung',
		frequency: 'Frequenz',
		duration: 'Dauer',
		dispense: 'Dosieren',
		refills: 'Nachfüllungen',
		isRequired: 'ist nötig',
		maxLengthIs: 'Maximale Länge ist',
		medicineSelection: 'Arzneimittelauswahl',
		dispensedAsWritten: 'Abgabe wie geschrieben, wenn medizinisch notwendig',
		noteToPharmacist: 'Hinweis für Apotheker',
		patientInstructions: 'Patientenanweisungen',
		quantityToDispense: 'Auszugebende Menge',
		timesAday: 'Mal am Tag',
		capsule: 'Kapsel',
		addedPrescriptions: 'Rezepte hinzugefügt',
		typeOfDosage: 'Art der Dosierung',
		orUntilDiretedToStop: '(oder) bis zum Anhalten aufgefordert',
		onlyAsNeeded: '„Nur bei Bedarf“',
		default: 'Standard',
		numberOfDays: 'Anzahl der Tage',
		appointmentCreated: 'Termin erfolgreich erstellt',
		appointmentUpdated: 'Termin erfolgreich aktualisiert',
		appointmentDetails: 'Ihre Termindetails sind',
		change: 'Veränderung',
		confirm: 'Bestätigen',
		successfulSignIn: 'Erfolgreiche Anmeldung',
		successfulSignInDescription: 'Ihr {value} wurde erfolgreich mit hellocare verbunden.',
		errorSignIn: 'Fehler beim Anmelden',
		errorSignInDescription: 'Es konnte keine Verbindung zu Ihrem {value} hergestellt werden, bitte versuchen Sie es erneut.',
		invalidFileType: 'Ungültiger Dateityp.',
		hideForm: 'Formular ausblenden',
		addSuperUser: 'Superbenutzer hinzufügen',
		myWorkflow: 'Mein Arbeitsablauf',
		directWorkflow: 'Direkter Workflow',
		throughSomethings: 'Durch {value}s',
		selectMyWorkflow: 'Wählen Sie meinen Arbeitsablauf',
		pluralRoleName: '{value}s',
		clickHereAddSomething: 'Klicken Sie hier, um eine neue {role} hinzuzufügen',
		inviteSomethings: 'Einladen {value}s',
		sectorIsRequired: 'Sektorname darf nicht leer sein',
		timeZoneMustBeSet: 'Zeitzone muss eingestellt sein',
		sectorNameMinLength: 'Sektorname muss mindestens 2 Zeichen lang sein',
		writeSectorName: 'Sektornamen schreiben',
		writeDeviceSerialNumber: 'Seriennummer des Geräts schreiben',
		writeTvSerialNumber: 'Seriennummer des Fernsehers schreiben',
		healthSystemMinLength: 'Name des Gesundheitssystems muss mindestens 2 Zeichen lang sein',
		nextStep: 'Nächster Schritt',
		minLengthIs: 'Mindestlänge ist',
		areYouSureDeleteItem: 'Möchten Sie diesen {value} wirklich löschen?',
		addPrescription: 'Rezept hinzufügen',
		medicalInfo: 'Medizinische Info',
		addMedicalInfo: 'Hizufügen {value},',
		diagnose: 'Diagnostize',
		note: 'Hinweis',
		prescription: 'Verschreibung',
		titleIsRequired: 'Titel ist erforderlich',
		appointmentSlotIsRequired: 'Bitte wählen Sie einen Termin aus',
		personIsRequired: 'Person ist erforderlich',
		descriptionIsRequired: 'Beschreibung ist erforderlich',
		descriptionMustHaveLetter:
			'Die Beschreibung darf nicht nur Leerzeichen, Zahlen oder Symbole enthalten. Muss Buchstaben enthalten.',
		rebootDevice: 'Gerät neustarten',
		factoryReset: 'Werkseinstellungen zurückgesetzt',
		rebootDeviceQuestion: 'Warum möchten Sie das Gerät neu starten',
		resetDeviceQuestion: 'Warum möchten Sie das Gerät zurücksetzen?',
		rebootDeviceReason: 'Beschreiben Sie den Grund für den Neustart...',
		resetDeviceReason: 'Bitte beschreiben Sie den Grund für die Einleitung des Werksresets...',
		resetDeviceUndone:
			'Diese Aktion kann nicht rückgängig gemacht werden. Möchten Sie wirklich einen Werksreset dieses Geräts einleiten mit',
		clearDataQuestion: 'Warum möchten Sie Gerätedaten löschen?',
		clearDataReason: 'Beschreiben Sie den Grund für das Löschen von Daten',
		editDetails: 'Details bearbeiten',
		otherDevices: 'andere Geräte',
		areYouSureDeletePrescription: 'Möchten Sie dieses Rezept wirklich löschen?',
		inactiveConference: 'Patient ist gegangen',
		inactiveConferenceDesc: 'Der Patient hat den virtuellen Raum bereits verlassen',
		joinCall: 'Anruf beitreten',
		expandLatestHealthData: 'Neueste Gesundheitsdaten anzeigen',
		collapseLatestHealthData: 'Aktuelle Gesundheitsdaten ausblenden',
		minutesInWaitingRoom: 'Minuten im Wartezimmer',
		rejectedRequest: 'Ihre Anfrage abgelehnt',
		selectHelloDevice: 'Wählen Sie das Gerät {value}',
		otherMedicalInfo: 'Andere medizinische Informationen',
		mobile: 'Handy, Mobiltelefon',
		laptop: 'Laptop',
		youHaveLengthPatients: 'Sie haben {Länge} {Patienten} im Wartezimmer',
		bodyFat: 'Körperfett',
		muscleMass: 'Muskelmasse',
		bodyWater: 'Körperwasser',
		symptoms: 'Symptome',
		transferToDoctor: 'Überweisung zum Arzt',
		transferToDoctorWarning:
			'Wenn Sie den Anruf an Dr. {Arzt} weiterleiten, wird der Anruf mit {Patient} beendet. Sind Sie sicher, dass Sie fortfahren möchten?',
		aiPrivacyMode: 'AI-Datenschutzmodus',
		noMoreThanTwoAI: '`Sie können nicht mehr als zwei KI-Modelle hinzufügen`',
		soundOn: 'Ton an',
		soundOff: 'Ton aus',
		nightVisionOn: 'Nachtsicht ein',
		nightVisionOff: 'Nachtsicht aus',
		reportAiDetection: 'KI-Erkennung melden',
		detectionReported: 'KI-Erkennung erfolgreich gemeldet',
		visitWith: 'Besuch mit',
		mainDetails: 'Hauptdetails',
		lengthOfVisit: 'Besuchsdauer',
		viewDetails: 'Details anzeigen',
		newAppointmentRequest: 'Sie haben eine neue Terminanfrage mit',
		onDate: 'an',
		has: 'hat',
		yourAppointmentInvitation: 'Ihre Termineinladung',
		youHaveAppointmentWith: 'Sie haben einen Termin mit',
		inMinutesOn: 'in {value} Minuten am',
		hasAcceptedAppointment: 'hat Ihre Termineinladung angenommen',
		hasRejectedAppointment: 'hat Ihre Termineinladung abgelehnt',
		hasCanceledAppointment: 'hat Ihre Termineinladung storniert',
		atDate: 'bei',
		youHaveAppointmentRequest: 'Sie haben eine Terminanfrage mit',
		updated: 'Aktualisiert',
		rescheduled: 'verschoben',
		expand: 'Erweitern',
		reportAsFalse: 'Als falsch melden',
		callEmergency: 'Notruf',
		markFalseAlert: 'Als Fehlalarm markieren',
		markAsSeen: 'Als gesehen markieren',
		resultsFor: 'Ergebnisse für {value}',
		patientExists: 'Dieser Patient existiert bereits!',
		dataAcquisition: 'Datenerfassung',
		allRequests: 'Alle Anfragen',
		opened: 'Geöffnet',
		cancelAppointmentTitle: 'Termin stornieren?',
		cancelAppointment: 'Termin stornieren',
		cancelAppointmentQuestion: 'Möchten Sie diesen Termin wirklich stornieren?',
		alertHistory: 'Warnungsverlauf',
		healthData: 'Gesundheitsdaten',
		roundingHealthData: 'Gesundheitsdaten',
		healthDataAlerts: 'Warnungen zu Gesundheitsdaten',
		artificialIntelligence: 'Künstliche Intelligenz',
		wasDetected: 'wurde entdeckt bei',
		timeline: 'Zeitleiste',
		youNeedAuthorizedSomething: `Sie müssen mindestens 1 {roleNameNurse} autorisieren, um den Workflow auf "Durch {roleNameNurse}e" zu ändern`,
		timeSlotExists: 'Sie haben bereits einen Termin in dieser Zeit.',
		hideEmergencyButtons: 'Notruftasten ausblenden',
		showEmergencyButtons: 'Notruftasten anzeigen',
		appointmentDetail: 'Termindetails',
		invitee: 'Eingeladener',
		selectOneOption: 'Wählen Sie eine der folgenden Optionen',
		noCanceledAppointments: 'Sie haben keine stornierten Anfragen!',
		editOrder: 'Bestellung bearbeiten',
		reorderSessions: 'Sitzungen neu anordnen',
		rearrangeMonitoringSessions: 'Drag-and-Drop, um Überwachungssitzungen neu anzuordnen',
		code: 'Code',
		alreadyAddedBreakTime: 'Sie haben diese Pausenzeit bereits hinzugefügt',
		completeAppointmentTitle: 'Termin abschließen?',
		completeAppointmentQuestion: 'Sind Sie sicher, dass Sie diesen Termin abschließen möchten?',
		markAsCompleted: 'Als erledigt markieren',
		contactSomethings: 'Kontaktieren Sie {value}',
		contactNurse: 'Kontaktieren Sie {roleNameNurse}',
		contactNursesDescription:
			'Wenn dies aktiviert ist, kann der Benutzer {roleNameNurse} kontaktieren, indem er 3 Optionen auswählt: Eine Gruppen-Nachricht senden, einen {roleNameNurse} im Dienst anrufen oder einen spezifischen {roleNameNurse} kontaktieren.',
		falseAlert: 'Fehlalarm',
		forwardToNurses: 'Weiterleiten an Frontline Nurses',
		forwardToFrontlineNurses: 'Weiterleiten an Krankenschwestern an vorderster Front',
		forwardToNursesDescription:
			'Kommunikations-Workflow für die Alarme, die von Virtual Nurse oder eSitter an die Krankenpfleger weitergeleitet werden sollen.',
		sendGroupMessage: 'Gruppennachricht senden',
		callSomethingOnDuty: 'Rufe {value}s im Dienst',
		specificSomething: 'Spezifisch {value}',
		stopTalking: 'Klicken, um mit dem Sprechen aufzuhören',
		checkBreakTime: 'Bitte fügen Sie eine Pausenzeit innerhalb der verfügbaren Stunden hinzu',
		teams: 'Mannschaften',
		sendMessage: 'Nachricht senden',
		message: 'Nachricht',
		chat: 'Plaudern',
		sort: 'Sortieren',
		viewLatestAlerts: 'Sehen Sie sich die neuesten Benachrichtigungen an',
		sharedAttachments: 'Freigegebene Anhänge',
		links: 'Links',
		sharedLinks: 'Geteilte Links',
		images: 'Bilder',
		sharedImages: 'Geteilte Bilder',
		videos: 'Videos',
		sharedVideos: 'Geteilte Videos',
		noTeamMembers: 'Keine Teammitglieder',
		noTeamsYet: 'Noch keine Mannschaften',
		noSomethingsAvailableTryAgainLater: 'Keine {value}s verfügbar. Bitte versuchen Sie es später erneut.',
		receivedAlert: 'eine Benachrichtigung erhalten',
		sentAlert: 'eine Benachrichtigung gesendet',
		addedComment: 'einen Kommentar hinzugefügt',
		markedThe: 'markiert die',
		alertAcknowledged: 'Warnung als bestätigt',
		wasAddedAs: 'wurde hinzugefügt als',
		hospitalCareTaker: 'Hausmeister im Krankenhaus“',
		alertDetails: 'Alarmdetails',
		measured: 'gemessen',
		forwardedAlertToSomethings: 'hat den Alarm an {value}s weitergeleitet',
		raiseAlert: 'Alarm auslösen',
		raiseAnAlertManually: 'Lösen Sie manuell eine Warnung aus',
		raiseAlertDescription:
			'Über diese Option kann {roleNameNurse} manuell einen Alarm für diesen Patienten auslösen. Dieser Alarm wird an die Pfleger am Bett oder an die Frontlinienpfleger weitergeleitet.',
		typeOfAlert: 'Art der Warnung',
		isAtRisk: 'ist gefährdet durch',
		bloodType: 'Blutgruppe',
		latestVitalSigns: 'Neueste Vitalzeichen',
		latestDiagnosis: 'Neueste Diagnose',
		chronicDiseases: 'Chronische Krankheit',
		allergies: 'Allergien',
		goToAllScripts: 'Gehen Sie zu Allscripts EHR',
		alertAsFalse: 'Warnung als falsch',
		manualAlerts: 'Manuelle Benachrichtigungen',
		raisedAnAlert: 'Alarm ausgelöst',
		badURL: 'Schlechte URL. Das URL-Format sollte wie https sein',
		symbolAndSpacesNotAllowed: 'Symbole und Leerzeichen sind nicht erlaubt',
		symbolsNotAllowed: 'Symbole sind nicht erlaubt!',
		call911: '911 anrufen',
		call911Description: 'Wenn dies aktiviert ist, können Benutzer 911 mit einem Klick anrufen.',
		addComment: 'Einen Kommentar hinzufügen',
		saveComment: 'Kommentar speichern',
		forwardToNoraApp: 'Weiterleiten an Nora App',
		mainMedicalFamilyCenter: 'Hauptmedizinisches Familienzentrum',
		medicalFamilyCenter: 'Medizinisches Familienzentrum',
		digitalClinic: 'Digitale Klinik',
		checkIn: 'Check-In',
		checkOut: 'Check-out',
		checkInPage: 'Check-In',
		noCheckInAllowed: 'Einchecken ist nicht erlaubt',
		userIsCheckedIn: 'Benutzer mit der E-Mail',
		hangUp: 'Auflegen',
		calling: 'Berufung',
		emergencyCall: 'Notruf',
		callEnded: 'Anruf beendet.',
		typeNameForPool: 'Geben Sie den Namen für diesen Pool ein',
		selectRoles: 'Rollen auswählen',
		selectRoleAppy: 'Rolle auswählen, um Active Directory anzuwenden',
		checkInPatient: 'Patienten einchecken',
		selectPatient: 'Patienten auswählen',
		patientHealthDetails: 'Symptome und zusätzliche Gesundheitsdaten',
		measureVitalSigns: 'Messen Sie die Vitalfunktionen des Patienten',
		searchForPatients: 'Suche nach Patienten',
		addNewPatient: 'Neuen Patienten hinzufügen',
		addPatient: 'Patient hinzufügen',
		results: 'Ergebnisse',
		showConsent: 'Zustimmung des Patienten',
		idNumber: 'ID-Nummer',
		selectThePatient: 'Patient auswählen',
		typeSymptoms: 'Geben Sie hier Symptome und Anzeichen ein',
		selectedSymptoms: 'Nachdem Sie die Symptome ausgewählt haben, werden sie hier angezeigt',
		howLongSymptoms: 'Wie lange haben Sie die Symptome?',
		preExistingCondition: 'Haben Sie Vorerkrankungen? Wenn ja, dann geben Sie sie bitte unten ein',
		anyAllergies: 'Hast du irgendwelche Allergien?',
		dragAndDropOrUpload: 'Klicken Sie hier oder ziehen Sie eine Datei hoch, um sie hochzuladen',
		measurePatientVitalSigns: '`Bitte messen Sie die Vitalzeichen des Patienten`',
		saveAndSelectPatient: 'Patient speichern und auswählen',
		addManually: 'Von Hand eintragen',
		userExists: 'Dieser Benutzer existiert bereits!',
		viewDevices: 'Geräte anzeigen',
		assignDevicesList: 'Liste der zugewiesenen Geräte',
		monitoringSettings: 'Überwachungseinstellungen',
		editMonitoringSettings: 'Überwachungseinstellungen bearbeiten',
		nightVision: 'Nachtsicht',
		nightVisionDescription: 'Wenn dies aktiviert ist, können Benutzer sehen, wenn der Raum dunkel ist.',
		EHRDescription: 'Zugriff auf die EHR-Daten des Patienten.',
		microsoftTeams: 'Microsoft Teams',
		microsoftTeamsDescription: 'Kommunikationsworkflow mit der Microsoft Team App des Krankenhauses',
		walkieTalkie: 'Walkie-Talkie',
		walkieTalkieDescription:
			'Audioübertragung an alle Krankenpfleger und virtuellen Krankenpfleger, die in der jeweiligen Etage eingecheckt sind.',
		addDigitalClinician: 'Digital Clinician hinzufügen',
		maxSizeExceeded: 'Versuchen Sie, eine Datei mit weniger als 2 MB hochzuladen',
		maxCompanySizeExceeded: 'Versuchen Sie, eine Datei mit weniger als 600 KB hochzuladen',
		appointmentDateRequired: 'Datum des Termins ist erforderlich',
		timeSlotRequired: 'Zeitfenster ist erforderlich',
		editSafeRanges: 'Sichere Bereiche bearbeiten',
		thresholdAlertDescription:
			'Geben Sie basierend auf dem Zustand des Patienten minimale und maximale Werte für alle Gesundheitsdaten ein',
		doctorPooling: 'Ärzte Pooling',
		doctorList: 'Ärzteliste',
		addDoctor: 'Arzt hinzufügen',
		searchDoctor: 'Arzt suchen',
		selectedDoctors: 'Ausgewählte Ärzte',
		sureToDeleteDoctor: 'Sind Sie sicher, dass Sie Arzt {value} löschen möchten?',
		deleteDoctor: 'Arzt löschen',
		checkedIn: 'Eingecheckt',
		checkedOut: 'Geprüft',
		placement: 'Platzierung',
		seeDoctorInDC: 'Arzt in digitaler Klinik aufsuchen',
		patientIsInVisit: 'Patient ist bereits in Besuch!',
		availableForCallNow: 'ist jetzt für einen Anruf verfügbar',
		healthDataSafeRanges: 'Sicherheitsbereiche für Gesundheitsdaten',
		medicalReport: 'Medizinischer Bericht',
		doctorsStampSignature: 'Stempel&Unterschrift des Arztes',
		doctorFullName: 'Vollständiger Name des Arztes',
		printReports: 'Berichte drucken',
		printReport: 'Druckbericht',
		capsulesDosage: '{value} Kapseln',
		oneCapsule: '{value} Kapsel',
		specialty: 'Spezialität',
		refillsLowercase: 'Nachfüllungen',
		cityMustHaveLetter: 'Stadt muss Buchstaben enthalten',
		ok: 'OK',
		measuredIn: 'gemessen in',
		completeRegistration:
			'Wir empfehlen Ihnen, Ihre persönlichen und beruflichen Daten in das untenstehende Formular einzutragen.',
		dragAndDropOr: 'Ziehen und ablegen oder',
		click: 'Klick hier',
		uploadProfilePicture: 'um dein Profilbild hochzuladen',
		addLicense: 'Lizenz hinzufügen',
		licenseNo: 'Lizenznummer',
		selectDate: 'Datum auswählen',
		selectCountry: 'Land auswählen',
		selectSpecialty: 'Spezialität auswählen',
		saveAndContinue: 'Speichern&fortsetzen',
		position: 'Position',
		addPosition: 'Position hinzufügen',
		company: 'Gesellschaft',
		educationHistory: 'Bildungsgeschichte',
		school: 'Schule',
		degree: 'Grad',
		startYear: 'Startjahr',
		endYear: 'Jahresende',
		employmentHistory: 'Erwerbsbiografie',
		addEducation: 'Ausbildung hinzufügen',
		licenses: 'Lizenzen',
		reset: 'Zurücksetzen',
		companyNameRequired: 'Firmenname ist erforderlich',
		doctorsStamp: 'Ärzte Stempel',
		doctorsSignature: 'Unterschrift des Arztes',
		companyWebsiteRequired: 'Unternehmens-Website ist erforderlich',
		companyAddressRequired: 'Firmenadresse ist erforderlich',
		somethingWrongWithConference: 'Beim Erstellen der Konferenz ist etwas schief gelaufen!',
		patientIsOnWaitingRoom: 'Der Patient ist jetzt im Wartezimmer!',
		deviceOfflineNow: 'Gerät ist momentan offline. Bitte versuchen Sie es erneut!',
		personalNumber: 'Persönliche Nummer',
		pleaseWriteIdNumber: 'Bitte schreiben Sie Ihre ID-Nummer',
		idNumberLength: 'Die ID-Nummer sollte aus 10 Zeichen bestehen und nicht mit Null beginnen.',
		patientHealthCardNumberLength:
			'Die Nummer der Patienten-Gesundheitskarte sollte aus 10 bis 20 Zeichen bestehen und nicht mit Null beginnen.',
		diabetes: 'Diabetes',
		oxygen: 'Sauerstoff',
		distanceWalkingRunning: 'Geh- und Laufstrecke',
		temperature: 'Temperatur',
		pi: 'Durchblutungsindex',
		numberOfTimesFallen: 'Anzahl der Fälle',
		pleaseUploadProfilePicture: 'Bitte laden Sie ein Profilbild hoch',
		beforeContinue: 'Bevor Sie fortfahren',
		currentlyWorkingHere: 'Arbeite gerade hier',
		currentlyStudyingHere: 'Studiere gerade hier',
		high: 'Hoch',
		low: 'Niedrig',
		criticalHigh: 'Kritisch hoch',
		criticalLow: 'Kritisch niedrig',
		french: 'Französisch',
		german: 'Deutsch',
		dutch: 'Niederländisch',
		turkish: 'Türkisch',
		spanish: 'Spanisch',
		englishUnitedStates: 'Englisch (USA)',
		serbian: 'Serbisch',
		graduateSchool: 'Fakultät',
		emergencyMedicinePhysician: 'Arzt für Notfallmedizin',
		hospitalist: 'Hospitalist',
		internist: 'Internist',
		pediatrician: 'Kinderarzt',
		psychiatrist: 'Psychiater',
		childPsychologist: 'Kinder Psychologe',
		counselor: 'Berater',
		psychologist: 'Psychologe',
		psychotherapist: 'Psychotherapeut',
		other: 'Sonstiges',
		newVersionAvailable: 'Neue Version der App ist verfügbar',
		checkInOtherPatient:
			'Wenn Sie weiterhin einen anderen Patienten einchecken, gehen alle aktuellen Änderungen, die Sie vorgenommen haben, verloren. Möchten Sie Ihre Änderungen wirklich verwerfen und mit dem Eincheckvorgang von vorne beginnen?',
		discardAndContinue: 'Änderungen verwerfen und fortfahren',
		currentSignature: 'Aktuelle Signatur',
		cancelCall: 'Anruf abbrechen',
		deviceOfflineTryLater: 'Dieses Gerät ist offline. Versuchen Sie es später noch einmal.',
		deviceOnCallTryLater: 'Dieses Gerät ist gerade in einem Gespräch. Versuchen Sie es später noch einmal.',
		deviceOfflineTryLaterExtended: '{value1} ist offline. Versuchen Sie es später noch einmal.',
		deviceOnCallTryLaterExtended: '{value1} telefoniert gerade. Versuchen Sie es später noch einmal.',
		cannotConnect: 'Kann nicht verbinden. Bitte versuche es erneut.',
		callDeclined: 'Anruf wurde abgelehnt',
		callEndedTryAgain: 'Anruf ist beendet. Bitte versuche es erneut',
		failedReconnect: 'Verbindung zum Netzwerk konnte nicht wiederhergestellt werden. Bitte versuchen Sie es erneut.',
		idleCallEnded: 'Sie waren zu lange untätig. Anruf ist beendet.',
		riskOfFall: 'Absturzgefahr',
		patientNeedsHelp: 'Patient braucht Hilfe',
		patientRequiresHelp: 'Patient braucht Hilfe',
		otherActivity: 'Andere Aktivität',
		searchPatientByName:
			'Bitte suchen Sie den Patienten nach Vor- und Nachname, um die Informationen zu seinen Besuchen anzuzeigen',
		switchToHelloSource: 'Wechseln Sie zu HELLO Source',
		powerOnTV: 'TV einschalten',
		powerOffTV: 'Fernseher ausschalten',
		time: 'Zeit',
		macAddress: 'MAC-Adresse',
		macUpperAddress: 'MAC-Adresse',
		appVersion: 'App Version',
		androidVersion: 'Android-Version',
		osVersion: 'OS Version',
		ipAddress: 'IP Adresse',
		stethoscopeAudioRecordings: 'Stethoskop-Audioaufnahmen',
		selectPatientAndContinue: 'Bitte wählen Sie einen der Patienten aus, um mit weiteren Verfahren fortzufahren',
		unit: 'Einheit',
		pleaseWriteHeight: 'Bitte schreiben Sie Ihre Größe',
		pleaseWriteWeight: 'Bitte schreiben Sie Ihr Gewicht',
		pleaseWriteTotalCholesterol: 'Bitte geben Sie Ihr Gesamtcholesterin an',
		pleaseWriteHdlCholesterol: 'Bitte schreiben Sie Ihr HDL-Cholesterin',
		pleaseWriteWaistCircumference: 'Bitte geben Sie Ihren Taillenumfang an',
		centimeters: 'Zentimeter',
		kilograms: 'Kilogramm',
		pleaseSelectCountry: 'Bitte wähle ein Land',
		pleaseWriteCity: 'Bitte schreiben Sie eine Stadt',
		pleaseWriteZipCode: 'Bitte schreiben Sie eine Postleitzahl',
		pleaseWriteAddress: 'Bitte schreiben Sie eine Adresse',
		pleaseWriteLicense: 'Bitte schreiben Sie eine Lizenz',
		pleaseSelectDegree: 'Bitte wählen Sie einen Abschluss',
		pleaseWriteStartDate: 'Bitte geben Sie das Startdatum an',
		startDateCannotBeHigher: 'Das Startdatum darf nicht höher als heute sein.',
		startDateCannotBeThatLower: 'Startdatum darf nicht vor 120 Jahren liegen.',
		endDateCannotBeSameAsStartDate: 'Das Enddatum muss mindestens 1 Jahr nach dem Startdatum liegen.',
		pleaseWriteEndDate: 'Bitte geben Sie das Enddatum ein',
		pleaseWriteCompany: 'Bitte schreiben Sie den Firmennamen',
		pleaseWritePosition: 'Bitte Position schreiben',
		clear: 'Löschen',
		noDiagnosesFound: 'Keine Diagnosen gefunden',
		noPrescriptionsFound: 'Keine Rezepte gefunden',
		noNotesFound: 'Keine Notizen gefunden',
		noProcedureFound: 'Kein Verfahren gefunden',
		setupAvailability: 'Setup-Verfügbarkeit',
		noVisitsFound: 'Keine Besuche gefunden',
		connectionType: 'Verbindungstyp',
		physicalExercises: 'Sportübungen',
		physicalTherapy: 'Physiotherapie',
		medicalInfoPhysicalTherapy: 'Physiotherapie',
		physicalTherapist: 'Physiotherapeut',
		startingDate: 'Anfangsdatum',
		targetedDays: 'Gezielte Tage',
		pTPrescriptionReport: 'PT-Verschreibungsbericht',
		dataEditedSuccessfully: 'Daten wurden erfolgreich bearbeitet',
		exercise: 'Übung',
		exerciseDate: 'Ausübungstag',
		editExercise: 'Übung bearbeiten',
		noPhysicalExercisesFound: 'Keine Physiotherapien gefunden',
		treeView: 'Tree View',
		listView: 'Listenansicht',
		currentlyLoggedInAs: 'Sie sind derzeit angemeldet als',
		logOutHere: 'Hier abmelden',
		pleaseLogOutFromThisAccount:
			'Bitte melden Sie sich von diesem Konto ab und gehen Sie zurück zu Ihrer E-Mail-Adresse, um diese Einladung anzunehmen.',
		thisDeviceIsOnACall: 'Dieses Gerät ist in einem Gespräch.',
		onePatient: 'Patient',
		multiplePatients: 'Patienten',
		noOptions: 'Keine Optionen',
		anotherProcedure: 'Verfahren',
		addExistingPatient: 'Vorhandenen Patienten hinzufügen',
		locationAndAddress: 'Standort-Adresse',
		signatureAndStamp: 'Unterschrift&Stempel',
		employment: 'Beschäftigung',
		education: 'Ausbildung',
		signatureDescription:
			'Wenn Sie Ihre Änderungen speichern, wird alles, was Sie in die Leinwand schreiben, zu Ihrer Unterschrift.',
		stampDescription: 'Es wird empfohlen, dass der Stempel transparent und im PNG-Format ist.',
		visceralFatGrade: 'Grad des viszeralen Fetts',
		daysNo: 'Tage',
		emailExists: 'Email existiert',
		gridView: 'Rasteransicht',
		singleView: 'Einzelansicht',
		unknown: 'Unbekannt',
		measuredAt: 'Gemessen an',
		patientName: 'Patientenname',
		patientMRN: 'Patienten-MRN',
		of: 'von',
		firstNameNonNumber: 'Der Vorname sollte nur Zeichen enthalten.',
		lastNameNonNumber: 'Der Nachname sollte nur Zeichen enthalten.',
		parentNameNonNumber: 'Übergeordneter Name sollte nur Zeichen enthalten.',
		cityNameNonNumber: 'Der Name der Stadt sollte nur Zeichen enthalten.',
		dateOfBirthNotValid: 'Geburtsdatum ist ungültig',
		minHeightIs: 'Mindestgröße ist',
		maxHeightIs: 'Maximale Größe ist',
		minWeightIs: 'Mindestgewicht ist',
		maxWeightIs: 'Maximales Gewicht ist',
		minTotalCholesterolIs: 'Min. Gesamtcholesterin ist',
		maxTotalCholesterolIs: 'Max. Gesamtcholesterin ist',
		minHdlCholesterolIs: 'Min. HDL-Cholesterin ist',
		maxHdlCholesterolIs: 'Max HDL-Cholesterin ist',
		minWaistCircumferenceIs: 'Minimaler Taillenumfang ist',
		maxWaistCircumferenceIs: 'Maximaler Taillenumfang ist',
		dateFormat: 'Datumsformat',
		timeFormat: 'Zeitformat',
		companyRegion: 'Unternehmensregion',
		companyOwner: 'Firmeninhaber',
		invite: 'Einladen',
		defaultMeasurementUnits: 'Standard-Maßeinheiten',
		userCanChangeMeasurement:
			'Die ausgewählten Einheiten sind Standard für jeden Benutzer, der in Ihrem Unternehmen registriert ist, aber jeder Benutzer kann sie nach seinen Vorlieben ändern (nur für sich selbst).',
		walkingRunningDistanceRequired: 'Geh- und Laufstrecke sind erforderlich',
		weightRequired: 'Gewicht ist erforderlich',
		bodyTemperatureRequired: 'Temperatur ist erforderlich',
		heightRequired: 'Größe ist erforderlich',
		bloodGlucoseRequired: 'Blutzucker ist erforderlich',
		dateFormatRequired: 'Datumsformat ist erforderlich',
		timeFormatRequired: 'Zeitformat ist erforderlich',
		cameraNameRequired: 'Kameraname erforderlich',
		enterCameraName: 'Geben Sie hier den Kameranamen ein',
		uniqueEmail: 'E-Mails sollten eindeutig sein',
		companies: 'Firmen',
		verificationEmailFollowInstructions: 'Sie haben eine Bestätigungs-E-Mail erhalten, bitte folgen Sie den Anweisungen.',
		success: 'Erfolg',
		requiredCompanyName: 'Firmenname ist erforderlich',
		requiredWebsiteName: 'Website-URL ist erforderlich',
		milesUnit: 'mi (Meile)',
		kmUnit: 'km (Kilometer)',
		lbsUnit: 'lbs (Pfund)',
		kgUnit: 'kg (Kilogramm)',
		fahrenheitUnit: '°F (Fahrenheit)',
		celsiusUnit: '°C (Celsius)',
		ftUnit: 'ft (Fuß)',
		cmUnit: 'cm (Zentimeter)',
		mgUnit: 'mg/dL (Milligramm pro Deziliter)',
		mmolUnit: 'mmol/L (Millimol pro Liter)',
		hourFormat12: '12 Stunden',
		hourFormat24: '24 Stunden',
		continueAs: 'Weiter als',
		rememberAsDefaultChoice: 'Merken Sie sich dies als meine Standardauswahl',
		myUserRoles: 'Meine Benutzerrollen',
		switch: 'Schalten',
		primarySpecialty: 'Primäre Spezialität',
		selectStates: 'Staat(e) auswählen',
		statesLicensed: 'Staaten lizenziert in',
		addARole: 'Rolle hinzufügen',
		resetTwoFa: 'Zwei-Faktor-Authentifizierung zurücksetzen',
		selectAuth: 'Zwei-Faktor-Authentifizierung auswählen',
		pleaseSelectRole: 'Bitte wählen Sie eine Rolle aus, die Sie diesem Mitglied hinzufügen möchten',
		switchRole: 'Rolle wechseln',
		providerOrPatient: 'Zu einem Anbieter- oder Patientenkonto wechseln',
		admin: 'Administrator',
		selectRole: 'Wähle eine Rolle',
		digitalClinician: 'Digitaler Kliniker',
		digitalclinician: 'Digitaler Kliniker',
		areYouSureDeleteRole: 'Möchten Sie diese Rolle wirklich von diesem Mitglied löschen?',
		deleteRole: 'Rolle löschen?',
		memberRole: 'Rolle',
		hrs: 'Std',
		physicalTherapyExercises: 'Physiotherapie-Übungen',
		addNewExercise: 'Füge eine neue Übung hinzu',
		selectTypeOfExercise: 'Übungstyp auswählen',
		exerciseSettings: 'Übungseinstellungen',
		addExerciseSettings: 'Übungseinstellungen hinzufügen',
		noAddedExercise: 'hat keine zusätzlichen Übungen!',
		selectCategory: 'Kategorie wählen',
		regulateExerciseIntensity: 'Regulieren Sie die Intensität der Übungen',
		selectDatesForExercise: 'Daten für Übung auswählen',
		containOnlyNumbers: 'Es sollte nur Zahlen enthalten',
		rangeExceeded: 'Zahl sollte zwischen {value1} und {value2} liegen',
		downloadReport: 'Bericht herunterladen',
		downloadPatientReport: 'Patientenbericht herunterladen',
		participantProfile: 'Teilnehmerprofil',
		bridge: 'Brücke',
		togglePatientsTv: 'TV-Patienten ausschalten',
		toggleGridView: 'Alternar vista de cuadrícula',
		toggleLayoutView: 'Layout-Ansicht umschalten',
		invitePeople: 'Menschen einladen',
		moreOptions: 'Mehr optionen',
		phoneNumberExists: 'Telefonnummer existiert bereits.',
		pleaseEnterValidNumber: 'Bitte geben Sie eine gültige Telefonnummer ein.',
		directLink: 'Direkte verbindung',
		sendLinkToPeople: 'Senden Sie diesen Link an die Personen, die Sie einladen möchten',
		addEmail: 'E-Mail hinzufügen',
		inviteViaEmail: 'Per E-Mail einladen',
		pleaseEnterToAddEmail: 'Drücken Sie die Eingabetaste, um eine E-Mail hinzuzufügen.',
		inviteViaSms: 'Per SMS einladen',
		inviteSms: 'Per SMS einladen',
		pressEnterToAddPhoneNumber: 'Drücken Sie die Eingabetaste, um die Telefonnummer hinzuzufügen.',
		joinViaPhone: 'Melden Sie sich per Telefon an',
		accessCode: 'Zugangscode hinzufügen',
		sendInvite: 'Senden sie einladung',
		addOneOrMorePplEmailSmsLink: 'Fügen Sie eine oder mehrere Personen per E-Mail, SMS, Link',
		pleaseEnterValidEmail: 'Bitte geben Sie eine gültige Email-Adresse ein.',
		emailAlreadyExists: 'Diese E-Mail existiert bereits.',
		conferenceLoading: 'Konferenz wird geladen',
		cameraIsOff: 'Kamera ist aus',
		joinNow: 'Jetzt beitreten',
		youAreLoggedInAs: 'Sie sind angemeldet als',
		youAreAboutToParticipate: 'Sie nehmen an einem Telemedizin-Termin teil.',
		pleaseIntroduceYourself: 'Stellen Sie sich bitte vor',
		yourName: 'Ihr namen',
		askToJoin: 'Bitten sie um teilnahme',
		conferenceNotActive: 'Konferenz ist nicht aktiv!',
		joiningConference: 'Konferenz beitreten',
		firstNameRequired: 'Vorname ist erforderlich',
		lastNameRequired: 'Der Nachname ist erforderlich',
		seeingOrHearingPatient:
			'Sie sehen oder hören den Patienten nicht, weil die physische {value3} Datenschutz-Taste auf {value} aktiviert wurde. Diese Taste kann nur manuell deaktiviert werden. Bitte kontaktieren Sie Ihren Administrator. {value4}',
		alsoTvIsMuted:
			'Außerdem ist der Fernseher stummgeschaltet oder ausgeschaltet. Bitte stellen Sie sicher, dass {value} Sie hören kann.',
		tvIsMuted: 'Der Fernseher ist stummgeschaltet oder ausgeschaltet. Bitte stellen Sie sicher, dass {value} Sie hören kann.',
		thePatient: 'Patient',
		theBaby: 'Baby',
		connectingToHello: 'Verbindung zu {value} herstellen',
		loadingCamera: 'Kamera wird geladen',
		cameraAndMicBlocked: 'Ihre Kamera und Ihr Mikrofon sind blockiert',
		micBlocked: 'Ihr Mikrofon ist blockiert',
		cameraBlocked: 'Ihre Kamera ist blockiert',
		clickMicOrCamera: 'Klicken Sie oben rechts auf Ihrem Bildschirm auf das blockierte Symbol {value}',
		camera: 'Kamera',
		microphone: 'Mikrofon',
		maximizeFeed: 'Wechseln Sie in den Vollbildmodus',
		minimizeFeed: 'Vollbildmodus beenden',
		allowAccessRefreshPage: 'Zugriff erlauben und Seite aktualisieren',
		youHaveLeftMeeting: 'Sie haben die Sitzung verlassen',
		meetingEndedFamilyMember:
			'Die Sitzung wurde vom Krankenhaus beendet. Datenschutzschaltflächen sind auf {value} aktiviert. Sie können das Baby sehen, wenn die Datenschutzschaltflächen deaktiviert sind.',
		babyOutsideVisitingHoursMessage: 'Sie können Ihr Baby außerhalb der Besuchszeiten nicht sehen.',
		unSupportedTV: 'Nicht unterstützter Fernseher',
		commandIsNotSupportedPatientTV: 'Dieser Befehl wird auf dem Fernseher dieses Patienten nicht unterstützt.',
		areUsureUwantToLeaveSession: '„Sind Sie sicher, dass Sie die Sitzung verlassen möchten?“',
		copy: 'Kopieren',
		copied: 'Kopiert',
		failedToCopyLink: 'Link konnte nicht kopiert werden!',
		copySessionId: 'Kopieren Sie die Sitzungs-ID zur Fehlerbehebung',
		copiedSessionId: 'Sitzungs-ID kopiert!',
		thereIsActiveConference: 'Es gibt eine bereits aktive Konferenz',
		callTransferredToAnotherClient: 'Anruf an einen anderen Klient weitergeleitet.',
		callAnsweredElsewhere: 'Anruf auf einem anderen Gerät angenommen.',
		rejoin: 'Wieder beitreten',
		submitPhysicalTherapy: 'Physiotherapie einreichen',
		universalSafetyInterventions: 'Universelle Sicherheitsmaßnahmen',
		fallRisk: 'Sturzrisiko',
		dailyWeight: 'Tägliches Gewicht',
		fallHarmRisk: 'Sturz mit Schadensrisiko',
		silentHeartAttack: 'Stiller Herzinfarkt',
		addSuperAdmin: 'Super-Admin hinzufügen',
		privacyOn: 'Datenschutz ein',
		privacyOff: 'Privatsphäre aus',
		settings: 'Einstellungen',
		safeRanges: 'Sichere Bereiche',
		pressureInjury: 'Druckverletzungen',
		getOutOfBed: 'vom Bett Aufstehen',
		screenMode: 'Bildschirmmodus',
		speakerAudioVolume: 'Lautsprecherlautstärke',
		displayOrientationExtended: 'Displayausrichtung erweitert',
		roundingDefaultCamera: 'Rundung der Standardkamera',
		monitoringDefaultCamera: 'Überwachung der Standardkamera',
		faceDown: 'Gesicht nach unten',
		rails: 'Bett',
		left: 'Links',
		right: 'Rechts',
		top: 'Oben',
		bottom: 'Unten',
		skeletonMode: 'Skelettmodus',
		physicalTherapies: 'Physikalische Therapien',
		addExercise: 'Übung hinzufügen',
		seconds: 'Sekunden',
		hour: 'Stunden',
		noRooms: 'Momentan keine Zimmer.',
		callType: 'Anrufart',
		details: 'Einzelheiten',
		exportCSV: 'CSV-Protokolle exportieren',
		exportToCSV: 'In CSV exportieren',
		cannotBeNDaysDifference: 'Der Datumsunterschied darf nicht mehr als {value} Tage betragen',
		reportSentSuccessfully: 'Bericht wurde erfolgreich gesendet.',
		toAccessCheckEmail: 'Um auf den Bericht zuzugreifen und ihn herunterzuladen, fahren Sie bitte mit Ihrer E-Mail-Adresse fort.',
		reportIsUnderway: 'Bericht wird erstellt.',
		reportUnderwayDescription: 'Ihr Bericht wird erstellt und dies kann je nach Datenmenge eine Weile dauern.',
		thankYouForPatience: 'Danke für Ihre Geduld!',
		listOfRooms: 'Zimmerliste',
		exportCSVInBackground: 'CSV im Hintergrund exportieren',
		talkToPatient: 'Sprechen Sie mit dem Patienten',
		patientView: 'Patientenansicht',
		ongoing: 'Laufend',
		past: 'Vorbei an',
		screenUsed: 'Bildschirm verwendet',
		callDetails: 'Anrufdetails',
		endCallForRoom: 'Anruf für Zimmer beenden',
		retry: 'Wiederholen',
		callDurationSoFar: 'Gesprächsdauer (bisher)',
		noOngoingConferences: 'Derzeit keine laufenden Konferenzen.',
		refresh: 'Aktualisierung',
		areYouSureToTerminate: 'Sind Sie sicher zu kündigen',
		terminateRequestTimeOut: 'Anforderungs-Timeout beenden',
		retryRequest: 'Möchten Sie es erneut versuchen? Dadurch wird das Gespräch zwischen dem Arzt und dem Patienten beendet.',
		sessionTerminatedSuccessfully: 'Die Sitzung wurde erfolgreich beendet.',
		terminateRoomSession:
			'Möchten Sie diese Raumsitzung wirklich beenden? Dadurch wird das Gespräch zwischen Arzt und Patient beendet.',
		roomSessionTerminated: 'Raumsitzung wurde erfolgreich beendet.',
		sessionAlreadyEnded: 'Diese Sitzung wurde bereits beendet.',
		terminationInitiated: 'Die Beendigung dieser Sitzung wird von einem anderen Administrator initiiert.',
		noPermissionToTerminate: 'Sie haben keine Berechtigung, diese Sitzung zu beenden.',
		assignedDevices: 'Zugewiesene Geräte',
		assignedDevice: 'Zugewiesenes Gerät',
		availabilitySetup: 'Verfügbarkeit',
		data: 'Daten',
		participants: 'Teilnehmer',
		noCategory: 'Keine Kategorie',
		required: 'Erforderlich',
		fillRequiredFields: 'Füllen Sie die erforderlichen Felder aus',
		ai: 'AI',
		noMoreThanOneAiType: 'Sie können nicht mehr als eine AI-Einstellung gleichzeitig aktivieren.',
		maxBirthdayLimit: 'Sie dürfen höchstens 120 Jahre alt sein',
		year: 'Jahr',
		video: 'Video',
		file: 'Datei',
		invalidLink: 'Ungültiger Link. Bitte versuchen Sie es erneut!',
		measureCompleted: 'Messung abgeschlossen',
		couldNotGetPatientData: 'Patientendaten konnten nicht abgerufen werden',
		tryAgainWithoutMoving: 'Versuchen Sie es erneut, ohne sich zu bewegen',
		pressurizationSlow: 'Druckaufbau langsam! Bitte überprüfen Sie die Manschette!',
		wait5minutes: 'Warten Sie 5 Minuten und versuchen Sie es erneut',
		doToRetakeMeasure: 'Sie können {value} die Messung wiederholen!',
		toRemeasureTemperature: 'Um die Messung zu wiederholen, drücken Sie die Messtaste des Thermometers.',
		doToReconnect: 'Sie können {value}, um die Verbindung wiederherzustellen!',
		measureHasFinished: '{value} hat die Messung beendet',
		deviceIsConnected: '{value} – Gerät ist verbunden',
		deviceIsConnecting: '{value} – Gerät verbindet sich',
		deviceDisconnected: '{value} – Gerät reagiert nicht die Verbindung wurde getrennt',
		pleaseWaitConnection: 'Bitte warten Sie, bis die Verbindung hergestellt ist',
		pleaseStandOnScale: 'Bitte stellen Sie sich auf die Waage, um mit der Messung zu beginnen.',
		valueIsDisconnected: '{value} ist getrennt',
		valueIsConnecting: '{value} ist Verbindung',
		valueIsConnected: '{value} ist verbunden',
		deviceCouldNotConnect: '{value} – Es konnte keine Verbindung zum Gerät hergestellt werden. Überprüfen Sie Bluetooth',
		deviceMeasuring: '{value} - Gerät misst',
		deviceConnectFail: '{value} – Geräteverbindung fehlgeschlagen',
		deviceMeasureStopped: '{value} - Messung gestoppt',
		deviceMeasureCompleted: '{value} - Maßnahme abgeschlossen',
		deviceReconnecting: '{value} – Gerät verbindet sich neu',
		problemWithReader: 'Problem mit dem Lesegerät. Machen Sie einen neuen Test mit einem neuen Teststreifen!',
		testStripRemoved: 'Der Teststreifen wurde während der Messung entfernt.',
		problemStrip:
			'Problem mit dem Teststreifen (nasser Streifen, zu schnell entfernt, zu viel Blut auf den Streifen aufgetragen). Machen Sie einen neuen Test mit einem neuen Teststreifen!',
		lowRoomTemperature: 'Die Raumtemperatur ist zu niedrig. Optimale Temperatur ist 10°C - 40°C / 50°F - 104°F',
		highRoomTemperature: 'Die Raumtemperatur ist zu hoch. Optimale Temperatur ist 10°C - 40°C / 50°F - 104°F',
		communicationError:
			'{value} – Kommunikationsfehler. Halten Sie die Taste M (Speicher) 7 Sekunden lang gedrückt, um das Gerät wiederherzustellen.',
		newTestNewStrip: 'Machen Sie einen neuen Test mit einem neuen Teststreifen!',
		getBlood: 'Hol Blut!',
		measureStarted: 'Maßnahme gestartet',
		stripIn: 'Das Band wurde eingestellt',
		stripOut: 'Entfernen Sie das Klebeband',
		totalCholesterol: 'Gesamtcholesterin',
		hdlCholesterol: 'HDL-Cholesterin',
		waistCircumference: 'Taillenumfang',
		healthInformation: 'Gesundheitsinformationen',
		currentEverydaySmoker: 'Aktueller Alltagsraucher',
		currentSomedaySmoker: 'Heutzutage Raucher',
		formerSmoker: 'Ehemaliger Raucher',
		neverSmoker: 'Nie Raucher',
		sufferFromHypertension: 'Leiden Sie unter Bluthochdruck?',
		medicalStatus: 'Haben Sie einen Medikamentenstatus?',
		areYouSmoker: 'Bist du Raucher?',
		haveDiabeticStatus: 'Haben Sie einen Diabetes-Status?',
		patientIsBeingRegistered: 'Patient wird registriert',
		patientAlreadyExists: 'Patient existiert bereits',
		pleaseSelectOneOfTheOptionsBelow: 'Bitte wählen Sie eine der folgenden Optionen aus',
		diabetesMellitusI: 'Diabetes mellitus Typ I',
		diabetesMellitusII: 'Diabetes mellitus Typ II',
		submit: 'Einreichen',
		dateTimeFormats: 'Formate für Datum und Uhrzeit',
		seeingAndHearing: 'sehen und hören',
		seeing: 'Sehen',
		hearing: 'Hören',
		patientRequestedHelp: 'Patient bat um Hilfe',
		patientSitting: 'Der Patient wurde Inaktivität in einer Stuhlposition festgestellt',
		patientStanding: 'Der Patient wurde Inaktivität im Stehen festgestellt',
		patientLyingSide: 'Der Patient wurde Inaktivität in Seitenlage festgestellt',
		patientLyingRightSide: 'Patient wurde in rechter Seitenlage als inaktiv erkannt',
		patientLyingLeftSide: 'Patient wurde in linker Seitenlage als inaktiv erkannt',
		patientLyingFront: 'Der Patient wurde Inaktivität in liegender Rückenlage festgestellt',
		patientFaceDown: 'Der Patient war in Bauchlage inaktiv.',
		patientInactive: 'Der Patient wurde als inaktiv erkannt.',
		closed: 'geschlossen',
		or: 'oder',
		history: 'Geschichte',
		availableDoctors: 'Verfügbare Ärzte',
		alreadySelected: 'Bereits ausgewählt',
		alreadyInPool: 'Bereits im Pool',
		cantAddMoreDoctors: 'Es können nicht mehr als 5 Ärzte gleichzeitig hinzugefügt werden!',
		cam: 'Kamera',
		mic: 'Mikrofon',
		camAndMic: 'kamera und Mikrofon',
		hellocareMode: 'Hellocare-Modus',
		aiWidgetMode: 'AI-Widget-Modus',
		telemedicineMode: 'Telemedizin-Modus',
		hospitalMode: 'Krankenhausmodus',
		signOut: 'Abmelden',
		primaryHealthcare: 'Medizinische Grundversorgung',
		healthSystemType: 'Art des Gesundheitssystems',
		selectHealthSystemType: 'Gesundheitssystemtyp auswählen',
		patientGettingOutOfBed: 'Der Patient steigt aus dem Bett',
		clickToLoginAnotherAcc: 'Klicken Sie hier, um sich mit einem anderen Konto anzumelden.',
		notAssignedHs: 'Sie sind derzeit keinem Gesundheitssystem zugeordnet',
		memberNoHs: `Dieses Mitglied ist derzeit keinem Gesundheitssystem zugewiesen`,
		digitalClinicMode: 'Digitaler Klinikmodus',
		impulsiveConfused: 'Impulsiv/Verwirrt',
		pullingO2: 'O2 ziehen',
		nonRedirectable: 'Nicht umleitbar',
		precautions: 'Vorsichtsmaßnahmen',
		precautionsDescription:
			'Wenn dies aktiviert ist, kann {roleNameNurse} Vorsichtsmaßnahmen wie Sturzrisiko für den Patienten festlegen, damit sie Patienten basierend auf der Liste der Vorsichtsmaßnahmen leicht visualisieren und sortieren können. Darüber hinaus verwenden Sie die Dropdown-Optionen für jedes Feld, um die Datenquelle (EHR oder Hellocare) zu konfigurieren.',
		terminateSession: 'Sitzung beenden?',
		terminateRoomCall: 'Raumsitzung beenden?',
		selectRoom: 'Zimmer auswählen',
		selectLocationReassin:
			'Wählen Sie oben einen Standort aus dem Gesundheitssystem aus, um die Krankenhäuser darauf zu verwalten.',
		selectLocation: 'Ort auswählen',
		selectHsToManage: 'Wählen Sie das Gesundheitssystem aus, das Sie verwalten möchten.',
		reAssignDevice: 'Gerät neu zuweisen',
		regionSaved: 'Region gespeichert',
		somethingWrong: 'Etwas ist schief gelaufen!',
		selectLocationReassign:
			'Wählen Sie oben einen Standort aus dem Gesundheitssystem aus, um die Krankenhäuser darin zu verwalten.',
		hi: 'Hallo',
		selectOptionsBelow: 'Optionen unten auswählen',
		willBeReceivingCalls: 'Ich werde Anrufe von Patienten erhalten',
		willNotBeReceivingCalls: 'Ich werde keine Anrufe von Patienten erhalten',
		selectHealthSystems: 'Gesundheitssysteme auswählen',
		selectReceivingCalls: 'Wählen Sie aus, ob Sie Anrufe von Patienten erhalten.',
		selectHealthSystemsReceiveCalls: 'Wählen Sie die Gesundheitssysteme aus, von denen Sie Anrufe erhalten werden.',
		selectLevelReceiveCalls: 'Wählen Sie die Ebene aus, von der Sie Anrufe erhalten.',
		neckExercises: 'Nackenübungen',
		legExercises: 'Beinübungen',
		shoulderExercises: 'Schulterübungen',
		hipExercises: 'Hüftübungen',
		extendedLegExercise: 'Erweitertes Beintraining',
		singleLegGluteKicksExercise: 'Einbeinige Glute Kicks-Übung',
		standingMarchExercise: 'Stehende Marschübung',
		hamstringStretchExercise: 'Kniesehnen-Dehnungsübung',
		standingQuadStretchExercise: 'Stehende Quad-Stretch-Übung',
		neckExercise: 'Nackenübung',
		neckStretchExercise: 'Nackendehnungsübung',
		liftingHeadSidewaysExercise: 'Übung zum seitlichen Heben des Kopfes',
		shoulderExercise: 'Schulterübung',
		armRaisesExercise: 'Armheben-Übung',
		rotatorCuffExercise: 'Rotatorenmanschetten-Übung',
		shoulderExtensionExercise: 'Schulterstreckungsübung',
		seatedShoulderRaiseExercise: 'Sitzende Schulterheben-Übung',
		hipExercise: 'Hüftübung',
		hipMarchingExercise: 'Hüftmarschübung',
		sideLyingLegLiftExercise: 'Seitlich liegende Beinhebeübung',
		fireHydrantExercise: 'Feuerhydranten-Übung',
		maximumRangeOfMotion: 'Maximale Bewegungsfreiheit',
		leftSideNumberOfRepetition: 'Anzahl der Wiederholungen auf der linken Seite',
		rightSideNumberOfRepetition: 'Anzahl der Wiederholungen auf der rechten Seite',
		numberOfDaysToAchieveTheTarget: 'Anzahl der Tage, um das Ziel zu erreichen',
		degreeIncrementInTargetROM: 'Graderhöhung im Ziel-ROM',
		holdingTimeInSeconds: 'Haltezeit in Sekunden',
		numberOfRepetition: 'Anzahl der Wiederholungen',
		numberOfRepetitionForRightNeck: 'Anzahl der Wiederholungen auf der rechten Seite',
		addPhysicalTherapy: 'Fügen Sie eine Physiotherapie hinzu',
		remotePairedWithDevice: '„Mit diesem Gerät ist eine Fernbedienung gekoppelt. Möchten Sie es entkoppeln?',
		remoteNotPairedWithDevice:
			'„Dieses Gerät hat keine gekoppelten Fernbedienungen. Wenn Sie eine Fernbedienung koppeln möchten, halten Sie die OK- und Zurück-Taste gedrückt, bis Sie ein rotes pulsierendes Licht auf der Fernbedienung sehen, und klicken Sie dann auf Im Internet koppeln.',
		remotePairedSuccessfully: 'Fernbedienung erfolgreich gekoppelt.',
		remoteUnpairedSuccessfully: 'Remote erfolgreich entkoppelt.',
		pairingTimeout: 'Kopplungszeit abgelaufen. Bitte versuchen Sie es erneut.',
		pair: 'Paar',
		unpair: 'Entkoppeln',
		dismiss: 'Zurückweisen',
		tryingToPairRemote: 'Versuch, die Fernbedienung zu koppeln...',
		tryingToUnpairRemote: 'Versuch, die Fernbedienung zu entkoppeln...',
		remoteControl: 'Fernbedienung',
		patientStandingWalking: 'Gleichgewichtsverlust festgestellt',
		personIsDetectedWalking: 'Gleichgewichtsverlust festgestellt. Bitte überprüfen Sie den Patienten!',
		analyticPositionDescription: 'Der Patient wurde {value} für {value1} erkannt',
		lyingFront: 'vorne liegend',
		lyingSide: 'liegeseite',
		lyingDown: 'hinlegen',
		sitting: 'sitzung',
		walking: 'gehen',
		inviteUsers: 'Nutzer einladen',
		cannotEnableDataAcquisition:
			'Sie können die Datenerfassung nicht aktivieren, da auf diesem Gerät derzeit die Privatsphäre aktiviert ist.',
		cannotEnablePrivacyMode:
			'Sie können den Datenschutz nicht aktivieren, da auf diesem Gerät derzeit die Datenerfassung aktiviert ist.',
		careEventsForNurses: 'Pflegeereignisse für {roleNameNurse}',
		roundingCareEventsForNurses: 'Pflegeereignisse für {roleNameNurse}',
		careEventsForNursesDescription:
			'Patientenpflegeereignisse wie Aufnahme, Beratung, Behandlung und Entlassung, die von {roleNameNurse} durchgeführt werden, werden registriert.',
		careEventsForSafetyCompanions: 'Interventionen und Warnmeldungen für Sicherheitsbegleiter und {roleNameNurse}n',
		careEvents: 'Pflegeveranstaltungen',
		careEvent: 'Pflegeveranstaltung',
		recordings: 'Aufnahmen',
		savedCareEvent: 'Gespeichertes Pflegeereignis',
		eventDetails: 'Veranstaltungsdetails',
		homeMedListCompleted: 'HomeMed-Liste abgeschlossen',
		admissionDataCompleted: 'Zulassungsdatum abgeschlossen',
		safetyCompanions: 'Sicherheitsbegleiterinnen',
		companionModeName: 'Begleitmodus',
		frontlineNurses: 'Pflegekräfte an vorderster Front',
		ipoCompleted: 'IPOC abgeschlossen',
		dischargeInstructionsCreated: 'Entlassungsanweisungen erstellt',
		dischargeTeachingCompleted: 'Entlastungslehre abgeschlossen',
		performed2ndRNMedVerification: 'Zweite RNMed-Verifizierung durchgeführt',
		notifiedMD: 'Benachrichtigter MD',
		performedMentorship: 'Durchgeführte Mentorenschaft',
		performedSepsisScreenNotification: 'Durchgeführte Sepsis-Bildschirmbenachrichtigung',
		completed24HourChartCheck: 'Abgeschlossener 24-Stunden-Chart-Check',
		performedClinicalRounding: 'Durchgeführtes klinische Zyklus',
		providedUpdateToFamily: ' Aktualisierung für Familie bereitgestellt',
		assistedWithCodeBlue: 'Mit Code Blue unterstützt',
		redirectedPatientToPreventFall: 'Umgeleiteter Patient, um Sturz zu verhindern',
		notifiedBedsideStaffToAssistPatientToPreventFall:
			'Notifiziertes Personal am Krankenbett, um den Patienten bei der Verhinderung von Stürzen zu unterstützen.',
		notifiedBedsideStaffToAssistPatient: 'Notifiziertes Personal am Krankenbett, um den Patienten zu unterstützen',
		calledRapidResponse: 'Genannt Rapid Response',
		preventedHarmToStaffMember: 'Verhinderter Schaden für Mitarbeiter',
		typeOfCareEvent: 'Ein Pflegeereignis hinzufügen',
		stopRecording: 'Höre auf, aufzunehmen',
		record: 'Aufzeichnung',
		startRecording: 'Starte die Aufnahme',
		videoRecordingSaving: 'Video-Feed-Aufzeichnung wird gespeichert',
		chooseValidDate: 'Enddatum darf nicht in der Zukunft liegen',
		patientHistory: 'Patientenanamnese',
		additionalHealthDataSymptoms: 'Zusätzliche Gesundheitsdaten und Symptome',
		patientRegistration: 'Patientenregistrierung',
		parentName: 'Elternname',
		patientHealthCardNumber: 'Patienten-Gesundheitskartennummer',
		maritalStatus: 'Familienstand',
		single: 'Single',
		married: 'Verheiratet',
		divorced: 'geschieden',
		widowWidower: 'Witwe/Witwer',
		registerNewPatient: 'Neuen Patienten registrieren',
		getPatientData: 'Patientendaten abrufen',
		menuSettings: 'Menüeinstellungen',
		editMenuSettings: 'Menüeinstellungen bearbeiten',
		alreadyWaitingForRequest: 'Sie warten bereits auf eine Anfrage',
		whatReasonOfVisit: 'Was ist der Grund für Ihren heutigen Besuch?',
		reasonOfVisit: 'Grund des Besuchs',
		cannotMeasureVS:
			'Vitalfunktionen können nicht über das Internet gemessen werden, wenn Sie nicht mit dem Arzt telefonieren. Rufen Sie die hellocare-App auf dem Handy auf oder laden Sie sie herunter, um Vitalfunktionen zu messen.',
		takeBreak: 'Machen Sie eine Pause',
		resumeWorking: 'Arbeit fortsetzen',
		symptomsDuration: 'Symptome Dauer',
		frontDeskCheckIn: 'Check-in an der Rezeption',
		startVisit: 'Besuch starten',
		minutesWaitingRoom: 'Minuten im Wartezimmer',
		markVisitAsCompleted: 'Besuch als abgeschlossen markieren',
		inPerson: 'Persönlich',
		virtualCall: 'Virtueller Anruf',
		instructionsForConsultation: 'Hinweise zur Beratung',
		instructionsForDiagnosticResearch: 'Anweisungen für die diagnostische Forschung',
		healthInstitutionWherePatientInstructed: 'Gesundheitseinrichtung, in der der Patient verweisen wurde',
		consultant: 'Berater',
		purposeOfInstruction: 'Zweck der Anweisung',
		medicalHistory: 'Anamnese',
		examinations: 'Prüfungen',
		icd9Code: 'ICD-9-Code',
		currentTreatment: 'Aktuelle Behandlung',
		codeMedicines: 'Code-Medikamente',
		workingDiagnosis: 'Arbeitsdiagnose',
		icd10Code: 'ICD-10-Code',
		organizationalUnit: 'Organisationseinheit',
		organizationalUnitCode: 'Code der Organisationseinheit',
		addressOfInstitution: 'Anschrift der Einrichtung“',
		procedureCode: 'Verfahrenscode',
		icpc: 'ICPC',
		addForms: 'Formulare hinzufügen',
		visitDetails: 'Besuchsdetails',
		forms: 'Formen',
		numberOfVisits: 'Anzahl der Besuche',
		lastVisit: 'Letzter Besuch',
		showVisitHistory: 'Besuchsverlauf anzeigen',
		remove: 'Entfernen',
		nextVisit: 'Nächster Besuch',
		waitingList: 'Warteliste',
		nextTest: 'Nächster Test',
		startTesting: 'Test starten',
		instructions: 'Anweisungen',
		test: 'Testen',
		sample: 'Probe',
		enterResults: 'Ergebnisse eingeben',
		submitResults: 'Ergebnisse einreichen',
		checkInPatients: 'Patienten einchecken',
		doYouHaveAllergies: 'Hast du irgendwelche Allergien?',
		waitingResults: 'Warten auf Ergebnisse',
		healthInstitution: 'Gesundheitseinrichtung',
		codeHealthInstitution: 'Kodex der Gesundheitseinrichtung“',
		addedForms: 'Formulare hinzugefügt',
		addAnotherTest: 'Einen weiteren Test hinzufügen',
		doesPatientHaveAllergies: 'Hat der Patient irgendwelche Allergien?',
		noResponseFromPatient: 'Keine Reaktion des Patienten',
		whatTypeOfAllergies: 'Welche Art von Allergien hat der Patient?',
		food: 'Essen',
		environmental: 'Umwelt',
		biological: 'biologisch',
		foodAllergies: 'Lebensmittelsallergien',
		medicationAllergies: 'Arzneimittelallergien',
		addAllergies: 'Allergien hinzufügen',
		registerPatient: 'Patient registrieren',
		helloIsDisconnected: '{value} ist getrennt!',
		connectionCannotEstablish:
			'Die Verbindung zu {value} konnte nicht hergestellt werden. Bitte überprüfen Sie die Internetverbindung auf dem Gerät und versuchen Sie es erneut.',
		cannotChangeWorkflow: 'Arbeitsablauf kann nicht geändert werden',
		noCompletedAppointments: 'Sie haben keine abgeschlossenen Anfragen!',
		cholesterol: 'Cholesterin',
		totalCholesterolTooltip:
			'Ihr Gesamtcholesterin im Blut ist ein Maß für LDL-Cholesterin, HDL-Cholesterin und andere Lipidkomponenten. Ihr Arzt wird Ihren Gesamtcholesterinwert verwenden, um Ihr Risiko für Herzerkrankungen zu bestimmen und wie Sie am besten damit umgehen.',
		hdlCholesterolToolip:
			'HDL (Lipoprotein hoher Dichte) oder „gutes“ Cholesterin absorbiert Cholesterin und transportiert es zurück zur Leber. Die Leber spült es dann aus dem Körper. Ein hoher HDL-Cholesterinspiegel kann das Risiko für Herzerkrankungen und Schlaganfälle senken.',
		waistCircumferenceTooltip:
			'„Eine weitere Möglichkeit, das potenzielle Krankheitsrisiko einzuschätzen, ist die Messung des Taillenumfangs. Überschüssiges Bauchfett kann schwerwiegend sein, da es Sie einem größeren Risiko aussetzt, mit Fettleibigkeit zusammenhängende Erkrankungen wie Typ-2-Diabetes, Bluthochdruck und koronare Herzkrankheiten zu entwickeln. Ihre Taille kann Ihnen also sagen, dass Sie ein höheres Risiko haben, mit Fettleibigkeit zusammenhängende Erkrankungen zu entwickeln.',
		meetingWithDoctor: 'Treffen mit Dr.',
		meetingWithPatient: 'Treffen mit dem Patienten',
		enterNoteHere: 'Notiz hier eingeben',
		accountIsDeactivated: 'Hallo {value}',
		helloUser: 'Hallo {value}',
		reachAdministrator: 'Anzahl von Beispielen',
		numberOfSamples: 'Anzahl der Tests',
		numberOfTests: 'Notfälle',
		emergencyCases: 'Notfallfall starten',
		startEmergencyCase: 'Warten auf Ergebnisse',
		waitingForResults: 'Patientenname oder Ausweisnummer suchen',
		searchPatientNameOrNumber: 'Ergebnisse hinzufügen',
		addResults: 'Schreiben Sie hier Notizen',
		network: 'Netzwerk',
		profileName: 'Profilname',
		cidr: 'CIDR',
		wiFiBand: 'WLAN-Band',
		minBandwidth: 'Mindestbandbreite in kbps',
		maxBandwidth: 'Maximale Bandbreite in kbps',
		addConfiguration: 'Konfiguration hinzufügen',
		searchByIPAddress: 'Suche nach IP-Adresse',
		createCareEvent: 'Pflegeereignis erstellen',
		bandwidth: 'Bandbreite',
		wifiFrequency: 'WLAN-Frequenz',
		minShouldBeLessThanMax: 'Das Minimum sollte kleiner als das Maximum sein.',
		minBandwidthRequired: 'Mindestbandbreite ist erforderlich.',
		maxBandwidthRequired: 'Maximale Bandbreite ist erforderlich.',
		minBandwidthLessThan: '``Mindestbandbreite darf nicht weniger als 500 sein.',
		maxBandwithMoreThan: '``Maximale Bandbreite darf nicht mehr als 5000 sein.',
		wiFiBandRequired: 'WLAN-Band ist erforderlich.',
		chooseUnit: 'Einheit wählen',
		applyConfig: 'Konfiguration anwenden.',
		selectHospital: 'Krankenhaus auswählen',
		selectHospitalApply: 'Krankenhaus auswählen, um Active Directory anzuwenden',
		selectFloor: 'Etage auswählen.',
		setMinBandwidth: 'Mindestbandbreite festlegen',
		setMaxBandwidth: 'Maximale Bandbreite festlegen',
		chooseWiFiBand: 'WLAN-Band auswählen',
		roleIsRequired: 'Rolle ist erforderlich.',
		invalidIpAddress: 'Ungültige IP-Adresse oder CIDR-Bereich.',
		webSiteAccess: 'Website-Zugriff',
		setIpAddress: 'IP-Adresse einstellen',
		maximumIpAddresses: 'Kann nicht mehr als {value} IP-Adressen auf einmal hinzufügen',
		provideCIDRRange:
			'Geben Sie einen Adressbereich in CIDR-Notation (z. B. 192.168.99.0/24) oder eine IP-Adresse (z. B. 192.168.99.0) an. Sie können auch eine Liste mit IP-Adressen oder Adressbereichen mit der Eingabetaste bereitstellen.',
		setIpAddressOrRange: 'IP-Adresse oder Bereich festlegen',
		deleteNetworkConfigConfirmation: 'Möchten Sie diese Netzwerkkonfiguration wirklich löschen?',
		deleteAdConfirmation: 'Möchten Sie dieses Active Directory wirklich löschen?',
		deleteGroupRoleConfirmation: 'Möchten Sie diese Active Directory-Gruppenrolle wirklich löschen? ',
		representsRangeOfIps: 'Repräsentiert einen Bereich von IPs',
		historyForConfig: 'Historie für Konfiguration',
		notAuthorizedFromThisIP: 'Sie sind nicht berechtigt, von der IP-Adresse aus auf diese Seite zuzugreifen',
		notAuthorizedToAccessThisPage: 'Sie sind nicht autorisiert auf diese Seite zuzugreifen.',
		ifYouThinkThereAreMistakes:
			'Wenn Sie der Meinung sind, dass ein Fehler aufgetreten ist, wenden Sie sich bitte an Ihren Administrator.',
		activeDirectory: 'Active Directory',
		addAd: 'AD hinzufügen',
		addGroupRoles: 'Gruppenrollen hinzufügen',
		groupRoles: 'Gruppenrollen',
		groupRolesDescription: 'Active Directory-Rollengruppe',
		groupRolesRequired: 'Gruppenrollen sind erforderlich',
		azureAdUrl: 'Azure AD-URL festlegen',
		setValidGroupName: 'Gültigen Gruppennamen festlegen',
		setValidGroupNameRequired: 'Ein gültiger Gruppenname ist erforderlich',
		setValidGroupNameDescription: 'Active Directory gültiger Gruppenname',
		clientId: 'Kunden ID',
		clientIdRequired: 'Client-ID ist erforderlich',
		clientIdDescription: '{value} Client-ID festlegen',
		clientSecret: 'Kundengeheimnis',
		clientSecretRequired: 'Client-Secret ist erforderlich',
		clientSecretDescription: 'Client-Geheimnis für {value} festlegen',
		radiology: 'Radiologie',
		numberOfRepetitionForLeftNeck: 'Anzahl der Wiederholungen für Hals',
		inviteToLaboratory: 'Ins Labor einladen',
		startTests: 'Tests starten',
		patientNoShowUp: 'Patient ist nicht erschienen',
		markSamplesAsTaken: 'Proben als genommen markieren',
		markProcessAsCompleted: 'Vorgang als abgeschlossen markieren',
		editPatient: 'Patient bearbeiten',
		searchPatientNameId: 'Sie können Patienten anhand ihres Namens oder ihrer ID-Nummer suchen',
		medicalForms: 'Medizinische Formulare',
		formsDisplayedHere: 'Die von Ihnen hinzugefügten Formulare werden hier angezeigt.',
		totalNumberOfSessions: 'Gesamtzahl der Sitzungen',
		completedVisits: 'Abgeschlossene Besuche',
		sessionDuration: 'Sitzungsdauer',
		notShownUp: 'Nicht aufgetaucht',
		ongoingVisits: 'Laufende Besuche',
		registryNumber: 'Registrierungsnummer',
		visitStart: 'Beginn des Besuchs',
		visitEnd: 'Ende des Besuchs',
		inviteToRoom: 'In Zimmer einladen',
		currentVisit: 'Aktueller Besuch',
		consultationReport: 'Konsultationsbericht',
		instructionsForInjection: 'Anweisungen zur Verabreichung einer parenteralen Therapie (Injektion)',
		instructionsForInfusion: 'Anweisungen zur Verabreichung einer parenteralen Therapie (Infusion)',
		laboratory: 'Labor',
		searchToAdd: 'Suchen, um {value} für {value2} hinzuzufügen',
		tests: 'Tests',
		xRayScans: 'Röntgenaufnahmen',
		medicationNameHere: 'Name des Medikaments hier',
		medicationType: 'Medikamententyp',
		addAnotherMedication: 'Fügen Sie ein weiteres Medikament hinzu',
		selectData: 'Datum auswählen',
		addOtherData: 'Weitere Daten hinzufügen',
		returnReportFromLab: 'Rückmeldebericht aus dem Labor',
		returnReportFromRadiology: 'Rückmeldebericht aus der Radiologie',
		addOtherNote: 'Füge eine weitere Notiz hinzu',
		addMedicalForms: 'Medizinische Formulare hinzufügen',
		searchPatientNameIdVisitNumber: 'Suche nach Patientenname, ID oder Registrierungsnummer des Besuchs',
		nextDay: 'Tag',
		frontDesk: 'Rezeption',
		injectionRoom: 'Injektionsraum',
		infusionRoom: 'Infusionsraum',
		capacity: 'Kapazität',
		writeCapacity: 'Schreiben Sie die Kapazität',
		writePriority: 'Schreiben Sie die Priorität',
		startBreak: 'Pause starten',
		endBreak: 'End der Pause',
		startSchedule: 'Zeitplan starten',
		endSchedule: 'Zeitplan beenden',
		visitHistoryFor: 'Besuchen Sie die Geschichte für',
		patientNoVisits: 'Der Patient hat keine Besuche',
		openCases: 'Offene Fälle',
		closedCases: 'Abgeschlossene Fälle',
		instruction: 'Anweisung',
		therapyDescription: 'Therapiebeschreibung',
		checkUp: 'Untersuchung',
		reCheckUp: 'Neuer Check-up',
		referral: 'Verweisung',
		referToOneOfTheDoctors: 'Überweisen Sie den Patienten an einen der Ärzte',
		patientQueue: 'Patientenwarteschlange',
		referToThisRoom: 'Patienten in dieses Zimmer verweisen',
		waitingTime: 'Wartezeit',
		referralDetails: 'Verweisdetails',
		queueNumber: 'Warteschlangennummer',
		patientAddedDoctorWaitingList: 'Patient wurde zur Warteliste des Arztes hinzugefügt',
		itinerary: 'Route',
		additionalNote: 'Zusätzliche Anmerkung',
		visitIn: 'Besuch in',
		doctorCheckUp: 'Arztcheck',
		laboratoryTesting: 'Laboruntersuchungen',
		invited: 'Eingeladen',
		notShowedUp: 'Nicht aufgetaucht',
		inProgress: 'Im Gange',
		cancelled: 'Abgesagt',
		frontDeskPatientCheckinPending: 'Rezeption - Patienten-Check-in - Ausstehend',
		frontDeskPatientCheckinInvited: 'Rezeption - Patienten-Check-in - Eingeladen',
		frontDeskPatientCheckinInprogress: 'Rezeption - Patienten-Check-in - In Bearbeitung',
		frontDeskPatientNotShowedUp: 'Rezeption - Patient nicht erschienen',
		frontDeskPatientCheckinCompleted: 'Rezeption – Patienten-Check-in abgeschlossen',
		frontDeskPatientCheckinCancelled: 'Rezeption – Check-in für Patienten storniert',
		patientWaitingForLaboratoryTest: 'Labortest - Wartender Patient',
		laborantInvitedPatient: 'Patient zum Labor eingeladen',
		laborantInvitedPatientNotShowedUp: 'Patient zum Labor eingeladen – nicht erschienen',
		laboratoryTestInProgress: 'Labortest - In Bearbeitung',
		laboratoryVisitCompleted: 'Laborbesuch - Abgeschlossen',
		laboratoryVisitCancelled: 'Laborbesuch - Abgesagt',
		otherInformation: 'Andere Informationen',
		preExistingDescription: 'Vorerkrankungen',
		typeOfAllergies: 'Welche Art von Allergien haben Sie?',
		searchOrAddAllergies: 'Neue Allergien suchen oder hinzufügen',
		typeAllergyAndPressEnter: 'Geben Sie Ihre Allergie ein und drücken Sie die Eingabetaste',
		pleaseSelectOneOfTheOptionsAbove: 'Bitte wählen Sie eine der obigen Optionen aus',
		environmentalAllergies: 'Umweltallergien',
		biologicalAllergies: 'Biologische Allergien',
		writePreExistingCondition: 'Vorerkrankungen schreiben',
		pleaseWritePreExistingCondition: 'Bitte Vorerkrankungen angeben',
		patientToBeTestedFor: 'Patient soll getestet werden',
		creatinine: 'Kreatinin',
		lucose: 'Lukose',
		hemogram: 'Blutbild',
		sedimentation: 'Sedimentation',
		triglycerides: 'Triglyceride',
		urea: 'Harnstoff',
		urine: 'Urin',
		setToPending: 'Auf ausstehend setzen',
		numberOfAnalysis: 'Anzahl der Analysen',
		glucose: 'Glucose',
		assignNewDoctor: 'Neuen Arzt zuordnen',
		reassignToQueue: 'Zu {value} Warteschlange neu zuweisen',
		pleaseWriteCorrectValues: 'Bitte schreiben Sie korrekte Werte',
		caseId: 'Fall-ID',
		checkInNumber: 'Check-in-Nummer',
		addNecessaryVitalSigns:
			'Fügen Sie alle erforderlichen Vitalfunktionen hinzu, indem Sie auf die Schaltfläche „Weitere Daten hinzufügen“ klicken.',
		sediment: 'Sediment',
		erythrocyteSedimentationRate: 'Blutsenkungsreaktion',
		erythrocyteCount: 'Erythrozytenzahl',
		hemoglobin: 'Hämoglobin',
		femtolitre: 'Femtoliter',
		picograms: 'Piktogramme',
		hematocrit: 'Hämatokrit',
		ldlCholesterol: 'LDL-Cholesterin',
		vldCholesterol: 'VLD-Cholesterin',
		view: 'Aussicht',
		color: 'Farbe',
		reaction: 'Reaktion',
		specificWeight: 'Bestimmtes Gewicht',
		proteins: 'Proteine',
		acetone: 'Aceton',
		bilirubin: 'Bilirubin',
		urobilinogen: 'Urobilinogen',
		nitrites: 'Nitrite',
		babyCrying: 'Baby weint',
		babyCryingDetected: 'Baby weint erkannt',
		availableLaboratorians: 'Verfügbare Labortechniker',
		referToOneOfTheLaboratorians: 'Wenden Sie sich an eines der Labors',
		referToThisLaboratorian: 'Wenden Sie sich an dieses Labor',
		leukocyteCount: 'Leukozytenzahl',
		auditLogs: 'Audit-Protokolle',
		client: 'Klient',
		logType: 'Protokolltyp',
		inputter: 'Eingabegerät',
		inputterId: 'Eingabe-ID',
		inputterType: 'Eingabetyp',
		subjectType: 'Subjekt typ',
		subjectName: 'Subjekt Name',
		subjectId: 'Subjekt-ID',
		tenantId: 'Tenant-ID',
		objectType: 'Objekttyp',
		objectId: 'Objekt Identifikation',
		sourceOfInput: 'Quelle der Eingabe',
		actioneeName: 'Aktionsname',
		actioneeObjectId: 'Aktionsobjekt-ID',
		actioneeObjectType: 'Actionee-Objekttyp',
		noAuditLogs: 'Keine Überwachungsprotokolle',
		filterLogs: 'Protokolle filtern',
		user: 'Benutzer',
		resetFilter: 'Filter zurücksetzen',
		waistUnit: 'Tailleneinheit',
		totalCholesterolUnit: 'Gesamtcholesterineinheit',
		hdlCholesterolUnit: 'HDL-Cholesterineinheit',
		contactInformation: 'Kontaktinformationen',
		update: 'Aktualisieren',
		updateYourHealthInformation: 'Aktualisieren Sie Ihre Gesundheitsinformationen',
		updateYourContactInformation: 'Aktualisieren Sie Ihre Kontaktdaten',
		updateYourAddress: 'Aktualisieren Sie Ihre Adresse',
		updateYourPersonalInformation: 'Aktualisieren Sie Ihre persönlichen Daten',
		updateYourEducation: 'Aktualisieren Sie Ihre Ausbildung',
		updateYourEmployment: 'Aktualisieren Sie Ihre Beschäftigung',
		updateYourLicenses: 'Aktualisieren Sie Ihre Lizenzen',
		updateYourSignatureAndStamp: 'Aktualisieren Sie Ihre Unterschrift und Ihren Stempel',
		updateYourLocationAndAddress: 'Aktualisieren Sie Ihren Standort und Ihre Adresse',
		manageProfileInformation: 'Verwalten Sie Ihre Profilinformationen',
		keepAccountSecure: 'Halten Sie Ihr Konto sicher',
		changePharmacyLocation: 'Standort Ihrer Apotheke ändern',
		manageApps: 'Verwalten Sie Ihre Apps',
		changeAppLanguages: 'Sprache der App ändern',
		changeMeasurementUnits: 'Maßeinheiten ändern',
		helpDescription: 'Über, Live-Chat-Unterstützung, Personen einladen',
		appLanguage: 'App Sprache',
		help: 'Hilfe',
		uploadNewPicture: 'Neues Bild hochladen',
		postalCode: 'Postleitzahl',
		otherData: 'Andere Daten',
		selectOption: 'Wähle eine Option',
		selectRails: 'Warum Rails eine Warnung senden soll',
		showFullProfile: 'Vollständiges Profil anzeigen',
		fillMandatoryFields: 'Änderungen können nur gespeichert werden, wenn Sie die Pflichtfelder ausfüllen',
		endDateLaterThanStartDate: 'Das Enddatum muss nach dem Startdatum liegen',
		endDateLaterThanToday: 'Enddatum darf nicht nach dem heutigen Datum liegen',
		rtgPulmonary: 'Lungenscan',
		rtgSkeleton: 'Skelett-Scan',
		rtgChest: 'Brustscan',
		rtgUpperExtremities: 'Scan der oberen Extremitäten',
		rtgLowerExtremities: 'Scan der unteren Extremitäten',
		rtgSpine: 'Wirbelsäulen-Scan',
		ultrasonography: 'Echo - Ultraschall',
		injection: 'Injektion',
		infusion: 'Infusion',
		recording: 'Aufzeichnung',
		recordTheSession: 'Zeichnen Sie die Sitzung auf',
		recordingDescription:
			'Wenn dies aktiviert ist, können Benutzer die Überwachungssitzung aufzeichnen, die im Patientenprofil gespeichert wird.',
		pleaseChooseAnotherDate: 'Bitte wählen Sie ein anderes Datum.',
		infusionTherapy: 'Infusionstherapie',
		injectionTherapy: 'Injektionstherapie',
		deleteRegion: 'Region löschen?',
		atLeastOneRegion: 'Gesundheitssysteme müssen mindestens eine Region haben!',
		deleteHS: 'Gesundheitssystem löschen?',
		regionCannotBeEmpty: 'Region darf nicht leer sein',
		regionExists: 'Region mit diesem Namen existiert bereits',
		healthSystemExists: 'Gesundheitssystem mit diesem Namen existiert bereits',
		positive: 'Positiv',
		negative: 'Negativ',
		inTraces: 'In Spuren',
		clearUrine: 'Klar',
		turbid: 'Trübe',
		reddish: 'Rötlich',
		yellow: 'Gelb',
		acidic: 'Sauer',
		noOngoingVisit: 'Derzeit gibt es keinen laufenden Besuch.',
		noShowUpVisit: 'Derzeit gibt es keinen Fall, in dem Patienten nicht erschienen sind.',
		noCompletedVisits: 'Derzeit gibt es keinen abgeschlossenen Besuch.',
		radiologyScans: 'Radiologischer Scan',
		comment: 'Kommentar',
		enterComment: 'Kommentar eingeben',
		radiologyRoom: 'Radiologieraum',
		newPassword: 'Neues Passwort',
		readTermsAndConditions: 'Lesen Sie unsere Allgemeinen Geschäftsbedingungen',
		readPrivacyPolicy: 'Lesen Sie unsere Datenschutzerklärung',
		chemicals: 'Chemikalien',
		distance: 'Distanz',
		weightAndLeanBodyMass: 'Bezogen auf Gewicht und magere Körpermasse',
		relatedBodyTemperature: 'Bezogen auf die Körpertemperatur',
		heightAndWaistCircumference: 'Bezogen auf Körpergröße und Taillenumfang',
		walkingAndRunning: 'Bezogen auf Geh- und Laufstrecke',
		bloodGlucoseAndCholesterol: 'Bezogen auf Blutzucker, Gesamtcholesterin und HDL-Cholesterin',
		selectUnit: 'Einheit wählen',
		poolingFlow: 'Vereinigend Fluss',
		editGeneralSettings: 'Allgemeine Einstellungen bearbeiten',
		durationDays: 'Tage',
		timesADay: 'Mal am Tag',
		parenteralTherapyReport: 'parenteraler Therapiebericht',
		returnReportFromDiagnostics: 'Rückmeldebericht von der Diagnose',
		searchToAddDiagnosis: 'Suchen, um Diagnose hinzuzufügen',
		addDiagnoses: 'Diagnosen hinzufügen',
		previousDiagnoses: 'Frühere Diagnosen',
		presets: 'Voreinstellungen',
		createPreset: 'Voreinstellung erstellen',
		createNewPreset: 'Neues Preset erstellen',
		typePresetDescription: 'Typ Voreinstellungsname',
		addNewPreset: 'Neue Voreinstellung hinzufügen',
		deletePreset: 'Diese Voreinstellung löschen',
		areYouSureToDeletePreset: 'Sind Sie sicher, dass Sie das Voreinstellung {value} löschen möchten?',
		goToPreset: 'Gehe zu dieser Voreinstellung',
		noPresets: 'Keine Voreinstellungen verfügbar, versuchen Sie, eine hinzuzufügen.',
		presetChangeDescription: 'Sie können es jederzeit ändern',
		presetName: 'Name der Voreinstellung',
		activePreset: 'Aktive Voreinstellung:',
		noActivePreset: 'Keine aktive Voreinstellung',
		resetCamera: 'Kamera zurücksetzen',
		updateToCurrentPosition: 'Aktualisieren zur aktuellen Position',
		pleaseWriteValidDate:
			'„Fügen Sie bitte neuere Erfahrungen hinzu. Leider unterstützen wir derzeit keine Erfahrungen, die 60 Jahre zurückliegen.`',
		returnReportFromParentalTherapy: 'Rückkehrbericht von der Elterntherapie',
		number: 'Nummer',
		refer: 'Verweisen',
		sectorExistsMessage: '{value} mit diesem Namen existiert bereits',
		youAreAlreadyOnCall: 'Sie führen bereits ein Telefongespräch.',
		deviceNotAvailableForCall: 'Das Gerät ist jetzt nicht für Anrufe verfügbar.',
		couldNotGetDeviceState: 'Anrufstatus des Geräts konnte nicht abgerufen werden.',
		visitingHours: 'Besuchszeiten',
		addVisitingHours: 'Besuchszeiten hinzufügen',
		selectAll: 'Wählen Sie Alle',
		deSelectAll: 'Alle abwählen',
		conferenceId: 'Konferenz-ID',
		initiator: 'Initiator',
		roomCount: 'Zimmeranzahl',
		origin: 'Ursprung',
		usedAudio: 'Verwendeter Ton',
		usedVideo: 'Verwendetes Video',
		usedScreen: 'Verwendeter Bildschirm',
		selectContentToDownload: 'Wählen Sie den Inhalt aus, den Sie herunterladen möchten',
		applyGlobalSettings: 'Globale Einstellungen anwenden',
		areYouSureConfigurations: 'Möchten Sie die neuen Konfigurationen wirklich speichern?',
		enforceConfiguration: 'Konfiguration erzwingen',
		overrideLocalConfiguration:
			'Indem Sie diese Option aktivieren, überschreiben Sie den lokalen Konfigurationssatz in der Hierarchie darunter.',
		confirmationModal: 'Modale Bestätigung',
		activeQueueError: 'Sie haben Patienten in Ihrer Warteschlange. Bitte vervollständigen Sie die Warteschlange.',
		chooseRole: 'Wähle eine Rolle',
		selectRoleOrAll: 'Wählen Sie eine Rolle aus oder wählen Sie „Alle“, um den Zeitraum der Inaktivität anzuwenden.',
		addToQueue: 'Zur Warteschlange bei {value} hinzufügen',
		reject: 'Ablehnen',
		rejectSessionReason: 'Grund für die Ablehnung (optional)',
		rejectSession: 'Sitzung ablehnen',
		confirmRejectSession: 'Sind Sie sicher, dass Sie diese Sitzung ablehnen möchten?',
		patientsInQueue: '{value} Patienten in der Warteschlange',
		availableSomethings: 'Verfügbare {value}s',
		nextVisitIn: 'Nächster Besuch ist in {value}',
		pleaseGoToFrontDesk: 'Bitte besuchen Sie die Rezeption, um einer Warteschlange zugewiesen zu werden.',
		hdmiPort: 'HDMI-Anschluss',
		helloPort: 'hello Anschluss',
		defaultSource: 'Standardquelle',
		ringtoneVolume: 'Klingeltonlautstärke',
		'port/siteId': 'Port-/Standort-ID',
		port: 'Port',
		siteId: 'Webseitenadresse',
		apiKey: 'API-Schlüssel',
		pleaseSelectHealthSystem: 'Bitte wählen Sie ein Gesundheitssystem aus.',
		cannotSelectNrMoreThanValue: 'Kann nicht mehr als {value} {item} auswählen,',
		pleaseSelectHospital: 'Bitte wählen Sie ein Krankenhaus aus.',
		pleaseSelectDepartment: 'Bitte wählen Sie eine Abteilung aus.',
		pleaseSelectSector: 'Bitte wählen Sie einen {value} aus.',
		pleaseSelectHDMIPort: 'Bitte wählen Sie einen HDMI-Anschluss aus.',
		pleaseSelectTV: 'Bitte wählen Sie einen Fernseher aus.',
		ringtoneVolumeValidation: 'Die Klingeltonlautstärke muss zwischen 0 und 100 liegen.',
		pleaseSelectEndCallSource: 'Bitte wählen Sie eine TV-Endanrufquelle aus.',
		pleaseSelectProfileName: 'Bitte geben Sie einen Profilnamen ein.',
		pleaseSelectCompanionDevice: 'Bitte wählen Sie ein Begleitgerät aus, falls verfügbar',
		pleaseSetURL: 'Bitte legen Sie eine URL fest.',
		pleaseSetSecret: 'Bitte legen Sie ein Geheimnis fest.',
		pleaseSetApiKey: 'Bitte legen Sie einen API-Schlüssel fest.',
		pleaseSetSiteId: 'Bitte legen Sie eine Website-ID fest.',
		pleaseSetClientId: 'Bitte legen Sie eine Client-ID fest.',
		pleaseSetUserName: 'Bitte legen Sie einen Benutzernamen fest',
		integrationType: 'Bitte wählen Sie einen Integrationstyp aus.',
		portValidation: 'Port muss eine Zahl größer als 0 sein',
		addHSConfiguration: 'Gesundheitssystemkonfiguration hinzufügen',
		addTVConfiguration: 'TV-Konfiguration hinzufügen',
		selectHSForConfiguration:
			'Wählen Sie Gesundheitssystem aus, damit Sie das Krankenhaus auswählen können, auf das die Konfigurationen angewendet werden sollen.',
		chooseHospitalForChanges: 'Wählen Sie das Krankenhaus aus, auf das Sie Änderungen anwenden möchten',
		chooseSectorForChanges: 'Wählen Sie den {value} aus, auf den Sie Änderungen anwenden möchten',
		selectDepartment: 'Wählen Sie den {value} aus, auf den Sie Änderungen anwenden möchten',
		chooseTVSetup: 'Wählen Sie TV-Setup',
		chooseTVSetupType: 'Wählen Sie die Art der TV-Einrichtung aus.',
		selectHelloPort: 'hello Anschluss auswählen',
		selectHDMIPort: 'Wählen Sie den HDMI-Anschluss aus, an den hellocare angeschlossen wird.',
		tvManagement: 'TV-Management',
		selectTVOption: 'Wählen Sie die Option, wo der Fernseher nach dem Ende des Anrufs zurückgehen soll.',
		selectTeleHealthProfile: 'Telemedizinprofil auswählen',
		selectPreConfiguredProfile: 'Wählen Sie das vorkonfigurierte Telemedizinprofil aus. Leer lassen, wenn keines vorhanden ist.',
		helloCareRingtone: 'hellocare Klingelton',
		applyToAllDevices: 'Diese Einstellung wird auf alle Geräte dieses Krankenhauses angewendet.',
		setProfileName: 'Profilnamen festlegen',
		setURL: 'URL festlegen',
		setPort: 'Port setzen',
		setSiteId: 'Site-ID festlegen',
		setAPIKey: 'API-Schlüssel festlegen',
		secret: 'Geheimnis',
		setSecret: 'Geheimnis festlegen',
		setScope: 'Geltungsbereich festlegen',
		pleaseSetScope: 'Bitte legen Sie den Umfang fest.',
		pleaseSetTenantId: 'Bitte legen Sie die Mieter-ID fest.',
		facilityAccessKey: 'Zugangsschlüssel zur Einrichtung',
		pleaseSetFacilityAccessKey: 'Bitte legen Sie den Zugangsschlüssel für die Einrichtung fest.',
		setTheme: 'Thema festlegen',
		pleaseSetTheme: 'Bitte legen Sie das Thema fest.',
		tokenUrl: 'Token-URL',
		pleaseSetTokenUrl: 'Bitte legen Sie die Token-URL fest',
		deleteConfiguration: 'Konfiguration löschen?',
		areYouSureToDeleteConfig: 'Möchten Sie diese Konfiguration wirklich löschen?',
		liveExaminations: 'Live untersuchungen',
		selectOneOfExaminations: 'Bitte wählen Sie eine der Untersuchungen aus, um mit der Messung zu beginnen',
		ear: 'Ohr',
		lungs: 'Lunge',
		throat: 'Kehle',
		skin: 'Haut',
		ecg: 'EKG',
		eCG: 'EKG',
		spirometer: 'Spirometer',
		abdomen: 'Abdomen',
		lungsAir: 'Spirometer',
		connectDevice: '{value} {value1}',
		confirmIotProperlyPlaced: 'Bitte bestätigen Sie mit dem Patienten, dass das Medizinprodukt richtig platziert ist.',
		toCheckConnectToDevice: 'Für {value} {value2} muss der Patient {value3}.',
		toStartMeasureTemperature: 'Um mit der Temperaturmessung zu beginnen, sollte der Patient die Messtaste am Gerät drücken.',
		check: 'überprüfen',
		toMeasure: 'messen',
		otoscope: 'Otoskop',
		dermatoscope: 'Dermatoskop',
		ultrasound: 'Ultraschall',
		deviceIsConnectedStartMeasurement: '{value} ist verbunden, Sie können die Messung starten!',
		startMeasuring: 'Messung starten',
		previousMeasurements: 'Vorherige Messungen',
		showMore: 'Zeig mehr',
		checkMePro: 'Checkme Pro',
		initiateDevice: 'Initiiere {value}',
		assignVisitor: 'Besucher zuordnen',
		visitors: 'Besucher',
		disableVisits: 'Besuche deaktivieren',
		disableVisitsDescription:
			'Wenn dies aktiviert ist, legen Benutzer die maximale Zeit fest, für die die Anrufe an den Patienten für Familie und Freunde deaktiviert werden.',
		enableVisits: 'Besuche aktivieren',
		selectTime: 'Zeit auswählen',
		areYouSureDisableVisits:
			'Indem Sie diese Option aktivieren, legen Sie die maximale Zeit fest, für die die Anrufe an den Patienten für Familie und Freunde deaktiviert werden. Nach Ablauf der ausgewählten Zeit werden die Anrufe basierend auf der Benutzerrolle und dem Zeitplan für Krankenhausbesuche zugelassen.',
		idNumberExists: 'Diese ID-Nummer existiert bereits. Bitte versuchen Sie es erneut!',
		infectiousDisease: 'Infektionskrankheit',
		cardiologist: 'Kardiologe',
		pulmonologist: 'Pulmologe',
		nephrologist: 'Nephrologe',
		callDuringVisitingHours: 'Sie können den Patienten nur während der Besuchszeiten anrufen.',
		retakeMeasurement: 'Messung wiederholen',
		deviceIsMeasuring: '{value} misst',
		waitForCompletionToEnsure: 'Warten Sie auf die Fertigstellung, um genaue Ergebnisse zu gewährleisten!',
		removeDeviceToCompleteMeasure: 'Entfernen Sie das Gerät vom Finger, um die Messung abzuschließen.',
		completeProfileTerms: `Kreuzen Sie hier an, um anzugeben, dass Sie die ' gelesen haben und damit einverstanden sind`,
		terms: 'Bedingungen',
		disabledFor: 'Deaktiviert für',
		familyFriends: 'Freunde der Familie',
		otherPeople: 'Andere Leute',
		hierarchy: 'Hierarchie',
		roomOnList: 'Zimmer',
		selectHSHierarchy: 'Gesundheitssystemhierarchie auswählen',
		applyVisitingHoursAllDays: 'Wenden Sie die Besuchszeiten auf alle Tage der Woche an',
		addedConfigsRecurring: 'Hinzugefügte Konfigurationen werden basierend auf einem bestimmten Wochentag wiederholt.',
		deleteMember: 'Mitglied löschen?',
		stopMeasuring: 'Hör auf zu messen',
		showHistory: 'Zeige die Geschichte',
		error: 'Fehler',
		takingCareOfbaby: 'Sich um dieses Baby kümmern',
		takingCareOfbabyConfirm:
			'Wenn Sie diese Option aktivieren, werden Sie der Chatgruppe mit Familienmitgliedern dieses Babys zugewiesen. Bestätigen?',
		takingCareOfbabyConfirmUnAssigned:
			'Wenn Sie diese Option aktivieren, werden Sie der Chatgruppe mit Familienmitgliedern dieses Babys entzogen. Bestätigen?',
		nicuChat: 'NICU-Chat',
		nicuCheckIn: 'NICU Check-in',
		nicuCheckOut: 'NICU Check-out',
		kiosk: 'Kiosk',
		triage: 'Triage',
		includeRoles: 'Rollen einschließen',
		isSingleSignOutEnabled: 'Einmaliges Abmelden aktivieren',
		deviceIsBusy: 'Gerät ist beschäftigt',
		ntpConfigurations: 'NTP-Konfigurationen',
		ntpUrl: 'NTP-Endpunkt',
		ntpPort: 'NTP-Port',
		setNtpUrl: 'NTP-Endpunkt setzen',
		setNtpPort: 'NTP-Port festlegen',
		setNtpUrlPort: 'NTP-Endpunkt und -Port festlegen',
		pleaseEnterNtpUrl: 'Bitte NTP-Endpunkt eingeben',
		pleaseEnterNtpPort: 'Bitte geben Sie den NTP-Port ein',
		atLeastOneNtpUrlIsRequired: 'Mindestens 1 NTP Enpoint ist erforderlich',
		portIsRequired: 'Port ist erforderlich',
		urlPortCannotBeSame: 'Endpunkt- und Portfelder sollten eindeutig sein',
		turnOnGettingOutOfBed: 'Schalten Sie das Aufstehen ein',
		turnOffGettingOutOfBed: 'Schalten Sie das Aufstehen aus dem Bett aus',
		turnOnInactiveTime: 'Inaktive Zeit des Patienten einschalten',
		turnOffInactiveTime: 'Inaktive Zeit des Patienten ausschalten',
		patientInQueue: 'Sie haben Patienten in Ihrer Warteschlange. Bitte vervollständigen Sie die Warteschlange!',
		medicalVisitInitiated: 'Bereits eingeleiteter Arztbesuch. Bitte schließen Sie den Besuch ab!',
		disableAi: 'AI deaktivieren',
		disableAiTemporarily: 'KI vorübergehend deaktivieren',
		disableAiDescription:
			'Wenn dies aktiviert ist, können Benutzer einen KI-Anwendungsfall deaktivieren, der für eine gewünschte Zeitdauer eingeschaltet ist, und nach Ablauf dieser Zeit setzt die KI ihre Aktivität fort.',
		enableAi: 'AI aktivieren',
		sendRailsAlert: 'Warum Rails eine Warnung senden soll',
		areYouSureDisableAi:
			'Durch Aktivieren dieser Option legen Sie die Zeit fest, zu der die KI-Warnungen für Sie und den Patienten deaktiviert werden. Nach Ablauf der ausgewählten Zeit wird die AI-Funktion aktiviert und Sie erhalten Benachrichtigungen.',
		defaultCamera: 'Standardkamera',
		selectDefaultCamera: 'Standardkamera auswählen',
		selectedDefaultCameraDescription: 'The selected camera is displayed by default for each call type.',
		defaultCameraDescription:
			'Bevor Sie die Kamera {value} als Standardkamera auswählen, stellen Sie bitte sicher, dass sie mit dem Gerät verbunden ist.',
		scheduleDataAcquisition: 'Datenerfassung planen',
		scheduleTimeForDataAcquisition: 'Zeit für die Datenerfassung einplanen',
		dataAcquisitionSchedule: 'Datenerfassungsplan',
		snoozeFalseAlert: 'Dieser Alarm wird für 5 Minuten zurückgestellt.',
		snoozeAcknowledgeAlert: 'Dieser Alarm wird für 15 Minuten ausgeschaltet, während der Patient Hilfe bekommt.',
		configureFeatureFlags: 'Feature-Flags konfigurieren',
		generalAndMenuSettings: 'Allgemeine und Menüeinstellungen',
		featureFlags: 'Feature-Flags',
		featureFlagsDescription:
			'Feature Flags bieten eine zentralisierte Steuerungseinrichtung zum Aktivieren oder Deaktivieren bestimmter Funktionen auf allen Ebenen eines gesamten Unternehmens.',
		selectSector: 'Wähle Wert}',
		sector: 'Sektor',
		pleaseSelectFloor: 'Bitte wählen Sie eine Etage aus.',
		adminPreviousConfigs: 'Durch Auswahl dieser Option überschreiben Sie die vorherigen Konfigurationen der Sektoren unten.',
		maxPortNumber: 'Maximale Portnummer ist',
		portCannotBeNegative: 'Port darf keine negative Zahl sein',
		wearablesDescription: 'Wenn diese Option aktiviert ist, können Wearables während der Live-Untersuchung verwendet werden',
		bodyComposition: 'Körperzusammensetzung',
		snoreDetection: 'Schnarcherkennung',
		caloriesBurned: 'Community Verified icon',
		makeSureDeviceIsOn: 'Stellen Sie sicher, dass das Gerät des Patienten eingeschaltet ist.',
		samsungWatch: 'Samsung-Watch',
		vitalKit: 'Vital-Kit',
		showPreviousMeasurements: 'Vorherige Messungen anzeigen',
		patientHasMeasured: '{value1} hat für {value2} gemessen',
		ecgDoesNotShowSigns: 'Dieses EKG zeigt keine Anzeichen von Vorhofflimmern',
		cannotCheckForSignsOfHeartAttack:
			'Wir können nicht nach Anzeichen eines Herzinfarkts suchen. Wenn Sie glauben, dass Sie einen medizinischen Notfall haben,',
		callEmergencyServices: 'Rufen Sie den Notdienst an',
		theear: 'Ohr',
		thelungs: 'Lunge',
		theheart: 'Herz',
		thethroat: 'Kehle',
		theheartRate: 'Pulsschlag',
		theoxygenSaturation: 'Sauerstoffsättigung',
		thetemperature: 'Temperatur',
		theskin: 'Haut',
		thebloodPressure: 'Blutdruck',
		theecg: 'EKG',
		thebloodGlucose: 'Blutzucker',
		theweight: 'Gewicht',
		theabdomen: 'Abdomen',
		theotoscope: 'Otoskop',
		thestethoscope: 'Stethoskop',
		thepulseOximeter: 'Pulsoximeter',
		thethermometer: 'Thermometer',
		thedermatoscope: 'Dermatoskop',
		theglucometer: 'Glukometer',
		thescale: 'Skala',
		thespirometer: 'Spirometer',
		theultrasound: 'Ultraschall',
		selectAvailableDevices: 'Wählen Sie eines der verfügbaren Geräte aus, um die Untersuchung zu starten.',
		medicalDevices: 'Medizinische Geräte',
		medicalDevicesDescription:
			'Wenn diese Option aktiviert ist, stehen dem Benutzer alle IoT-Geräte während der Live-Untersuchung zur Verfügung',
		aiUsingCamera: 'AI bei Kamera Nutzung',
		guidePatientOtoscope:
			'Führen Sie den Patienten an, den ovalen Ohrtrichter in die zylindrische Kamera am Gerät einzuführen und ihn richtig im Ohr zu platzieren.',
		guidePatientHeartLungs:
			'Führen Sie den Patienten mithilfe der geführten Abbildung auf dem Bildschirm an, das Gerät richtig am Körper zu platzieren.',
		guidePatientThroat:
			'Weisen Sie den Patienten anWeisen Sie den Patienten an, den Zungenspatel in das Gerät einzuführen und richtig auf den Mund zu setzen.',
		guidePatientHeartRate: 'Führen Sie den Patienten dazu, den rechten Daumen auf die untere rechte Elektrode zu legen.',
		guidePatientForMeasurement: 'Weisen Sie den Patienten an, wie die Messung durchgeführt wird.',
		guidePatientDiabetes: 'Stellen Sie sicher, dass die Geräte des Patienten eingeschaltet und über Bluetooth verbunden sind',
		guidePatientECG:
			'Führen Sie den Patienten und drehen Sie das Gerät horizontal nach rechts. Drücken Sie mit dem rechten Daumen auf die untere rechte Elektrode. Drücken Sie mit dem linken Daumen auf die untere linke Elektrode. Drücken Sie mit dem linken Zeigefinger auf die linke obere Elektrode.',
		startDateLaterThanToday: 'Startdatum darf nicht nach dem heutigen Datum liegen',
		pleaseWriteAllergy: 'Bitte schreiben Sie mindestens eine Allergie und klicken Sie auf Enter!',
		examKit: 'All-in-One-Prüfungskit',
		examKitDescription:
			'Wenn diese Option aktiviert ist, steht das All-in-One-Prüfungskit-Gerät zur Verwendung während der Live-Untersuchung zur Verfügung',
		aiMeasurements: 'KI-Messungen',
		AIMeasurementsDescription:
			'Wenn diese Option aktiviert ist, ist die Messung der Vitalfunktionen während der Live-Untersuchung über KI verfügbar',
		earDescription:
			'Wenn dies aktiviert ist, können Gesundheitsdienstleister ein Otoskop aktivieren und eine Ohrenuntersuchung durchführen.',
		lungsDescription:
			'Wenn dies aktiviert ist, können Gesundheitsdienstleister ein Stethoskop aktivieren und die Lungenfunktion beurteilen.',
		heartDescription:
			'Wenn dies aktiviert ist, können Gesundheitsdienstleister ein Stethoskop aktivieren und die Herzaktivität überwachen.',
		throatDescription:
			'Wenn dies aktiviert ist, können Gesundheitsdienstleister ein Otoskop aktivieren und eine Halsuntersuchung durchführen.',
		heartRateDescription:
			'Wenn dies aktiviert ist, können Gesundheitsdienstleister ein Pulsoximeter aktivieren und die Herzaktivität überwachen.',
		oxygenSaturationDescription:
			'Wenn dies aktiviert ist, können Gesundheitsdienstleister ein Pulsoximeter aktivieren und die Sauerstoffsättigung im Blut überprüfen.',
		temperatureDescription:
			'Wenn dies aktiviert ist, können Gesundheitsdienstleister ein Thermometer aktivieren und die Körpertemperatur messen.',
		skinDescription:
			'Wenn dies aktiviert ist, können Gesundheitsdienstleister ein Dermatoskop aktivieren und Hautzustände untersuchen.',
		bloodPressureDescription:
			'Wenn dies aktiviert ist, können Gesundheitsdienstleister eine Blutdruckmanschette aktivieren und den Blutdruck messen.',
		eCGDescription:
			'Wenn dies aktiviert ist, kann der Gesundheitsdienstleister die elektrische Aktivität des Herzens mit einem EKG-Gerät aufzeichnen.',
		bloodGlucoseDescription:
			'Wenn dies aktiviert ist, können Gesundheitsdienstleister ein Blutzuckermessgerät aktivieren und den Blutzucker messen.',
		weightDescription:
			'Wenn dies aktiviert ist, können Gesundheitsdienstleister ein Waagegerät aktivieren und das Körpergewicht aufzeichnen.',
		spirometerDescription:
			'Wenn dies aktiviert ist, können Gesundheitsdienstleister ein Spirometer aktivieren und die Lungenfunktion messen.',
		abdomenDescription:
			'Wenn dies aktiviert ist, können Gesundheitsdienstleister ein Ultraschallgerät aktivieren und abdominale Untersuchungen durchführen.',
		biobeatPatch: 'Biobeat-Patch',
		biobeatPatchDescription:
			'Wenn diese Option aktiviert ist, kann das Biobeat Patch-Gerät während der Live-Untersuchung verwendet werden',
		lifesignalsPatch: 'Lebenssignal-Patch',
		lifeSignalsPatchDescription:
			'Wenn diese Option aktiviert ist, kann das Lifesignal Patch-Gerät während der Live-Untersuchung verwendet werden',
		healthDataDescription:
			'Wenn diese Option aktiviert ist, werden alle Gesundheitsdaten des Patienten während des Anrufs angezeigt.',
		summaryDescription:
			'Wenn dies aktiviert ist, wird die Zusammenfassung der medizinischen Informationen an einer Stelle angezeigt',
		visitsDescription:
			'Wenn diese Option aktiviert ist, wird der gesamte Besuchsverlauf für den Patienten während des Anrufs angezeigt.',
		alertHistoryDescription:
			'Wenn dies aktiviert ist, wird die Option zum Aktualisieren und Anzeigen des Risikoverlaufs für Vitalfunktionen angezeigt.',
		medicalInfoPatientDiagnosis: 'Patientendiagnose',
		patientDiagnosesDescription:
			'Wenn diese Option aktiviert ist, haben Ärzte die Möglichkeit, während des Anrufs eine Diagnose für den Patienten hinzuzufügen.',
		proceduresDescription:
			'Wenn diese Option aktiviert ist, haben Ärzte die Möglichkeit, während des Anrufs eine Behandlung für den Patienten hinzuzufügen.',
		roundingNotesDescription:
			'Wenn diese Option aktiviert ist, haben Ärzte die Möglichkeit, während des Anrufs Notizen für den Patienten hinzuzufügen.',
		prescriptionsDescription:
			'Wenn diese Option aktiviert ist, haben Ärzte die Möglichkeit, während des Anrufs ein Rezept für den Patienten hinzuzufügen.',
		physicalTherapyDescription:
			'Wenn diese Option aktiviert ist, haben Ärzte die Möglichkeit, während des Anrufs neue Übungen für den Patienten hinzuzufügen.',
		wrapUpPage: 'Zusammenfassung der Sitzung',
		sessionWrapUpDescription:
			'Wenn diese Option aktiviert ist, wird den Ärzten eine Seite angezeigt, auf der sie medizinische Informationen überprüfen und eingeben können.',
		previous: 'Vorherige',
		ehr: 'EHR',
		eHR: 'EHR',
		companyImage: 'Ruf der Firma',
		togglePatientsTvON: `Ist eingeschaltet`,
		audioFromMonitoringFeeds: 'Audio von Monitoring-Feeds',
		talkToPatients: 'Sprechen Sie mit Patienten',
		tvChannels: 'Fernsehsender',
		previousState: 'Vorheriger Zustand',
		visitor: 'Besucher',
		nicuMode: 'NICU Modus',
		virtualExaminationMode: 'Virtueller Prüfungsmodus',
		simplifiedMode: 'Vereinfachter Modus',
		eVideonMode: 'eVideon Modus',
		hellocareEngagement: 'Beteiligung bei hellocare',
		patientMonitoring: 'Patientenüberwachung',
		bedDefinition: 'EHR Bett Definition',
		bed: 'Bett',
		locked: 'Gesperrt',
		unlocked: 'Entsperrt',
		callSettingStatus: '{value} {value2} erlaubt',
		is: 'ist',
		isNot: 'ist nicht',
		audioRoutingOptions: 'Audioquellenoptionen',
		selectAudioRoutingOptions: 'Audioquellenoption für jeden Anruftyp auswählen',
		customConfigurations: 'Benutzerdefinierte Konfigurationen',
		basedOnDeviceType: 'Basierend auf dem Gerätetyp',
		selectOneOfRoutingOptions: 'Wähle eine der Tonquellenoptionen',
		audioRoutingDeviceType: 'Wählen Sie spezifische Konfigurationen basierend auf dem Gerät',
		notAuthorizedHealthSystem: 'Sie sind nicht berechtigt, dieses Gesundheitssystem zu verwenden.',
		switchHsFeature: 'Bitte wechseln Sie das Gesundheitssystem, um diese Funktion zu verwenden.',
		tablet: 'Tablet',
		alwaysFromHello: 'Immer von HELLO',
		routesToTvPatientNurse: 'Routen zum Fernseher, wenn der Patient {role} sieht',
		routesToTvSourceAvailable: 'Routen zum Fernseher, wenn Quelle/Zustand verfügbar',
		alwaysPillowSpeaker: 'Immer vom lautsprecher',
		alwaysFromPhone: 'Immer von Telefon',
		alwaysFromTablet: 'Immer von Tablet',
		routeExternalDetected: 'Zu externem Lautsprecher weiterleiten, wenn erkannt',
		viewPatient: 'Patient ansehen',
		callStartAudioOnly: 'Anruf beginnt nur mit Audio',
		callStartAudioVideo: 'Anruf beginnt mit Audio & Video',
		audioOnly: 'Nur mit Audio starten',
		audioVideo: 'Mit Audio & Video beginnen',
		callSettings: 'Anrufeinstellungen',
		roomSignCallSettings: 'Anrufeinstellungen für Raumzeichen',
		providerAndPatientCallSettings: 'Anrufeinstellungen für Anbieter und Patient',
		wallpaper: 'Hintergrundbild und Hintergrund',
		selectCallOptions: 'Wähle zwischen vorgegebenen Optionen für jede Art von Anruf',
		others: 'Andere',
		callTypes: 'Anruftypen',
		areYouSureToRemoveMember: 'Sind Sie sicher dass Sie dieses Mitglied aus dem raum entfernen wollen?',
		notAuthorizedMonitoring: 'Sie sind nicht berechtigt, Monitoring zu verwenden',
		returnReport: 'Rückgabebericht',
		medicalAssessment: 'Medizinische Beurteilung',
		therapy: 'Therapie',
		diagnosticResearch: 'Diagnostische Forschung',
		menuOptions: 'Menüpunkte',
		mainConcerns: 'Hauptanliegen',
		anamnesis: 'Anamnese',
		clickToSave: 'Klicken Sie zum Speichern',
		addMoreAllergies: 'Fügen Sie weitere Allergien hinzu',
		patientAcknowledgedAllergies: 'Der Patient gab zu, die folgenden Allergien zu haben',
		doesHaveAllergies: 'Hat dieser Patient Allergien?',
		writeAllergyAndEnter: 'Geben Sie Allergie ein und drücken Sie zum Hinzufügen die Eingabetaste',
		noAddedAllergies: 'Keine zusätzlichen Allergien',
		searchForDiagnoses: 'Suchen Sie nach dem Code oder Namen und drücken Sie die Eingabetaste, um die Diagnose hinzuzufügen',
		addedDiagnoses: 'Diagnosen hinzugefügt',
		recommendations: 'Empfehlungen',
		prescribeDrugs: 'Medikamente verschreiben',
		useToPrescribe: 'Verwenden, um {value} vorzuschreiben',
		drugs: 'Drogen',
		prescribeInfusions: 'Infusionen verschreiben',
		prescribeInjections: 'Injektionen verschreiben',
		addedTests: 'Tests hinzugefügt',
		useToReferPatientTo: 'Wird verwendet, um den Patienten auf den {value} zu verweisen',
		searchforTheCodeAndEnter: 'Suchen Sie nach dem Code und drücken Sie die Eingabetaste, um das Verfahren hinzuzufügen',
		commentForTechnicians: 'Kommentare für die Techniker',
		visitSummaries: 'Besuchen Sie Zusammenfassungen',
		isCompanyLevelConfiguration: 'Konfiguration auf Unternehmensebene',
		autoSynchronizeLogin: 'Automatische Synchronisierung beim Einloggen',
		youAlreadyHaveAddedThisDomain: 'Diese Domain haben Sie bereits hinzugefügt',
		theProvidedDomainIsInvalid: 'Die bereitgestellte Domain ist ungültig (z.B. hellocare.ai)',
		formShouldContainOneDomainEmail:
			'Mindestens eine Domain ist erforderlich, bitte schreiben und Enter drücken, um eine Domain-E-Mail hinzuzufügen.',
		maximumEmailDomainsAllowedAre5: 'Maximal erlaubte E-Mail-Domänen sind 5',
		accountCreationFailed: `Kontoerstellung fehlgeschlagen. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte unser Support-Team für Unterstützung.`,
		accountSynchronizationFailed: `Kontosynchronisierung fehlgeschlagen. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte unser Support-Team für Unterstützung.`,
		accountBeingSynchronizedMayTakeMinutes: `Ihr Konto wird synchronisiert. Dies kann einige Minuten dauern!`,
		autoAdd: 'Automatisch hinzufügen',
		autoUpdate: 'Automatisches Update',
		autoDelete: 'Automatisch löschen',
		adUserDoesNotExist:
			'Dieser Benutzer existiert bereits oder das Benutzerkonto ist nicht in der Benutzerliste der externen Identitätsanbieter vorhanden und kann nicht auf die Anwendung zugreifen. Bitte überprüfen Sie, ob das Konto korrekt ist.',
		additionalDataMissing:
			'Zusätzliche Skalendaten sind nicht verfügbar, da in den Patientenprofilen keine Informationen zu Größe, Gewicht und Geschlecht vorhanden sind',
		missingWeight: 'Zusätzliche Waagendaten sind aufgrund des fehlenden Gewichts nicht verfügbar',
		missingHeight: 'Zusätzliche Maßstabsdaten sind aufgrund fehlender Höhe nicht verfügbar',
		missingGender: 'Zusätzliche Skalendaten sind aufgrund des Fehlens von Geschlecht nicht verfügbar',
		unrealisticWeight: 'Das Gewicht stimmt nicht mit der Körpergröße des Patienten überein',
		shoesOn:
			'Die Waage kann die Körperimpedanz nicht erkennen. Stellen Sie sicher, dass Ihre nackten Füße richtig auf den 4 Elektroden platziert sind',
		emailNotValid: 'Email ist ungültig',
		emailDuplicated: 'E-Mail dupliziert',
		taskCompleted: 'Aufgabe erledigt',
		noEmailsExelFile: 'Ihre Excel-Datei enthält keine E-Mails',
		errorType: 'Fehlertyp',
		bulkUpload: 'Massen-Upload',
		emailList: 'E-Mail liste',
		medium: 'Mittel',
		sensitivity: 'Empfindlichkeit',
		highSensitivity:
			'Dieser Modus ist so konzipiert, dass er schon bei der geringsten Bewegung einer Person von ihrem Bett weg einen Alarm auslöst.',
		mediumSensitivity: 'Dieser Modus soll einen Alarm auslösen, wenn sich eine Person geringfügig von ihrem Bett entfernt hat.',
		lowSensitivity:
			'Dieser Modus ist so programmiert, dass er nur dann einen Alarm auslöst, wenn eine Person ihr Bett vollständig verlassen hat.',
		standOnScale: 'Stellen Sie sich auf die Waage',
		deviceDisconnect: 'Getrennt',
		lowBodyTemperatureDescription: 'Niedrige Körpertemperatur',
		highBodyTemperatureDescription: 'Hohe Körpertemperatur',
		lowHeartRateDescription: 'Der Patient hat eine sehr niedrige Herzfrequenz!',
		highHeartRateDescription: 'Der Patient hat eine sehr hohe Herzfrequenz!',
		criticalHighBodyTemperature: 'Kritisch hohe Körpertemperatur',
		lowBMIUnderweight: 'Niedriger BMI Untergewicht',
		highBMI: 'Hoher BMI',
		criticalHighBMI: 'Kritisch hoher BMI',
		systolicLowBloodPressure: 'Systolischer niedriger Blutdruck',
		diastolicLowBloodPressure: 'Diastolischer niedriger Blutdruck',
		systolicCriticalLowBloodPressure: 'Systolischer kritisch niedriger Blutdruck',
		diastolicCriticalLowBloodPressure: 'Diastolischer kritisch niedriger Blutdruck',
		systolicHighBloodPressure: 'Systolischer Bluthochdruck',
		diastolicHighBloodPressure: ' Diastolischer Bluthochdruck',
		systolicCriticalHighBloodPressure: 'Systolischer kritischer Bluthochdruck',
		diastolicCriticalHighBloodPressure: 'Diastolischer kritischer Bluthochdruck',
		lowOxygenSaturation: 'Niedrige Sauerstoffsättigung',
		criticalLowOxygenSaturation: 'Kritisch niedrige Sauerstoffsättigung',
		thehighHeartRate: 'Hohe Herzfrequenz',
		thelowHeartRate: 'Niedrige Herzfrequenz',
		thelowBodyTemperature: 'Niedrige Körpertemperatur',
		thehighBodyTemperature: 'Hohe Körpertemperatur',
		thecriticalHighBodyTemperature: 'Kritisch hohe Körpertemperatur',
		thelowBMIUnderweight: 'Niedriger BMI Untergewicht',
		thehighBMI: 'High BMI',
		thecriticalHighBMI: 'Kritisch hoher BMI',
		thesystolicLowBloodPressure: 'Systolischer niedriger Blutdruck',
		thediastolicLowBloodPressure: 'Diastolischer niedriger Blutdruck',
		thesystolicCriticalLowBloodPressure: 'Systolischer kritisch niedriger Blutdruck',
		thediastolicCriticalLowBloodPressure: 'Diastolischer kritisch niedriger Blutdruck',
		thesystolicHighBloodPressure: 'Systolischer Bluthochdruck',
		thediastolicHighBloodPressure: 'Diastolischer Bluthochdruck',
		thesystolicCriticalHighBloodPressure: 'Systolischer kritischer Bluthochdruck',
		thediastolicCriticalHighBloodPressure: 'Diastolischer kritischer Bluthochdruck',
		thelowOxygenSaturation: 'Niedrige Sauerstoffsättigung',
		thecriticalLowOxygenSaturation: 'Kritisch niedrige Sauerstoffsättigung',
		thehighBloodGlucose: 'Hoher Blutzucker',
		thelungsAir: 'FEV1/PEF',
		stethoscopeMeasurements: 'Stethoskop-Herzmessungen',
		heartStetho: 'Herz',
		lungsStetho: 'Lunge',
		clickAnyPoint:
			'Klicken Sie unten auf eine der Aufzeichnungen, um die Wiedergabe der Datei zu starten und das Messdiagramm anzuzeigen',
		availableRecordings: 'Verfügbare Aufnahmen',
		measurement: 'Messung',
		pause: 'Pause',
		more: 'Mehr',
		switchToFrontBack: 'Wechseln sie zur {value}',
		frontView: 'vorderseite',
		backView: 'zurück',
		totalMeasurements: '{value} Messung/en',
		allRoleCustomizationsDisabled: 'Die Funktion zur Anpassung der Rolle könnte für Ihre Rolle deaktiviert sein.',
		point: 'Punkt',
		selectOneOfThePoints: 'Wählen Sie einen der Punkte aus, um mit der Messung zu beginnen!',
		selectAnotherPoint: 'Wählen Sie einen anderen Punkt',
		front: 'Vorderseite',
		bulkView: 'Massenansicht',
		defaultView: 'Standardansicht',
		maxEmail: 'Sie können maximal 100 E-Mails hochladen',
		missingPatientInfo: 'Fehlende Patientendaten',
		conversationFiles: 'Konversationsdateien',
		conversationPhotos: 'Gesprächsfotos',
		noPermission: 'Sie haben keine Berechtigung!',
		sendRequestFailed: 'Anfrage senden fehlgeschlagen. Bitte versuche es erneut!',
		conferenceFailed: 'Konferenz fehlgeschlagen. Bitte versuche es erneut!',
		requestHasBeenSent: 'Anfrage wurde gesendet!',
		manageBedside: 'Krankenbett verwalten',
		serialNumber: 'Seriennummer',
		roomSignage: 'Raumbeschilderung',
		respiration: 'Atmung',
		facialSkinAge: 'Alter der Gesichtshaut',
		stressIndex: 'Stress-Index',
		cardiacWorkload: 'Herzarbeitsbelastung',
		heartAttackRisk: 'Herzinfarktrisiko',
		strokeRisk: 'Schlaganfallrisiko',
		extremelyLowOxygen: 'Der Patient hat eine extrem niedrige Sauerstoffsättigung!',
		lowOxygen: 'Der Patient hat eine niedrige Sauerstoffsättigung!',
		appleWatch: 'Apple Watch',
		selectTimer: 'Timer auswählen',
		snoozeAlert: 'Dieser Alarm wird für {value} deaktiviert, während der Patient Hilfe bekommt.',
		interpretationServices: 'Dolmetscherdienste',
		cardiacOutput: 'Herzleistung',
		cardiacIndex: 'Herzindex',
		reloadLatestFeatures: 'Bitte {value} Sie die Seite, um auf die neuesten Funktionen und Verbesserungen zuzugreifen',
		minute: 'Minute',
		generateQRCode: 'QR-Code generieren',
		clickGenerateQRCode: 'Bitte klicken Sie auf QR-Code generieren, um Vital Kit zu autorisieren',
		scanGeneratedQRCode:
			'QR-Code generiert. Bitten Sie die Krankenpflegerin am Krankenbett, den QR-Code mit dem All-in-one Exam Kit zu scannen.',
		removedAek: 'Der All-in-one Exam Kit wurde aus der Sitzung entfernt.',
		spirometerMeasurements: 'Spirometer-Messungen',
		spirometerTooltip: `Ein Spirometer ist ein Diagnosegerät, das die Luftmenge misst, die Sie ein- und ausatmen können. Es verfolgt auch die Zeit, die Sie benötigen, um vollständig auszuatmen, nachdem Sie tief eingeatmet haben. Bei einem Spirometrietest müssen Sie in einen Schlauch atmen, der an einer Maschine namens Spirometer befestigt ist.`,
		guidePatientSpirometer: 'Führen Sie den Patienten an, das Gerät richtig zu platzieren',
		selectedTranslator: 'Ausgewählter Übersetzer',
		areYouSureRemoveTranslation: 'Möchten Sie den Übersetzungsdienst wirklich entfernen?',
		providerName: 'Anbietername',
		otoscopeMeasurements: 'Otoskop {value} Messungen',
		dermatoscopeMeasurements: 'Hautmessungen mit einem Dermatoskop',
		darkMode: 'Dunkler modus',
		handWashing: 'Händewaschen',
		handsDisinfected: 'Hände desinfiziert',
		handsDisinfectedSuccessfully: 'Hände erfolgreich desinfiziert!',
		lastUpdate: 'Letztes update',
		interpreter: 'Dolmetscherin',
		resourceAssigned: 'Ressource zugewiesen',
		e2ee: 'E2EE-Anrufe',
		dialIn: 'Einwählen',
		dialOut: 'Herauswählen',
		ptzCam: 'PTZ-Steuerung',
		pTZControlsDescription:
			'Wenn dies aktiviert ist, können Benutzer die PTZ der {helloName} Geräte und der {huddleName} USB-Kamera steuern und Lesezeichen für bestimmte Kamerapositionen erstellen.',
		faceDownDescription:
			'Wenn dies aktiviert ist, werden Benutzer über KI benachrichtigt, wenn ein Patient nach unten gerichtet ist.',
		aIPrivacyModeDescription:
			'Wenn dies aktiviert ist, können Benutzer den Patienten ausblenden und eine Strichfigur über ihn zeichnen, um seine Privatsphäre zu schützen.',
		handWashingDescription:
			'Wenn dies aktiviert ist, können Krankenhausadministratoren über KI die Einhaltung der Hygienevorschriften durch Gesundheitsdienstleister überwachen/verfolgen.',
		fallDetectionDescription:
			'Wenn dies aktiviert ist, werden Benutzer über KI benachrichtigt, wenn ein Patient auf den Boden fällt.',
		patientMobility: 'Patientenmobilität',
		patientWalkingDescription:
			'Wenn dies aktiviert ist, werden Benutzer über KI benachrichtigt, wenn ein Patient beim Gehen gefährdet ist.',
		railsDescription:
			'Wenn dies aktiviert ist, können Benutzer über KI benachrichtigt werden, wenn ein Patient eine Schiene von seinem Bett herunterlegt.',
		inactiveTimeDescription:
			'Wenn dies aktiviert ist, können Benutzer über KI benachrichtigt werden, wenn ein Patient für einen bestimmten Zeitraum inaktiv war.',
		gettingOutOfBedDescription:
			'Wenn dies aktiviert ist, können Benutzer über KI benachrichtigt werden, wenn ein Patient versucht, aus seinem Bett aufzustehen.',
		aIVitalSignsDescription:
			'Wenn dies aktiviert ist, können Benutzer die Vitalfunktionen der Patienten mithilfe künstlicher Intelligenz überwachen.',
		biobeatVitalSigns: 'Biobeat-Vitalzeichen',
		biobeatVitalSignsDescription:
			'Durch diese Option können Benutzer die Biobeat-Lebenszeichenüberwachung in Echtzeit aktivieren.',
		smartWatchVitalSigns: 'Smartwatch-Vitalfunktionen',
		smartWatchVitalSignsDescription:
			'Durch diese Option können Benutzer die Smartwatch-Live-Überwachung der Vitalfunktionen aktivieren.',
		dialInDescription:
			'Wenn dies aktiviert ist, wird die Option angezeigt, Personen über die Telefonnummer zum Anruf einzuladen.',
		dialOutDescription: 'Wenn dies aktiviert ist, wird die Option zum Anrufen von Personen über die Telefonnummer angezeigt.',
		inviteViaEmailDescription: 'Wenn dies aktiviert ist, wird die Option angezeigt, Personen per E-Mail zum Anruf einzuladen.',
		interpretationServiceDescription: 'Wenn dies aktiviert ist, werden Dolmetschdienste in verschiedenen Sprachen angezeigt.',
		inviteViaSmsDescription: 'Wenn diese Option aktiviert ist, wird die Option angezeigt, Personen per SMS zum Anruf einzuladen.',
		smsWithInvitationNote: 'Einladung mit Einladungshinweis senden',
		smsWithoutInvitationNote: 'Einladung ohne Einladungshinweis senden',
		meetingUrl: 'Besprechungs-URL',
		meetingUrlDescription: 'Wenn dies aktiviert ist, wird die Option angezeigt, Personen über den Link zum Anruf einzuladen.',
		roundingNightVisionDescription:
			'Wenn dies aktiviert ist, wird die Option angezeigt, während des Anrufs im Nachtsichtmodus zu sehen.',
		gridViewDescription: 'Wenn dies aktiviert ist, wird die Option angezeigt, alle Anrufteilnehmer als Rasteransicht anzuzeigen.',
		fitToScreenDescription:
			'Wenn diese Option aktiviert ist, wird die Option angezeigt, während des Anrufs den Vollbildmodus oder die Bildschirmgröße anzupassen.',
		screenSharing: 'Bildschirm teilen',
		screenSharingDescription: 'Wenn dies aktiviert ist, wird die Option zum Teilen des Bildschirms während des Anrufs angezeigt.',
		invitingPeople: 'Menschen einladen',
		generalFeatures: 'Allgemeine Merkmale',
		communicatingWithThePatient: 'Kommunikation mit dem Patienten',
		communicatingWithNurses: 'Kommunikation mit {roleNameNurse}n',
		automaticAlerts: 'Automatische Alarme',
		cannotBeEmpty: 'Kann nicht leer sein',
		viewPatientTooltip:
			'Diese Option ermöglicht es Ihnen, den Patienten zu sehen und zu hören; der Patient kann Sie nicht sehen oder hören, es sei denn, Sie aktivieren dies während der Übertragung.',
		ttpTooltip:
			'Diese Option initiiert einen Audio-Anruf beim Patienten, sie werden Sie hören können und Sie werden sie hören können. Während des Anrufs können Sie Ihre oder die Kamera des Patienten einschalten oder sogar Ihren Bildschirm teilen.',
		userNoAccess: 'Sie sind nicht berechtigt, diese Aktion abzuschließen.',
		reload: 'Neu laden',
		rpmProgram: 'RPM-Programm',
		rpmPrograms: 'RPM-Programme',
		babyRoomDeviceBusy: 'Sie können das Baby nicht sehen, da {value} auf Abruf ist',
		addRpmProgram: 'RPM-Programm hinzufügen',
		pleaseWriteProgramName: 'Bitte schreiben Sie ein Programm',
		careTeam: 'Betreuungsteam',
		pleaseSelectCareTeam: 'Bitte wählen Sie die Mitglieder des Pflegeteams aus',
		primaryCarePhysician: 'Hausarzt',
		specialist: 'Spezialist',
		pharmacist: 'Apotheker',
		careGiver: 'Betreuer',
		selectMember: 'Wählen Sie ein Mitglied aus',
		survey: 'Umfrage',
		viewSurvey: 'Umfrage anzeigen',
		viewCareTeam: 'Pflegeteam anzeigen',
		score: 'Punktzahl',
		heartRateSpo2: 'Herzfrequenz und SpO2',
		mod: 'Mittel',
		searchByName: 'Suche nach Patienten nach Namen',
		pleaseSelectRpmProgram: 'Bitte wählen Sie ein RPM-Programm aus',
		selectProgram: 'Wählen Sie ein Programm aus',
		pleaseSelectMember: 'Bitte wählen Sie einen {value} aus.',
		program: 'Programm',
		createProgram: 'Programm erstellen',
		enterDifferentName: 'Geben Sie einen anderen Namen als die vordefinierten Programme ein.',
		deleteProgram: 'Programm löschen?',
		areYouSureDeleteProgram: 'Sind Sie sicher, dass Sie dieses Programm löschen möchten?',
		heartLungsAuscultation: 'Auskultation von Herz und Lunge',
		creatingProgram: 'Programm erstellen',
		programName: 'Programmname',
		measurementsSelection: 'Auswahl der Maße',
		pickMeasurements: 'Wählen Sie die Messungen und Geräte aus, die Sie für dieses Programm aktivieren möchten.',
		selectedMeasurements: 'Ausgewählte Messungen',
		measurements: 'Messungen',
		pleaseSelectMeasurement: 'Bitte wählen Sie mindestens eine Messung aus.',
		pleaseSelectDevice: 'Bitte wählen Sie für jede ausgewählte Messung mindestens ein Gerät aus.',
		editingProgram: 'Bearbeitungsprogramm',
		editProgram: 'Programm bearbeiten',
		programExists: 'Dieses Programm existiert bereits!',
		maximumDevices: 'Die Messung sollte bis zu zwei Geräte umfassen!',
		assignPatch: 'Patch zuweisen',
		wearables: 'Wearables',
		provider: 'Anbieter',
		wearableId: 'Geräte ID',
		wearableType: 'Typ',
		patchWearableType: 'Patch',
		watchWearableType: 'Uhr',
		inactive: 'Inaktiv',
		viewFullData: 'Vollständige Daten anzeigen',
		showEcg: 'Zeige EKG',
		monitor: 'Überwachen',
		aspDescription:
			'Alexa Smart Properties für das Gesundheitswesen macht es Krankenhäusern und Anbietern einfach und kostengünstig, sich um ihre Patienten zu kümmern. Alexa Smart Properties vereinfacht die Bereitstellung und Verwaltung von Alexa-fähigen Geräten in großem Maßstab und ermöglicht es Immobilien, HIPAA-fähige Alexa-Fähigkeiten zu aktivieren und Pflegedienstleister über Spracherlebnisse mit Patienten zu verbinden.',
		alexaSmartProperties: 'Alexa Smart Properties',
		alexaSmartPropertiesActivated: 'Alexa Smart Properties aktiviert!',
		aspFailed: 'ASP konnte nicht aktiviert werden. Bitte versuchen Sie es später erneut',
		nutritionist: 'Ernährungswissenschaftlerin',
		providerError: 'Bitte wählen Sie einen Anbieter.',
		wearableTypeError: 'Bitte wählen Sie den Typ des Geräts aus.',
		wearableIdEmpty: 'Bitte geben Sie die ID des Geräts ein.',
		invalidWearableId: 'Bitte geben Sie eine gültige ID ein.',
		patchIdAlreadyExists: 'Patch-ID existiert bereits',
		baseURL: 'Basis-URL',
		addBaseUrl: 'Basis-URL hinzufügen',
		addApiKey: 'API-Schlüssel hinzufügen',
		eCareManager: 'E-Care-Manager',
		secretKey: 'Geheimer Schlüssel',
		nodeId: 'Knoten-ID',
		nodeLevel: 'Knotenebene',
		carelogs: 'Pflegeprotokolle',
		carelogDetails: 'Details zum Pflegeprotokoll',
		visitInfo: 'Besuchen Sie Info',
		patientInfo: 'Patienteninformationen',
		endVisit: 'Endbesuch',
		careMemberType: 'Pflegemitgliedstyp',
		liveMeasurements: 'Live-Messungen',
		medicalRecordNumber: 'Krankenaktennummer',
		patientsCareLogs: `{value}'s Pflegeprotokolle`,
		possessiveSessions: 'Sitzungen von {value}',
		at: 'bei {value}',
		removePosition: 'Position entfernen',
		removeLicense: 'Lizenz entfernen',
		removeEducation: 'Entfernen Sie Bildung',
		ewsScore: 'EWS Punktzahl',
		lowRisk: 'Geringes Risiko',
		ewsSettings: 'EWS-Einstellungen',
		removeOption: 'Option entfernen',
		removeQuestion: 'Frage entfernen',
		display: 'Anzeige',
		theme: 'Thema',
		defaultTheme: 'Standardthema',
		light: 'Hell',
		dark: 'Dunkel',
		question: 'Frage',
		option: 'Möglichkeit',
		addNewSurveyDescription: 'Es wurden noch keine Umfragen hinzugefügt.',
		addNewSurvey: 'Neue Umfrage hinzufügen',
		addNewQuestion: 'Neue Frage hinzufügen',
		addMoreOptions: 'Fügen Sie weitere Optionen hinzu',
		atLeastTwoOptions: 'Bitte wählen Sie pro Frage mindestens zwei Optionen aus',
		valuesCannotBeEmpty: 'Werte dürfen nicht leer sein',
		options: 'Optionen',
		patchNotExist: 'Dieser Patch existiert nicht',
		telemetry: 'Telemetrie',
		deleteFileForMyself: 'Datei für mich selbst löschen',
		deleteFileWarning: 'Sind Sie sicher, dass Sie diese Datei löschen möchten?',
		conferenceEndedParticipantInviteDenied:
			'Sie verfügen nicht über ausreichende Berechtigungen, um diesen Teilnehmer anzurufen. Bitte wenden Sie sich an Ihren Administrator.',
		participantInviteDenied:
			'Sie verfügen nicht über ausreichende Berechtigungen, um {value} aufzurufen. Bitte wenden Sie sich an Ihren Administrator.',
		switchingToOtherTab:
			'Diese Überwachungssitzung wird beendet und die Fenster werden geschlossen, wenn Sie diese Seite verlassen. Sind Sie sicher, dass Sie gehen möchten?',
		key: 'Schlüssel',
		selectConfiguration: 'Konfiguration auswählen',
		selectConfigurationDesc: 'Wähle die Konfiguration aus, die du anwenden möchtest',
		nodeName: 'Knotenname',
		scope: 'Geltungsbereich',
		issuer: 'Aussteller',
		returnUrl: 'Rückgabeadresse',
		oauthScopes: 'OAuth-Berechtigungen',
		dictate: 'Diktieren',
		write: 'Schreiben',
		statAlarmInCSV: 'STAT-Alarm',
		statAlarm: 'Statistischer Alarm',
		statAlarmDescription:
			'Wenn dies aktiviert ist, sehen Benutzer in jedem Überwachungs-Feed ein zusätzliches Symbol, und wenn es umgeschaltet wird, wird auf der Patientenseite ein Alarm abgespielt.',
		statAlarms: 'STAT-Alarme',
		activationTime: 'Aktivierungszeit',
		deactivationTime: 'Deaktivierungszeit',
		editRoomNameRounding: 'Raumnamen bearbeiten',
		editRoomNameMonitoring: 'Raumnamen bearbeiten',
		editRoomNameRoundingDescription: 'Wenn dies aktiviert ist, können Benutzer Raumnamen für die Rundung bearbeiten',
		editRoomNameMonitoringDescription: 'Wenn dies aktiviert ist, können Benutzer Raumnamen für die Überwachung bearbeiten',
		enableStatAlarm: 'Statischen Alarm aktivieren',
		disableStatAlarm: 'Statischen Alarm deaktivieren',
		alexaEnabled: 'Alexa',
		enableAlexa: 'Aktivieren Sie Alexa für dieses Unternehmen',
		enableAlexaConfiguration:
			'Durch Aktivieren dieser Funktion können Krankenhäuser Alexa auf ihren {helloName} Geräten verwenden.',
		pleaseSetPassword: 'Bitte legen Sie ein Passwort fest.',
		errorStatAlarm:
			'Bei der Umschaltung des statischen Alarms von {value} ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!',
		shippingAddress: 'Lieferanschrift',
		requestedBy: 'Angefordert von',
		shipped: 'Ausgeliefert',
		logistics: 'Logistik',
		patientProfile: 'Patientenprofil',
		dashboard: 'Dashboard',
		totalNumberOfCalls: 'Gesamtzahl der Anrufe',
		sessionByRole: '{value} Sitzungen nach Rolle',
		totalNumberSessions: 'Gesamtzahl der Sitzungen',
		averageSessionDuration: 'Durchschnittliche Sitzungsdauer',
		totalDuration: 'Gesamtdauer',
		durationInSeconds: 'Dauer in Sekunden',
		sessionsByHour: 'Sitzungen nach Stunde',
		session: 'Sitzung',
		sessions: 'Sitzungen',
		ambient: 'Umgebungs',
		monitoringSessions: 'Überwachungssitzungen',
		totalSessionsDuration: 'Gesamtdauer der Sitzungen',
		outgoingSessionsDescription: 'Gespräche mit Patienten, die vom Pflegeteam initiiert wurden',
		incomingSessionsDescription: 'Gespräche mit Patienten, die von Patienten initiiert wurden',
		viewPatientSessionsDescription: 'Vom Pflegeteam initiierte Patientensitzungen anzeigen',
		familyVisits: 'Familienbesuche',
		avgSessions: 'Durchschnittliche Sitzungen pro Stunde',
		highestVolumeOfSessions: 'Höchstes Sitzungsvolumen',
		totalSessions: 'Gesamtzahl der Sitzungen',
		sessionByType: 'Sitzungen nach Typ',
		externalIdentityProviders: 'Externe Identitätsanbieter',
		externalIdentityProviderMembers: 'Externe Identitätsanbieter Mitglieder',
		selectSomething: 'Wählen Sie {value}',
		noMembersAdded:
			'Es wurden noch keine {value} hinzugefügt. Um mit dem Hinzufügen von {value} zu beginnen, klicken Sie einfach auf die Schaltfläche oben. Sie können bis zu {value2} {value} addieren.',
		doctors: 'Ärzte',
		atLeastOneMember: 'Bitte wählen Sie mindestens ein Mitglied aus!',
		addAdditionalMember:
			'Sie können zusätzlich zu den bereits im Programm enthaltenen Werten weitere {value} hinzufügen. Um mit dem Hinzufügen von {value} zu beginnen, klicken Sie einfach auf die Schaltfläche oben. Sie können bis zu {value2} {value} addieren.',
		editRpmProgram: 'RPM-Programm bearbeiten',
		levelOfConsciousness: 'Ebene des Bewusstseins',
		supplementOxygen: 'Ergänzen Sie Sauerstoff',
		phoneNotReachable: 'Telefonnummer ist nicht erreichbar',
		sipNotAvailable: 'Die Telefonwahl ist derzeit nicht verfügbar.',
		integrations: 'Integrationen',
		eCareApiKey: 'e-care API-Schlüssel',
		eCareBaseUrl: 'e-care Basis-URL',
		deviceWiFiTryingToConnect: 'Versuche, eine Verbindung mit {value} herzustellen',
		deviceWiFiScanFailed: '{value} Scan fehlgeschlagen. Bitte warten Sie etwas und versuchen Sie es erneut.',
		diviceWiFiScanNotFound: '{value} nicht gefunden, bitte versuchen Sie es erneut.',
		deviceWiFiConnectionFailed: '{value} Verbindung fehlgeschlagen. Bitte versuche es erneut.',
		deviceWiFiDisconnected: '{value} getrennt. Bitte versuche es erneut.',
		length: 'Länge',
		viewAllProviders: 'Alle Anbieter anzeigen',
		totalNumberOfProviders: 'Gesamtzahl der Anbieter',
		lastSessionDate: 'Datum der Letzten Sitzung',
		noProvidersFound: 'Auf der Liste sind keine Anbieter mit abgeschlossenen Sitzungen aufgeführt',
		sessionStart: 'Sitzungsbeginn',
		sessionEnd: 'Sitzungsende',
		sessionStatus: 'Sitzungsstatus',
		sessionDetails: 'Sitzungsdetails',
		callStart: 'Rufen Sie Start an',
		callEnd: 'Anruf beenden',
		event: 'Ereignis',
		intervenedBy: 'Interveniert von',
		allowMicPermissions:
			'hellocare benötigt Zugriff auf Ihr Mikrofon, damit Patienten Sie hören können. Bitte erlauben Sie den Mikrofonzugriff in den Webbrowser-Einstellungen.',
		turnOnDictation: 'Diktat einschalten',
		turnOffDictation: 'Diktat ausschalten',
		somethingWentWrongSpecific: '{value}. Etwas ist schief gelaufen!',
		unitCannotBeEmpty: 'Einheit darf nicht leer sein',
		bedCannotBeEmpty: 'Bett darf nicht leer sein',
		total: 'Gesamt',
		reason: 'Grund',
		subjectIdentification: 'Identifizierung des Subjekts',
		employeeDirection: 'Mitarbeiterführung',
		handHygieneAudits: 'Handhygiene-Audits',
		handHygieneViewed: 'Händehygiene angesehen',
		exportExcel: 'Excel exportieren',
		exportExcelBackground: 'Excel im Hintergrund exportieren',
		complianceReport: 'Hygiene-Compliance-Bericht',
		screenShareNotAllowed: 'Jemand anderes teilt seinen Bildschirm.',
		screenSharingNotAllowed: 'Sie können Ihren Bildschirm nicht teilen, während jemand anderes ihn teilt.',
		ptzCamSettingsTitle: '20x PTZ Kameraeinstellungen',
		reverseCamera: 'Rückfahrkamera',
		allowPermissions: 'Bitte erlauben Sie Kamera und Mikrofon Berechtigungen, um den Patienten anzurufen.',
		guest: 'Gast',
		selectSurvey: 'Wählen Sie den Umfragetyp aus',
		generic: 'Allgemein',
		safetyRange: 'Sicherheitsbereich',
		createSurvey: 'Umfrage erstellen',
		addQuestionsDescription: 'Sie können mit dem Hinzufügen von Fragen beginnen, indem Sie auf die Schaltfläche oben klicken.',
		typeOfSurvey: '{value} Umfrage',
		editSurvey: 'Umfrage bearbeiten',
		surveyType: 'Umfragetyp',
		surveys: 'Umfragen',
		scheduledDate: 'Geplantes Datum',
		questions: 'Fragen',
		rapidRequestType: 'Schnell',
		admissionRequestType: 'Zulassung',
		regularRequestType: 'Regulär',
		failedToGetParticipantInfo: 'Teilnehmerinformationen konnten nicht abgerufen werden. Bitte versuche es erneut.',
		failedToGetYourInfo: 'Ihre Informationen konnten nicht abgerufen werden. Bitte versuche es erneut.',
		failedToGetParticipantInfoName: '{value} Informationen konnten nicht abgerufen werden. Bitte versuche es erneut.',
		failedToGetInitiatorInfo: 'Informationen zum Initiator konnten nicht abgerufen werden. Bitte versuche es erneut.',
		ascAlphaOrder: '(A-Z)',
		descAlphaOrder: '(Z-A)',
		ascNumOrder: '(0-9)',
		descNumOrder: '(9-0)',
		queueManagement: 'Warteschlange Verwaltung',
		rapidRequestTypeCalls: 'Schnelle Anrufe',
		admissionRequestTypeCalls: 'Zulassungsaufrufe',
		regularRequestTypeCalls: 'Regelmäßige Anrufe',
		manageQueue: 'Warteschlange verwalten',
		viewQueue: 'Warteschlange anzeigen',
		doctorsQueue: `'s Warteschlange`,
		noAvailableDoctor: 'In diesem Krankenhaus gibt es keinen verfügbaren Arzt!',
		successfullyTransferPatient: 'Übertragung erfolgreich',
		requestSuccessfullyTransferPatient: 'Ihre Patientenüberweisung wurde erfolgreich versendet!',
		incomingPatientRequest: 'Eingehende Patientenanfrage!',
		changeRpmProgram: 'Drehzahl ändern',
		reasonToConnect: 'Grund für die Verbindung',
		acuity: 'Akuität',
		clinicalConcern: 'Klinische Bedenken',
		somethingInfo: '{value} Informationen',
		clinicalContact: 'Klinischer Kontakt',
		rapidResponse: 'Schnelle Reaktion',
		edAdmitDiscussion: 'Gespräch zur Aufnahme in die Notaufnahme',
		drugOrderOrClarification: 'Medikamentenbestellung / Klarstellung',
		abnormalVital: 'Abnormale Vitalwerte',
		abnormalLab: 'Abnormales Labor',
		criticalLab: 'Kritisches Labor',
		patientDeclining: 'Patienten ablehnend',
		otherEmergent: 'Andere Notfälle',
		reviewOutsideFacilityTransfer: 'Überprüfung des Transfers außerhalb der Einrichtung',
		drnOrDniClarification: 'Klarstellung von DRN oder DNI',
		otherNonEmergent: 'Andere nicht-notfallmäßige Fälle',
		admitIcu: 'Aufnahme auf die Intensivstation',
		admitNonIcu: 'Aufnahme außerhalb der Intensivstation',
		admitBackup: 'Backup-Aufnahme',
		immediate: 'Sofort',
		ext: 'Erweiterung',
		callPhoneNumber: 'Telefonnummer anrufen',
		lifeSignals: 'Lebenssignale',
		lifeSignalDescription:
			'Wenn diese Option aktiviert ist, kann das Lifesignal Patch-Gerät während der Überwachung verwendet werden',
		unableToGetInfo: '{value}s Informationen können nicht abgerufen werden.',
		unableToGetInfoGeneralMessage: 'Teilnehmerinformationen konnten nicht abgerufen werden.',
		callNumber: 'Anruf',
		assignWearable: 'Gerät zuordnen',
		wearableHistoryTooltip: 'Geschichte',
		unassignWearable: 'Sind Sie sicher, dass Sie die Zuweisung dieses Geräts zu diesem Patienten aufheben möchten?',
		unassignWearableTooltip: 'Gerätezuweisung aufheben',
		roleCustomization: 'Rollenanpassung',
		monitoringPrecautionsDescription:
			'Wenn dies aktiviert ist, kann der {value} während der Überwachung die Vorsichtsmaßnahmenlegende sehen.',
		poolingFlowDescription: 'Wenn dies aktiviert ist, kann der {value} den Pooling-Ablauf ändern.',
		conversationDescription: 'Wenn dies aktiviert ist, kann {value} Gespräche sehen.',
		notificationsDescription: 'Wenn dies aktiviert ist, kann der {value} Benachrichtigungen sehen.',
		savedSessionsDescription: 'Wenn dies aktiviert ist, kann der {value} die Sitzungsansicht speichern.',
		conversationFilesDescription:
			'Wenn dies aktiviert ist, kann der {value} während des Chats Dateien mit anderen Benutzern teilen.',
		conversationPhotosDescription: 'Wenn dies aktiviert ist, kann {value} während des Chats Fotos mit anderen Benutzern teilen.',
		homePageDescription:
			'Wenn dies aktiviert ist, sieht {value} eine Liste der Patienten, die darauf warten, an dem Anruf teilzunehmen.',
		waitingRoomDescription:
			'Wenn dies aktiviert ist, sieht {value} eine Liste der Patienten, die darauf warten, an dem Anruf teilzunehmen.',
		aiNotificationsDescription: 'Wenn dies aktiviert ist, kann {value} die Benachrichtigungen zu KI-Alarmen sehen.',
		patientsDescription: 'Wenn dies aktiviert ist, sieht der {value} eine Liste der Patienten, die ihm/ihr zugewiesen wurden.',
		apppointmentsDescription: `Wenn dies aktiviert ist, hat der {value} die Möglichkeit, die Verfügbarkeit festzulegen und Termine für Patienten zu erstellen.`,
		doctorRegistrationForm: 'Anmeldeformular',
		registrationFormPhotosDescription:
			'Wenn die Funktionsflagge aktiviert ist, müssen Ärzte ein Registrierungsformular ausfüllen, um ihre Rolle fortzusetzen.',
		roundingDescription: `Wenn dies aktiviert ist, kann der {value} Rundungen mit Patienten einleiten.`,
		rpmDescription: 'Wenn dies aktiviert ist, kann der {value} RPM-Programme für Patienten starten.',
		chatDescription: 'Wenn dies aktiviert ist, kann {value} einen Chat mit anderen Benutzern initiieren.',
		teamsDescription: 'Wenn dies aktiviert ist, kann {value} einen Chat mit anderen Benutzern initiieren.',
		monitoringDescription: 'Wenn dies aktiviert ist, kann der {value} Überwachungssitzungen initiieren.',
		visitorsDescription: 'Wenn dies aktiviert ist, kann {value} eine Liste der Besucher für den bestimmten Raum sehen.',
		dashboardDescription:
			'Wenn dies aktiviert ist, wird {value} in der Lage sein, Echtzeitdaten für das zugewiesene Gesundheitssystem zu sehen.',
		auditLogsDescription: 'Wenn dies aktiviert ist, kann {value} die Protokolle für ein bestimmtes Gesundheitssystem einsehen.',
		queueManagementDescription:
			'Wenn dies aktiviert ist, wird {value} in der Lage sein, die Warteschlange der Ärzte zu verwalten.',
		organizationDescription:
			'Wenn dies aktiviert ist, wird {value} in der Lage sein, neue Krankenhäuser, Abteilungen, Stockwerke und Zimmer hinzuzufügen.',
		callLogsDescription: 'Wenn dies aktiviert ist, wird {value} in der Lage sein, vergangene und laufende Anrufe zu sehen.',
		userManagementDescription:
			'Wenn dies aktiviert ist, wird {value} in der Lage sein, Mitglieder und eingeladene Mitglieder zu sehen.',
		configurationsDescription:
			'Wenn dies aktiviert ist, wird {value} in der Lage sein, Gerät, Netzwerk und Pflegeereignisse zu konfigurieren.',
		noDataToDownload: 'Keine Daten zum Herunterladen',
		noAssignedWearable:
			'Diesem Patienten ist kein Gerät zugeordnet. Um ein Gerät zuzuweisen, klicken Sie rechts auf die Schaltfläche „+“.',
		enableMultipleFeedAudioDescription:
			'Wenn dies aktiviert ist, können Benutzer Audio aktivieren, wenn sie mehrere Feeds überwachen.',
		patientInformation: 'Patienten information',
		voiceOver: 'Voiceover',
		telehealthDescription:
			'Eine integrierte TV-Lösung ermöglicht es Krankenhauspatienten, Filme und Unterhaltungsinhalte zu genießen und so ihren Aufenthalt zu verbessern, ohne dass körperliche Mobilität erforderlich ist.',
		evideoDescription:
			'eVideon ist eine umfassende Plattform für Patientenerlebnisse, die das Engagement und die Kommunikation in Gesundheitseinrichtungen verbessern soll. Es bietet interaktive Multimedia-Lösungen, die den Patienten personalisierte Bildungs-, Unterhaltungs- und Kommunikationstools bieten und so ihre Zufriedenheit und das gesamte Krankenhauserlebnis verbessern.',
		pcareDescription: `Mit der Patienteneinbindungsplattform von pCare haben Patienten Zugang zu interaktiven Tools für Bildung, Unterhaltung und Kommunikation. Es befähigt Patienten, indem es personalisierte Gesundheitsinformationen und -ressourcen bereitstellt, Ängste abbaut und eine stärkere Einbindung in ihre eigene Pflege fördert.`,
		getwellDescription: `Die Patienteneinbindungs- und Kommunikationsplattform von Getwell ermöglicht eine nahtlose Verbindung zwischen Patienten und ihren Gesundheitsdienstleistern. Es bietet eine Reihe von Tools für Aufklärung, Kommunikation und Pflegekoordination, die es Patienten ermöglichen, auf personalisierte Gesundheitsinformationen zuzugreifen, mit ihrem Pflegeteam zu interagieren und sich aktiv an ihren Behandlungsplänen zu beteiligen.`,
		sonifiDescription:
			'Verbessern Sie das Patientenerlebnis mit der integrierten Lösung von SONIFI Health, die Kommunikation, Aufklärung und Unterhaltung nahtlos miteinander verbindet. Verbessern Sie die Gesundheitsversorgung, indem Sie eine mühelose Patienteneinbindung in Krankenhauszimmern ermöglichen.',
		patientExperience: 'Patientenerfahrung',
		rtls: 'RTLS',
		rtlsDescription: 'Wenn dies aktiviert ist, sehen die Patienten, dass {roleNameNurse} im Raum ist.',
		roomModes: 'Raummodi',
		helloCareDescription: `Wenn diese Option aktiviert ist, werden auf dem Bildschirm Informationen wie Datum, Zimmername und Name des Patienten angezeigt.`,
		nicuDescription: 'Wenn diese Option aktiviert ist, wird auf dem Bildschirm eine Ansicht für ein Babyzimmer angezeigt.',
		hospitalAtHomeMode: 'Krankenhaus zu Hause Modus',
		hospitalAtHomeRMDescription:
			'Aktiviert die Bereitstellung von Krankenhausdiensten auf Krankenhausniveau direkt beim Patienten zu Hause. Wenn dieser Modus aktiviert ist, aktiviert er eine Reihe von Funktionen auf Patienten-Tablets, die darauf abzielen, eine umfassende Fernbetreuung zu erleichtern.',
		hospitalAtHomeAppointments: 'Termine',
		appointmentsDescription:
			'Durch Aktivieren dieser Funktion wird ein "Termine" Tab auf der Startseite des Tablets angezeigt. Dieser Tab ermöglicht es Patienten, ihre bevorstehenden Termine einzusehen, Erinnerungen zu erhalten und ihren Zeitplan zu verwalten.',
		hospitalAtHomeLeadNurse: 'Leitende Krankenschwester',
		leadNurseDescription:
			'Durch Aktivieren dieser Funktion wird ein "Leitende Krankenschwester" Tab auf der Startseite des Tablets angezeigt. Dieser Tab bietet Patienten direkten Zugang zu ihrer zugewiesenen leitenden Krankenschwester, so dass sie Kontakt aufnehmen und kommunizieren können.',
		hospitalAtHomeVitalSigns: 'Vitalzeichen messen',
		vitalSignsHAHDescription:
			'Durch Aktivieren dieser Funktion wird ein "Vitalzeichen messen" Tab auf der Startseite des Tablets angezeigt. Dieser Tab führt Patienten durch den Prozess des Messens und Aufzeichnens ihrer Vitalzeichen wie Blutdruck, Herzfrequenz und Temperatur und sendet die Daten direkt an ihr Betreuungsteam.',
		hospitalAtHomeCallCareTeam: 'Rufen Sie das Pflegeteam an',
		callCareTeamDescription:
			'Durch Aktivieren dieser Funktion wird eine "Rufen Sie das Pflegeteam an" Schaltfläche auf der Startseite des Tablets angezeigt. Diese Funktion ermöglicht es Patienten, ihr Pflegeteam schnell und einfach für Unterstützung, Beratung oder in Notfällen anzurufen.',
		hospitalAtHomeEducation: 'Bildung',
		educationDescription:
			'Durch Aktivieren dieser Funktion wird ein "Bildung" Tab auf der Startseite des Tablets angezeigt. Dieser Tab bietet Patienten Zugang zu Bildungsressourcen, Videos, Artikeln und Anleitungen, die relevant für ihre Gesundheitszustände und Behandlungen sind.',
		hospitalAtHomeTakePicture: 'Ein Bild machen',
		takeAPictureDescription:
			'Durch Aktivieren dieser Funktion wird eine "Ein Bild machen" Option auf der Startseite des Tablets angezeigt. Diese Funktion ermöglicht es Patienten, Bilder von ihrem Zustand (wie Wunden oder Ausschläge) zu machen und sicher an ihren Gesundheitsdienstleister zur Bewertung zu senden.',
		hospitalAtHomeMedication: 'Medikation',
		medicationDescription:
			'Durch Aktivieren dieser Funktion wird ein "Medikation" Tab auf der Startseite des Tablets angezeigt. Dieser Tab hilft Patienten, ihre Medikamente zu verwalten, indem er Zeitpläne, Erinnerungen und detaillierte Informationen zu jedem Medikament bereitstellt, das sie einnehmen müssen.',
		hospitalAtHomeChat: 'Chat',
		chatHAHDescription:
			'Durch Aktivieren dieser Funktion wird ein "Chat" Tab auf der Startseite des Tablets angezeigt. Diese Funktion ermöglicht es Patienten, mit ihrem Pflegeteam über Textnachrichten zu kommunizieren.',
		backgroundAI: 'Hintergrund KI',
		customizeRoom: 'Raum anpassen',
		roomTypes: 'Zimmertypen',
		babyRoomTypeDescription: 'Wenn dies aktiviert ist, können Sie Babyzimmer als Zimmertyp auf Zimmer-Ebene festlegen.',
		frontDeskRoomTypeDescription: 'Wenn dies aktiviert ist, können Sie Empfangszimmer als Zimmertyp auf Zimmer-Ebene festlegen.',
		doctorRoomTypeDescription: 'Wenn dies aktiviert ist, können Sie Arztzimmer als Zimmertyp auf Zimmer-Ebene festlegen.',
		laboratoryRoomTypeDescription: 'Wenn dies aktiviert ist, können Sie Laborzimmer als Zimmertyp auf Zimmer-Ebene festlegen.',
		injectionRoomTypeDescription:
			'Wenn dies aktiviert ist, können Sie Injektionszimmer als Zimmertyp auf Zimmer-Ebene festlegen.',
		infusionRoomTypeDescription: 'Wenn dies aktiviert ist, können Sie Infusionszimmer als Zimmertyp auf Zimmer-Ebene festlegen.',
		digitalClinicRoomTypeDescription:
			'Wenn dies aktiviert ist, können Sie Digitalklinikzimmer als Zimmertyp auf Zimmer-Ebene festlegen.',
		pharmacyRoomTypeDescription: 'Wenn dies aktiviert ist, können Sie Apothekezimmer als Zimmertyp auf Zimmer-Ebene festlegen.',
		radiologyRoomTypeDescription:
			'Wenn dies aktiviert ist, können Sie Radiologiezimmer als Zimmertyp auf Zimmer-Ebene festlegen.',
		kioskRoomTypeDescription: 'Wenn dies aktiviert ist, können Sie Kioskzimmer als Zimmertyp auf Zimmer-Ebene festlegen.',
		triageRoomTypeDescription: 'Wenn dies aktiviert ist, können Sie Triagezimmer als Zimmertyp auf Zimmer-Ebene festlegen.',
		intakeForm: 'Aufnahmeformular',
		intakeFormDescription: 'Wenn dies aktiviert ist, können Anbieter Patienteninformationen sammeln.',
		doctorPoolingDescription:
			'Wenn diese Option aktiviert ist, wird auf dem Bildschirm die Liste der diesem Krankenhaus zugeordneten Ärzte angezeigt.',
		scpHealthForm: 'SCP-Gesundheitsformular',
		baycareForm: 'Baycare-Formular',
		aiWidgetDescription:
			'Wenn dies aktiviert ist, werden auf dem Bildschirm Widgets wie Vitalfunktionen, bevorstehende Termine, Pfleger anrufen, KI-Vitalfunktionen und Physiotherapie angezeigt.',
		widgetDescription:
			'Wenn diese Option aktiviert ist, werden auf dem Bildschirm Widgets wie Vitalfunktionen, bevorstehende Termine, Pfleger anrufen, Bildung und heutige Rezepte angezeigt.',
		digitalClinicDescription: `Wenn diese Option aktiviert ist, werden auf dem Bildschirm Informationen wie Datum, Zimmername, Name des Patienten und die neuesten Messungen des Patienten angezeigt.`,
		telemedicineDescription:
			'Wenn diese Option aktiviert ist, werden auf dem Bildschirm die Liste der verfügbaren Ärzte und die Art der Anrufe angezeigt, die Benutzer einleiten können.',
		hospitalDescription:
			'Wenn dies aktiviert ist, werden auf dem Bildschirm Widgets wie eine Liste mit Familie und Freunden, Apps, „Betreuer anrufen“, „Bildung“ und „Heute verordnete Rezepte“ angezeigt.',
		virtualExaminationDescription:
			'Wenn diese Option aktiviert ist, wird auf dem Bildschirm die Liste der verfügbaren Ärzte angezeigt, bei denen der Benutzer Anrufe einleiten kann.',
		simplifiedDescription: `Wenn diese Option aktiviert ist, werden auf dem Bildschirm Informationen wie Datum, Zimmername und Name des Patienten angezeigt.`,
		eVideonDescription:
			'Wenn diese Option aktiviert ist, werden auf dem Bildschirm interaktive Patientenversorgungslösungen angezeigt, die Patienten und Besucher einbeziehen, aufklären und unterhalten und gleichzeitig die Arbeitsabläufe für das Personal optimieren.',
		patientExperienceDescription:
			'Wenn diese Option aktiviert ist, werden auf dem Bildschirm interaktive Patientenversorgungslösungen angezeigt, die Patienten und Besucher einbeziehen, aufklären und unterhalten und gleichzeitig die Arbeitsabläufe für das Personal optimieren.',
		mdmDescription: 'Die MDM-Patientenerfahrungsplattform steigert die Patienteneinbindung im gesamten Versorgungskontinuum.',
		azureAd: 'Azure AD',
		duo: 'Dou',
		pingFederate: 'Ping Federate',
		okta: 'OKTA',
		hillRom: 'Hill-Rom',
		epic: 'Epic',
		cerner: 'Cerner',
		telehealth: 'Telehealth',
		evideon: 'eVideon',
		pcare: 'pCare',
		getwell: 'GetWell',
		sonifi: 'Sonifi',
		mdm: 'MDM',
		globo: 'Globo',
		amn: 'AMN',
		languageLine: 'LanguageLine',
		languageLineTypeDescription:
			'Wenn dies aktiviert ist, können Benutzer Inhalte und Kommunikation nahtlos in mehrere Sprachen übersetzen. Darüber hinaus wird die Option zur Festlegung von LanguageLine auf der Ebene des Gesundheitssystems verfügbar sein.',
		addUnitPhoneNumber: 'Fügen Sie die Telefonnummer der Einheit hinzu',
		unitPhoneNumber: 'Telefonnummer der Einheit',
		noIntegrationTypesAvailable: 'Keine Integrationstypen verfügbar',
		pleaseSetupIntegrationType:
			'Bitte richten Sie zunächst einen Integrationstyp ein. Wenden Sie sich an Ihren Dienstanbieter, um Hilfe zu erhalten.',
		errorVoiceOver:
			'Es ist ein Fehler aufgetreten beim Umschalten der "Bleiben Sie ruhig"-Meldung von {value}. Bitte versuchen Sie es erneut!',
		enableStayStill: '"Ruhemodus"-Warnung aktivieren',
		disableStayStill: '"Ruhemodus"-Warnung deaktivieren',
		stoppedAlarmPopup: 'Alarm wurde aufgrund der Antwort von {role} {value} gestoppt',
		activeAlarmTriggered: 'Aktiver Alarm ausgelöst durch',
		statAlarmStarted: 'Statischer Alarm wurde gestartet',
		statAlarmStopped: 'Statischer Alarm wurde gestoppt',
		noStatAlarmsActivated: 'Keine STAT-Alarme wurden aktiviert',
		mediaUsage: 'Mediennutzung',
		complete: 'Vollständig',
		hasStoppedStatAlarm: 'stoppte den Alarm',
		voiceOverAlarmStopped: 'Manuelle Warnung deaktiviert',
		voiceOverAlarmStarted: 'Manuelle Warnung aktiviert',
		whiteboard: 'Weißwandtafel',
		whiteboardType: 'Whiteboard-Typ',
		stoppedAlertPopup: 'Manueller Alarm wurde aufgrund der Antwort von {role} {value} gestoppt',
		selectAlarm: 'Wählen Sie Alarm',
		availableAlarms: 'Verfügbare Alarme',
		selectOtherAlarm: 'Anderen Alarm auswählen',
		alarm: 'Alarm',
		alarmCount: 'Alarm {value}',
		scheduled: 'Geplant',
		repeat: 'Wiederholen',
		weekly: 'Wöchentlich',
		monthly: 'Monatlich',
		selectSchedule: 'Wählen Sie den Zeitplantyp aus',
		ivBagFluidLevel: 'Flüssigkeitsstand im IV-Beutel',
		admissions: 'Aufnahmen',
		discharges: 'Entlassungen',
		admissionsAndDischarges: 'Aufnahmen und Entlassungen',
		avgAdmissionsPerHour: 'Durchschnittliche Aufnahmen pro Stunde',
		avgDischargesPerHour: 'Durchschnittliche Entlassungen pro Stunde',
		highestAdmissionsPerHour: 'Höchste Aufnahmen pro Stunde',
		highestDischargesPerHour: 'Höchste Entlassungen pro Stunde',
		ivBagDescription:
			'Wenn dies aktiviert ist, können Benutzer Echtzeitaktualisierungen des Flüssigkeitsstands im IV-Beutel sehen und werden benachrichtigt, wenn der Pegel niedrig ist.',
		monitoringHealthDataDescription:
			'Wenn dies aktiviert ist, sollte der Tab "Gesundheitsdaten" unter Alarmen im Monitoring angezeigt werden.',
		ivBagCheck: 'Der Flüssigkeitsstand in der IV-Tasche ist niedrig. Bitte überprüfen Sie die IV.',
		warningAlertAt: 'Warnung bei',
		enableIvBagWarning: 'Um diese Option zu aktivieren, musst du zuerst einen Warnwert angeben.',
		patientEngagement: 'Patienteneinbindung',
		nurseCallsSystems: '{roleNameNurse} Ruft Systeme',
		configurationTypeRequired: 'Konfigurationstyp ist erforderlich',
		externalTypeDescription:
			'Wenn dies aktiviert ist, kann es als globale Authentifizierungsinstanz fungieren, die es Kunden, Mitarbeitern und Partnern ermöglicht, von jedem Gerät aus sicher auf alle benötigten Anwendungen zuzugreifen.',
		ehrDescription:
			'Wenn dies aktiviert ist, können Benutzer auf eine einheitliche Patientenakte zugreifen, um die klinische Entscheidungsfindung zu verbessern und die Patientenversorgung zu verbessern.',
		translationTypeDescription:
			'Wenn diese Option aktiviert ist, können Benutzer Inhalte und Kommunikation nahtlos in mehrere Sprachen übersetzen.',
		doctorQueue: 'Arztwarteschlange',
		lowIvBagFluidLevel: 'Niedriger Flüssigkeitsstand im Infusionsbeutel',
		bedIdentifier: 'Bettkennung',
		enterEmailAddress: 'E-Mail Adresse eingeben',
		addDomain: 'Domain hinzufügen',
		enterEmailDomain: 'E-Mail-Domäne eingeben',
		familyMemberNotAssigned: 'Sie sind noch keinem Patienten zugeordnet!',
		dependants: 'Angehörige',
		cannotBeFamilyMember: 'Der Patient kann kein Familienmitglied für sich selbst sein',
		patientGettingOutOfChair: 'Das Aufstehen aus dem Stuhl wurde erkannt',
		personGettingOutOfChairMessage: 'Der Patient steht aus dem Stuhl auf',
		sessionsQueue: 'Sitzungswarteschlange',
		sessionsQueueDescription:
			'Wenn dies aktiviert ist, kann der Arzt eine Sitzungswarteschlange sehen, der er sich anschließen oder ablehnen kann.',
		patientIsInactiveToastMessage: `Es ist Zeit, den Patienten auf die andere Seite zu drehen. Bitte benachrichtigen Sie den {role} am Bett.`,
		forwardSomethings: 'Weiterleiten an {value}s',
		patientInactiveInformation: 'Es ist Zeit, den Patienten auf die andere Seite zu drehen.',
		careEventsForNursesDesc:
			'Patienteninterventionen wie Aufnahme, Beratung, Behandlung, Entlassung, Notfallalarm und Datenschutzmodus, die von {roleNameNurse} durchgeführt werden, werden registriert.',
		measuringIv: 'Messung des IV-Flüssigkeitsniveaus...',
		providerCallBackgrounds: 'Anruferhintergründe des Anbieters',
		backgroundImages: 'Hintergrundbilder',
		backgroundsGallery: 'Hintergrundgalerie',
		incomingCallBackgrounds: 'Hintergründe für eingehende Anrufe',
		helloIncomingCallBackgrounds: 'Hello-Hintergründe für eingehende Anrufe',
		uploadImagesProvidersUse:
			'Laden Sie Bilder hoch, die Anbieter als Hintergründe während laufender Anrufe mit Patienten verwenden können.',
		uploadImageOnIncomingCall:
			'Laden Sie ein Bild hoch, das für den Patienten sichtbar ist, wenn er einen eingehenden Anruf von einem Anbieter erhält.',
		helloWallpaperDesc:
			'Laden Sie ein Bild hoch, das auf dem Hello-Gerät angezeigt wird, wenn der Fernseher auf die Helo-Quelle eingestellt ist und das Gerät nicht in laufenden Sitzungen beschäftigt ist.',
		selectPreferences: 'Einstellungen auswählen',
		backgroundImage: 'Hintergrundbild',
		addImage: 'Bild hinzufügen',
		blurryBackgroundDefault: 'Unscharfen Hintergrund als Standard festlegen',
		blurryBackground: 'Verschwommener Hintergrund',
		enabled: 'Aktiviert',
		notEnabled: 'Nicht aktiviert',
		viewMedications: 'Medikamente anzeigen',
		todaysMedication: 'Heutige Medikamente',
		patientSkippedMedication: 'Der Patient hat Medikamente ausgelassen',
		notifyPatientForMedication: 'Weisen Sie den Patienten darauf hin, seine Medikamente einzunehmen',
		featureChanged: 'Funktion geändert',
		variant: 'Variante',
		changedForRole: 'Geändert für Rolle',
		skipped: 'Übersprungen',
		taken: 'Genommen',
		displayCaregiverName: 'Anzeige des Betreuersnamens',
		displayCaregiversNameDescription:
			'Wenn diese Option aktiviert ist, kann der Patient den vollständigen Namen (z.B. Linda Andreson) oder den Vornamen und den ersten Buchstaben des Nachnamens (z.B. Linda A.) des Pflegers sehen, der während des Anrufs zwischen Patient und Pfleger angezeigt wird. Wenn diese Option deaktiviert ist, kann der Patient weder den Namen noch irgendwelche Buchstaben der Pfleger sehen.',
		sessionMaintenance: 'Diese Sitzung wurde von der Wartung beendet.',
		exceededCharactersLimit:
			'Sie haben die maximal zulässige Zeichenanzahl überschritten. Bitte passen Sie an oder entfernen Sie überschüssige Notizen.',
		helloCareEngagement: 'Hellocare Engagement',
		homeWidget: 'Startseite',
		whiteboardURL: 'Whiteboard URL',
		engagementWhiteboardURLDescription:
			'Geben Sie die Whiteboard-URL ein, um auf die Funktionalität des digitalen Whiteboards von hellocare zuzugreifen',
		home: 'Startseite',
		engagementHomeDescription:
			'Patientenkommunikations- und Informationszentrum: Ein zentraler Hub, der die Kommunikation mit Patienten erleichtert und wichtige Updates bereitstellt. Es enthält eine interaktive Tafel für vielseitige Anwendungen.',
		educationWidget: 'Bildung',
		engagementEducationDescription:
			'Bildungsmaterial im Gesundheitswesen: Ein Repository für gesundheitsbezogene Bildungsvideos, die das Patientenwissen und die Beteiligung verbessern.',
		liveTvWidget: 'Live-Fernsehen',
		engagementLiveTVDescription:
			'Unterhaltungs- und Informationskanäle: Eine Auswahl verschiedener TV-Sender aus verschiedenen Genres, um sicherzustellen, dass Patienten Zugang zu Unterhaltungs- und Informationsmöglichkeiten haben.',
		moviesWidget: 'Filme',
		engagementMoviesDescription:
			'Filmbibliothek: Ein Katalog von Filmen aus verschiedenen Genres, die On-Demand-Unterhaltung bieten.',
		musicWidget: 'Musik',
		engagementMusicDescription:
			'Musikradiosender: Zugang zu Musikradiosendern, kategorisiert nach Genre, zur Entspannung und Freude der Patienten.',
		moodsWidget: 'Stimmungen',
		engagementMoodsDescription:
			'Entspannungsvideos: Eine Sammlung stimmungsaufhellender Videos, darunter Szenen wie Kamine und Strände, zur Förderung von Komfort und Wohlbefinden der Patienten.',
		appsWidget: 'Apps',
		engagementAppsDescription:
			'Unterhaltungsanwendungen: Integration mit beliebten Unterhaltungs-Apps wie Netflix, Amazon Prime und YouTube Music, um die Unterhaltungsmöglichkeiten für Patienten zu erweitern.',
		familyAndFriendsWidget: 'Familie & Freunde',
		engagementFamilyFriendsDescription:
			'Patientenvernetzung: Eine Funktion, die es Patienten ermöglicht, über Videotelefonie mit ihren Lieben in Verbindung zu bleiben und emotionale Unterstützung zu fördern.',
		orderMealsWidget: 'Mahlzeiten bestellen',
		engagementOrderMealDescription:
			'Bequeme Mahlzeitenauswahl: Eine praktische Option für Patienten, Mahlzeiten nach ihren Vorlieben zu bestellen und so ihr Esserlebnis zu verbessern.',
		whiteBoardWidget: 'Whiteboard',
		engagementWhiteboardDescription:
			'Interaktives Informationszentrum: Ein multifunktionales Whiteboard, das als interaktives Informationszentrum dient und Notizen, Inhaltsanzeige und Videointegration ermöglicht.',
		careTeamWidget: 'Pflegeteam',
		engagementCareTeamDescription:
			'Zugang zum Pflegeteam: Patienten können leicht auf ihre dedizierten Pflegeteammitglieder zugreifen und mit ihnen kommunizieren, um die Pflegekoordination zu optimieren.',
		healthDataWidget: 'Gesundheitsdaten',
		engagementHealthDataDescription:
			'Gesundheitsüberwachung und Einblicke: Zugang zu Gesundheitsdaten von verschiedenen Überwachungsgeräten und IoTs, um einen umfassenden Überblick über die Patientengesundheit zu erhalten.',
		sessionAborted: 'Die Sitzung wurde abgebrochen. Bitte versuchen Sie es erneut.',
		sessionDisrupted: 'Die Sitzung wurde unterbrochen. Bitte versuchen Sie es erneut.',
		perfect: 'Perfekt',
		good: 'Gut',
		bad: 'Schlecht',
		mediocre: 'Mittelmäßig',
		noCareEvents: 'Keine Pflegeereignisse verfügbar.',
		askAdministratorForCareEvents: 'Bitte fragen Sie Ihren Administrator, Pflegeereignisse hinzuzufügen.',
		providerCamera: 'Video deaktivieren',
		providerMic: 'Anbietermikrofon',
		providerMicDesc: 'Durch Aktivieren dessen wird das Mikrofon des Anbieters eingeschaltet, wenn der Anruf gestartet wird.',
		patientCamera: 'Kamera des Patienten',
		patientMicrophone: 'Mikrofon des Patienten',
		patientMicrophoneDesc:
			'Durch Aktivieren dessen wird das Mikrofon des Patienten eingeschaltet, wenn der Anruf gestartet wird.',
		disableProviderCameraDesc:
			'Durch Aktivieren dessen wird die Kamera des Anbieters eingeschaltet, wenn der Anruf gestartet wird.',
		patientMicDesc: 'Durch Aktivieren dessen wird das Mikrofon des Patienten eingeschaltet, wenn der Anruf gestartet wird.',
		patientCameraDesc: 'Durch Aktivieren dessen wird die Kamera des Patienten eingeschaltet, wenn der Anruf gestartet wird.',
		enableLiveCaptions: 'Live-Untertitel aktivieren',
		disableLiveCaptions: 'Live-Untertitel deaktivieren',
		closedCaptions: 'Untertitel',
		liveCaptionsDescription:
			'Wenn dies aktiviert ist, kann die {value} Live-Untertitel des Teilnehmers sehen, der gerade spricht.',
		participantRemovedByAdministrator: 'Sie wurden von einem Administrator aus dieser Konferenz entfernt.',
		wantToTurnOnFallPrevention: 'Möchten Sie die Sturzprävention durch KI im Monitoring aktivieren?',
		continueNoAi: 'Nein, weiter ohne KI',
		aiMonitoringOn: 'KI-Überwachung EINSCHALTEN',
		aiFallPrevention: 'KI-Sturzprävention',
		helloWallpaper: 'Hallo Tapete',
		addCareEvent: 'Pflegeereignis hinzufügen',
		nameOrEmail: 'Name oder E-Mail',
		careEventHistory: 'Pflegeereignisverlauf',
		talkToPatientSettings: 'Einstellungen für Gespräche mit dem Patienten',
		talkToPatientSettingsDesc:
			'Verwenden Sie die unten stehenden Optionen, um festzulegen, was der Anbieter und der Patient sehen und hören werden, wenn sie einen Anruf mit dem Hallo-Gerät tätigen oder erhalten.',
		callSettingsForRoomSignDesc:
			'Verwenden Sie die untenstehenden Optionen, um festzulegen, was Anbieter und Patient sehen und hören, wenn sie einen Anruf von oder zur Raumkennzeichnung tätigen.',
		viewPatientSettings: 'Patienteneinstellungen anzeigen',
		viewPatientSettingsDesc:
			'Verwenden Sie diese Option, um die Option Patient anzeigen auf der Anbieterseite anzuzeigen oder auszublenden.',
		videoMonitoring: 'Videoüberwachung',
		ambientMonitoring: 'Umgebungsüberwachung',
		ambientAndVideoMonitoring: 'Umgebungs- und Videoüberwachung',
		ambientMonitoringDescription: 'Wenn dies aktiviert ist, kann der Anbieter Benachrichtigungen empfangen.',
		unoccupied: 'Unbesetztes',
		ambientMonitoringNotActive: 'Umgebungsüberwachung Nicht Aktiv',
		receiveAlerts: 'Um Benachrichtigungen zu erhalten,',
		inOrderToReceiveAlerts: 'um Benachrichtigungen zu erhalten.',
		alert: 'Warnung',
		manageAlert: 'Warnung Verwalten',
		closeAlert: 'Warnung Schließen',
		selectViewOrAddRooms:
			'Wählen Sie eine der gespeicherten Ansichten aus oder fügen Sie zunächst Räume zur aktuellen Ansicht hinzu.',
		maxAmbientMonitoringProviders: 'Dieses Gerät hat die maximale Anzahl zulässiger Umgebungsüberwachungsanbieter erreicht.',
		exportRebootDeviceLogs: 'Dieses Gerät hat noch keine Neustartprotokolle.',
		logs: 'Protokolle',
		removeParticipant: 'Teilnehmer entfernen',
		confirmParticipantRemoval: 'Bestätige die Entfernung des Teilnehmers',
		confirmationOfRemovalParticipant: 'Sind Sie sicher, dass Sie {value} aus dem Anruf entfernen möchten?',
		youAreRemovedFromConference: 'Sie wurden von dieser Konferenz entfernt.',
		helloSpeakerSelected: 'Der interne Lautsprecher des Hello-Geräts ist ausgewählt',
		tvSpeakerSelected: 'Der Fernsehlautsprecher ist ausgewählt',
		switchToTv: 'Wechseln zum Fernsehlautsprecher',
		switchToHello: 'Wechseln zum Lautsprecher des Hello-Geräts',
		viewing: 'Betrachtung',
		fetchingPrecautionsFailed: 'Das Abrufen von Vorsichtsmaßnahmen ist fehlgeschlagen.',
		selectCareEvent: 'Um Notizen zu aktivieren, wählen Sie ein Pflegeereignis aus',
		supportChat: 'Support-Chat',
		supportChatDescription: 'Wenn dies aktiviert ist, haben die Benutzer den Support-Chat innerhalb der App verfügbar.',
		prefix: 'Präfix',
		displayFullName: 'Anzeige des vollständigen Namens',
		displayCredentialsName: 'Anzeigen des Vornamens, des ersten Buchstabens des Nachnamens und der Anmeldeinformationen',
		deleteConfirmation: 'Löschbestätigung',
		failedToGetCaptions: 'Fehler beim Abrufen der Untertitel! Bitte versuchen Sie es in einem Moment erneut.',
		careEventsDashboard: 'Dashboard für Pflegeereignisse',
		created: 'Erstellt',
		departmentFloorRoom: 'Abteilung > Etage > Raum',
		careEventType: 'Art des Pflegeereignisses',
		admission: 'Zulassung',
		discharge: 'Entladung',
		allEvents: 'Alle Veranstaltungen',
		myCareEvents: 'Meine Pflegeveranstaltungen',
		selectCareTeam: 'Wählen Sie Pflegeteam',
		assignCareTeam: 'Weisen Sie das Pflegeteam zu',
		newCareEvent: 'Neues Care-Event',
		roomCannotBeEmpty: 'Der Raum darf nicht leer sein',
		searchRoomName: 'Nach Raumnamen suchen',
		careEventsDescription: 'Wenn dies aktiviert ist, kann der {value} Pflegeereignisse sehen.',
		statusAlreadyExists: 'Dieser Status existiert bereits!',
		patientCannotBeEmpty: 'Der Patient darf nicht leer sein',
		somethingCannotBeEmpty: '{value} darf nicht leer sein',
		addPatientWithEvent:
			'Weisen Sie einen bestehenden oder neuen Patienten einem Raum zu, um das Pflegeereignis mit seiner Akte zu verknüpfen.',
		selectExistingPatientWithEvent:
			'Wählen Sie einen Patienten aus, um ein Pflegeereignis hinzuzufügen, und weisen Sie ein Mitglied des Pflegeteams zu, es als Aufgabe zu verwalten.',
		searchPatientByMrn: 'Suchen Sie den Patienten nach Name oder MRN',
		aIDetectionDrawings: 'AI-Erkennungsskizzen',
		removeRoomMonitoring: 'Möchten Sie den Raum {value} entfernen?',
		sureRemoveRoomMonitoring:
			'Bitte bestätigen Sie, dass Sie diesen Raum aus der aktuellen Überwachungssitzung entfernen möchten.',
		removeSessionMonitoring: 'Möchten Sie die aktuelle Sitzung schließen?',
		sureRemoveSessionMonitoring: 'Bitte bestätigen Sie, dass Sie die aktuelle Sitzung schließen möchten.',
		interventions: 'Interventionen',
		addInterventions: 'Interventionen hinzufügen',
		interventionsHistory: 'Interventionsgeschichte',
		newInterventions: 'Neue Interventionen',
		noInterventions: 'Keine Interventionen verfügbar',
		askAdministratorForInterventions:
			'Bitte fragen Sie Ihren Administrator, um eine Liste von Interventionen zur Auswahl zu erstellen.',
		selectInterventions: 'Wählen Sie eine Option aus, um zusätzliche Notizen hinzuzufügen.',
		aditionalNotes: 'Zusätzliche Notizen',
		addAdditionalNotes: 'Zusätzliche Notizen hinzufügen',
		eventHistory: 'Ereignisverlauf',
		eventCompleted: '{value} abgeschlossen',
		eventCreated: '{value} erstellt',
		statusUpdated: 'Status aktualisiert',
		youUpdatedStatus: 'Sie haben den Status eines Ereignisses aktualisiert',
		describeChange: 'Beschreiben Sie, warum Sie diese Änderung vorgenommen haben',
		yourInsightsHelp:
			'Ihre Erkenntnisse helfen uns, genaue Aufzeichnungen zu führen und die beste Pflege für alle Beteiligten sicherzustellen',
		youReassignedCareMember: 'Sie haben ein Pflegeteam einer Veranstaltung neu zugewiesen',
		reassigned: 'Neu zugewiesen',
		assignedCareTeam: 'Zugewiesenes Pflegeteam',
		patientIsAtRoom: 'Der Patient ist im Zimmer',
		sameExerciseSameDay: 'Dieselbe Übung am selben Tag ist bereits erstellt!',
		youUpdatedPriority: 'Sie haben den Priorität eines Ereignisses aktualisiert',
		priorityUpdated: 'Priorität aktualisiert',
		selectStatus: 'Status auswählen',
		clearAll: 'Alles löschen',
		filters: 'Filter',
		dateRange: 'Datumsbereich',
		selectWithinDateRange: 'Der Datumsbereich darf nicht mehr als {value} Tage umfassen',
		selectValidDateRange: 'Bitte wählen Sie einen gültigen Datumsbereich aus!',
		selectValidEndTime: 'Bitte wählen Sie eine gültige Endzeit!',
		patientPlacement: 'Patientenplatzierung',
		skipSslVerification: 'SSL-Verifikation überspringen',
		mrnNoMatch: 'Die medizinische Patientennummer (MRN) stimmt nicht mit unseren Aufzeichnungen überein.',
		healthAlerts: 'Gesundheitswarnungen',
		aiAlerts: 'AI-Warnungen',
		activeAlerts: 'Aktive Warnungen',
		showAll: 'Zeige alles',
		showLess: 'Zeige weniger',
		allHospitals: 'Alle Krankenhäuser',
		allDepartments: 'Alle Abteilungen',
		allFloors: 'Alle Etagen',
		allRooms: 'Alle Räume',
		aiSettings: 'KI-Einstellungen',
		dataCollectionAlerts: 'Datenübernahme für falsche Alarme zulassen',
		dataCollectionAlertsDescription:
			'Diese Option ermöglicht unserem System, Bilder zu erhalten, falls ein falscher Alarm gemeldet wird.',
		patientNotes: 'Patientennotizen',
		addNotes: 'Notizen zum Patienten hinzufügen',
		enterPatientName: 'Patientennamen eingeben',
		patientAge: 'Patientenalter',
		enterPatientAge: 'Patientenalter eingeben',
		nurseNameNumber: 'KR - Name/Nummer',
		enterNurseNameNnumber: 'KR - Name/Nummer eingeben',
		nurseAssistantNameNumber: 'Pflegeassistent - Name/Nummer',
		enterNurseAssistantNameNumber: 'Pflegeassistent - Name/Nummer eingeben',
		tlNameNumber: 'TL - Name/Nummer',
		enterTlNameNumber: 'TL - Name/Nummer eingeben',
		primaryAdverseEvent: 'Hauptunerwünschtes Ereignis',
		enterPrimaryAdverseEvent: 'Hauptunerwünschtes Ereignis eingeben',
		primaryPatientCondition: 'Primärer Patientenzustand',
		enterPrimaryPatientCondition: 'Primärer Patientenzustand eingeben',
		primaryPatientRiskFactor: 'Primärer Patientenrisikofaktor',
		enterPrimaryPatientRiskFactor: 'Primärer Patientenrisikofaktor eingeben',
		titleBarNotes: 'Zusätzliche Titelleistennotizen',
		enterTitleBarNotes: 'Zusätzliche Titelleistennotizen eingeben',
		additionalPatientNotes: 'Zusätzliche Patientennotizen',
		enterAdditionalPatientNotes: 'Zusätzliche Patientennotizen eingeben',
		enterPatientInformation: 'Patienteninformationen eingeben',
		voiceAnnouncements: 'Verbale Umleitung',
		patientPrivacy: 'Patientenprivatsphäre',
		patientOutOfRoom: 'Patient außerhalb des Zimmers',
		pleaseDoNotGetUp: 'Achtung: Bitte nicht aufstehen!',
		pleaseDoNotTouchThat: 'Bitte nicht berühren!',
		pleaseStopWhatYouAreDoing: 'Achtung: Bitte stoppen Sie, was Sie tun!',
		stopPlease: 'Bitte stoppen!',
		useYourCallLight: 'Bitte benutzen Sie Ihr Ruflicht.',
		staffInjury: 'Personalverletzung',
		isolationExposure: 'Isolationsexposition',
		medicalDeviceInterference: 'Störung des medizinischen Geräts',
		elopement: 'Entweichen ,',
		intentionalSelfHarm: 'Absichtliche Selbstverletzung',
		suicide: 'Selbstmord',
		delirium: 'Delirium',
		dementia: 'Demenz',
		substanceWithdrawal: 'Substanzentzug',
		brianInjury: 'Hirnverletzung',
		psychiatricDisorder: 'Psychische Störung',
		stroke: 'Schlaganfall',
		infectiousDiseaseIsolation: 'Isolation bei infektiösen Krankheiten',
		poorShortTermMemory: 'Schlechtes Kurzzeitgedächtnis',
		poorComprehension: 'Schlechtes Verständnis',
		impulsivity: 'Impulsivität',
		agitation: 'Agitation',
		anxiety: 'Angst',
		distortedPerceptionOfReality: 'Verzerrte Wahrnehmung der Realität',
		angerFrustration: 'Wut/Frust',
		none: 'Keine',
		patientIsOutOfTheRoom: 'Patient ist außerhalb des Zimmers',
		surgery: 'Operation',
		patientisGoingDownForACTScan: 'Patient geht für eine CT-Untersuchung',
		patientIsGoingDownForAnMRI: 'Patient geht für eine MRT-Untersuchung',
		ambulating: 'Ambulation',
		paracentesis: 'Parazentese',
		sunshinePrivileges: 'Sonnenschein-Privilegien',
		transferring: 'Übertragung',
		erCp: 'ER CP',
		dialysis: 'Dialyse',
		mandarinChinese: 'Mandarin Chinesisch',
		patientReturnedToRoom: 'Patient ist zurück im Zimmer',
		roomWithoutPatientData: 'In diesem Zimmer sind keine Patientendaten vorhanden.',
		fitToScreenVideoFeeds: 'An den Bildschirm anpassen Video-Feeds',
		isIntervening: 'greift ein',
		savingImage: 'Bild speichern',
		imageSaved: 'Bild gespeichert!',
		savingImageFailed: 'Bild konnte nicht gespeichert werden!',
		helloDevice: 'Hello Gerät',
		systemExecutedActions: 'Vom System ausgeführte Aktionen',
		digitalSign: 'Digitales Zeichen',
		aiConfigurations: 'KI-Konfigurationen',
		railsDetection: 'Schienenerkennung',
		statAlarmActivation: 'Stat-Alarmaktivierung bei AI-Warnungen',
		toastMessage: 'Toast-Nachricht',
		alertOnMonitoringFeed: 'Warnung zum Monitoring-Feed',
		soundOnAiAlert: 'Ton bei KI-Alarm',
		drafts: 'Entwürfe',
		forward: 'Forward',
		interventionsAndAlerts: 'Interventions and Alerts',
		monitorPatient: 'Den Patienten überwachen',
		patientAudio: 'Patienten-Audio',
		allowPatientAudio:
			'Browser erfordern eine Benutzerinteraktion, bevor sie Audio abspielen können. Klicken Sie einfach auf „OK“, um fortzufahren.',
		allow: 'Erlauben',
		transferOwnership: 'Eigentum übertragen',
		transferOwnershipDescription:
			'Wenn Sie den Anruf verlassen, wird die Eigentümerschaft auf einen anderen Teilnehmer übertragen und der Anruf nicht beendet.',
		leaveCall: 'Anruf verlassen',
		directCallEcm: 'Direkter Anruf von eCM',
		activeCareEvent: 'Für den aktuellen Patienten des ausgewählten Zimmers liegt ein aktives Pflegeereignis vor!',
		setTimeZone: 'Zeitzone einstellen',
		selectedTimeZone: 'Ausgewählte Zeitzone',
		searchPlaceTimeZone: 'Ort oder Zeitzone suchen',
		updateCareEvents:
			'Für die Pflegeveranstaltungen stehen neue Updates zur Verfügung. Klicken Sie zum Aktualisieren auf die Schaltfläche rechts',
		updateList: 'Aktualisierungsliste',
		host: 'Host',
		path: 'Pfad',
		isSubscribed: 'Ist abonniert',
		multipleBedRoom: 'Mehrbettzimmer',
		multipleBedRoomDesc:
			'Dies wird die Sicht von der Besucherseite einschränken. Dies sollte über dem Zimmertyp hinzugefügt werden.',
		careTeamReport: 'Bericht des Pflegeteams',
		patientReport: 'Patientenbericht',
		head: 'Kopf',
		rail: 'Schiene',
		foot: 'Fuß',
		admittedDate: 'Aufnahmedatum',
		dischargedDate: 'Entlassungsdatum',
		viewSessions: 'Sitzungen anzeigen',
		endTime: 'Endzeit',
		intervention: 'Intervention',
		actionTaken: 'Getroffene Maßnahme',
		interventedBy: 'Interveniert von',
		hidePatientProfile: 'Patientenprofil ausblenden',
		showPatientProfile: 'Patientenprofil anzeigen',
		nameNumber: 'Name/Nummer',
		additionalTitleBarNotes: 'Zusätzliche Notizen in der Titelleiste',
		removeFeed: 'Überwachungsfeed entfernen',
		turnVideo: 'Video anzeigen',
		turnVideoOn: 'Video einschalten',
		sessionType: 'Sitzungstyp',
		voiceAnnouncementsDescription:
			'Die Aktivierung der Verbalen Umleitung wird diese als Funktion innerhalb des Monitorings hinzufügen, wo Anbieter sie nutzen können, um mit dem Patienten zu kommunizieren, indem sie es einfach als vorab aufgenommenen Ton abspielen.',
		patientOutOfRoomDescription:
			'Die Aktivierung von Patient außerhalb des Raums wird dies als Funktion innerhalb des Monitorings hinzufügen, wo Anbieter Fälle dokumentieren können, in denen der Patient aus bestimmten Gründen (die in einem Dropdown-Menü angegeben sind) nicht im Raum ist.',
		fullySilentMode: 'Stummmodus',
		cameraSettings: 'Kameraeinstellungen',
		cameraUse: 'Verwendung der Kamera',
		selectOneOfCameraSettings: 'Wählen Sie eine der Kameraeinstellungen aus',
		singleCameraUse: 'Verwendung mit einer einzigen Kamera',
		multiCameraUse: 'Verwendung mit mehreren Kameras',
		genericDescription:
			'Eine generische Umfrage ist für Patienten konzipiert, die sie nach Belieben oder wann immer sie es für notwendig halten, ausfüllen können. Diese Umfrage ist für Patienten auf ihrer Homepage zugänglich.',
		safetyRangeDescription:
			'Die Umfrage zu den Sicherheitsbereichen wird von Patienten erstellt, die sie immer dann ausfüllen können, wenn ihre Messungen die festgelegten Sicherheitsgrenzen für ein bestimmtes Vitalzeichen überschreiten.',
		scheduledDescription:
			'Geplante Untersuchungen sind so strukturiert, dass sie als monatliche, wöchentliche oder tägliche Kontrolluntersuchungen für den Patienten dienen.',
		snoozeTimerForAlert: 'Schlummerzeit für {value} Warnung',
		snoozeTimer: 'Schlummer-Timer',
		primaryPatientRisk: 'Primäres Patientenrisiko',
		sessionByTime: 'Sitzung nach Zeit',
		totalHours: 'Gesamtstunden',
		transferPatient: 'Patientenübertragung',
		openLifeSignals: 'Offen LifeSignals',
		isOldUserExperience: 'Ist Alte Benutzererfahrung',
		isEnabledForHs: 'Ist Aktiviert für Gesundheitssysteme',
		isEnabledForHsDescription:
			'Wenn dies aktiviert ist, können die Administratoren von Gesundheitssystemen das "Ist alte Benutzererfahrung" Feature Flag ändern.',
		isOldUserExperienceDescription:
			'Wenn dies aktiviert ist, wird die Benutzererfahrung des Unternehmens oder des Gesundheitssystems die alte sein.',
		userExperience: 'Benutzererfahrung',
		mixedMonitoringView: 'Gemischte Überwachungsansicht (Video und Umgebung)',
		mixedMonitoringViewDescription:
			"Wenn dies aktiviert ist, werden Anbieter 'Ambient-Überwachung' als zusätzliche Fähigkeit/Tab innerhalb der Baumansicht sehen und so die gemischte Überwachungsansicht (Videoübertragung + Ambient-Überwachung) ermöglichen.",
		invitationNote: 'Einladungsnotiz',
		invitationNotePlaceholder: 'Fügen Sie eine Einladungsnotiz hinzu, die der Gast sehen kann.',
		invitationNoteDescription: 'Alle Teilnehmer sehen diesen Hinweis. Maximal 160 Zeichen.',
		invitationNoteMinLength: 'Die Einladungshinweis muss mindestens 2 Zeichen enthalten',
		pinLock: 'Pin Sperre',
		disablePIN: 'Durch die Deaktivierung der PIN-Sperre wird diese beim Zugriff auf die Einstellungen nicht benötigt.',
		pinMinValue: 'Die PIN muss mindestens 1000 sein',
		changePin: 'PIN ändern',
		disablePinBtn: 'PIN deaktivieren',
		disablePinLock: 'Deaktivieren Sie die Pin-Sperre',
		newPinLock: 'Neue PIN-Sperre',
		searchByPatientNameMrn: 'Suche nach Patientennamen oder MRN',
		talkToPatientDescription: 'Wenn diese Option aktiviert ist, kann der Benutzer mit dem Patienten sprechen',
		viewPatientDescription: 'Wenn dies aktiviert ist, kann der Benutzer den Patienten anzeigen',
		checkFeatureAvailability: 'Bitte wenden Sie sich an Ihren Administrator, um diese Funktion verfügbar zu machen',
		badgeId: 'Badge-ID',
		hillRomId: 'Hill Rom-ID',
		userPicture: 'Benutzerbild',
		badgeAuthorization: 'Badge-Autorisierung',
		hl7v2: 'HL7 v2',
		hl7v2Description: 'Wenn dies aktiviert ist, wird die Bettenbeschreibung mit den erforderlichen Abschnitten angezeigt.',
		enablingPrivacyMode:
			'Durch Aktivierung dieser Funktion wird Ihre Patientenansicht für die ausgewählte Zeit unscharf. Bitte geben Sie die Zeit ein, für die Sie Ihren Bildschirm unscharf machen möchten, oder wählen Sie aus den vordefinierten Optionen.',
		enterSelectTimer: 'Geben Sie ein oder wählen Sie den Timer in Minuten.',
		writeWholeNumber: 'Bitte geben Sie eine ganze Zahl zwischen 1 und 60 ein.',
		isActive: 'Ist Aktiv',
		badgeStatus: 'Badge-Status',
		badgeDeleteMsg: 'Sind Sie sicher, dass Sie diesen Mitarbeiter deaktivieren möchten?',
		aspectRatio: 'Seitenverhältnis',
		badgingAccess: 'Zugang mit Badge',
		centrakBadge: 'Centrak-Badge',
		proxBadge: 'Prox-Badge',
		overviewTab: 'Wenn dies aktiviert ist, kann {value} die Übersicht des Patienten sehen.',
		healthTab: 'Wenn dies aktiviert ist, kann {value} die Gesundheit des Patienten sehen.',
		physicalTherapyTab: 'Wenn dies aktiviert ist, kann {value} die Physiotherapien des Patienten sehen.',
		visitTab: 'Wenn dies aktiviert ist, kann {value} die Besuche des Patienten sehen.',
		alertHistoryTab: 'Wenn dies aktiviert ist, kann {value} die Alarmhistorie des Patienten sehen.',
		transfer: 'Überweisen',
		activate: 'Aktivieren',
		moreParticipants: 'Mehr teilnehmer',
		clickToViewParticipants: 'Klicken Sie, um teilnehmer anzuzeigen',
		currentlyInThisCall: 'Derzeit in diesem anruf ',
		regeneratePin: 'PIN erneuern',
		hide: 'Verstecken',
		pca: 'PCA',
		roomInfo: 'Zimmerinformation',
		pain: 'Schmerz',
		painLevel: 'Schmerzstufe',
		manageablePainLevel: 'Erträgliche Schmerzstufe',
		roomServiceNumber: 'Zimmerservice #',
		isolations: 'Isolationen',
		patientInfoDescription: 'Wenn dies aktiviert ist, sieht {roleNameNurse} "Patienteninfo" als Feld in der Raumsicht.',
		monitoringWhiteboardDescription:
			'Wenn dies aktiviert ist, sehen Anbieter „Whiteboard“ als Kästchen in der Raumansicht und können das Whiteboard des Patienten über die Überwachung oder Umgebungsüberwachung aktualisieren.',
		listOfParticipants: 'Liste der Teilnehmer',
		participant: 'Teilnehmer',
		userAutoGeneratedPin: 'Automatisch Generierte PIN',
		autoGeneratedPinDesc:
			'Wenn dies aktiviert ist, wird eine vom System automatisch generierte PIN unter Benutzerverwaltung verfügbar sein, um mit dem Whiteboard zu interagieren.',
		podcastsWidget: 'Podcasts',
		podcastsWidgetDescription: 'Podcast-Bibliothek: Ein Katalog von Podcasts, die verschiedene Themen abdecken.',
		gamesWidget: 'Spiele',
		gamesWidgetDescription: 'Spiele: Eine Liste verschiedener Spiele-Apps.',
		hdmiInWidget: 'HDMI-IN',
		hdmiInWidgetDescription: 'HDMI-IN: Inhalte von der Set-Top-Box oder anderen Streaming-Geräten abspielen.',
		roomControlsWidget: 'Raumsteuerungen',
		roomControlsWidgetDescription: 'Raumsteuerung: Ermöglicht dem Patienten die Nutzung der Raumsteuerungen.',
		settingsWidget: 'Einstellungen',
		settingsWidgetDescription: 'Einstellungen: Optionen zur Anpassung Ihres Whiteboard-Erlebnisses.',
		whiteboardPiPWidget: 'Whiteboard PiP',
		whiteboardPiPWidgetDescription:
			'Whiteboard PiP: Wiedergabe einer Auswahl verschiedener Fernsehkanäle quer durch die Genres im Bild-in-Bild-Format.',
		transferOwnershipMessage: 'Sie sind jetzt der Besitzer dieses Anrufs.',
		lying: 'Hinlegen',
		goingHome: 'Heimgehen',
		rideConfirmed: 'Ist Fahrt Bestätigt',
		healthSummary: 'Gesundheitszusammenfassung',
		diet: 'Diät',
		bradenScore: 'Braden-Skala',
		sensoryPerceptions: 'Sinneswahrnehmungen',
		skinMoisture: 'Hautfeuchtigkeit',
		mobility: 'Mobilität',
		nutrition: 'Ernährung',
		frictionShear: 'Reibung & Scherkräfte',
		within24Hours: 'Innerhalb von 24 Stunden',
		is2or3days: '2-3 Tage',
		more3days: 'Mehr als 3 Tage',
		rideIsConfirmed: 'Die Fahrt ist bestätigt',
		rideIsNotConfirmed: 'Die Fahrt ist nicht bestätigt',
		familyContact: 'Familienkontakt',
		pcaPhoneNumber: 'PCA-Telefonnummer',
		somethingPhoneNumber: '{value} Telefonnummer',
		completelyLimitedLabel: 'Völlig eingeschränkt',
		completelyLimited:
			'Völlig eingeschränkt: reagiert nicht (stöhnt nicht, zuckt nicht zusammen oder greift nicht) auf Druck. Begrenzte Fähigkeit, Schmerzen über den größten Teil des Körpers zu fühlen',
		veryLimitedLabel: 'Sehr eingeschränkt',
		veryLimited: 'Sehr eingeschränkt: Reagiert nur auf schmerzhafte Reize. Kann das Unbehagen nicht richtig kommunizieren',
		slightlyLimitedLabel: 'Leicht eingeschränkt',
		slightlyLimited: 'Leicht eingeschränkt: reagiert auf verbale Befehle, fühlt aber Unbehagen in 1 oder 2 Extremitäten',
		noImpairmentLabel: 'Keine Beeinträchtigung',
		noImpairment: 'Keine Beeinträchtigung: reagiert auf verbale Befehle, hat kein sensorisches Defizit',
		constantlyMoistDesc: 'Ständig feucht: Die Haut wird fast ständig feucht gehalten',
		constantlyMoist: 'Ständig feucht',
		veryMoistDesc: 'Sehr feucht: Die Haut ist oft feucht',
		veryMoist: 'Sehr feucht',
		occasionallyMoistDesc:
			'Gelegentlich feucht: Die Haut ist gelegentlich feucht und erfordert einen Wäschewechsel einmal täglich',
		occasionallyMoist: 'Gelegentlich feucht',
		rarelyMoistDesc: 'Selten feucht: Die Haut ist trocken',
		rarelyMoist: 'Selten feucht',
		bedfastDesc: 'Bettlägerig: ans Bett gefesselt',
		bedfast: 'Bettlägerig',
		chairfastDesc: 'Sesselfast: Der Patient kann stark eingeschränkt gehen, kann aber sein eigenes Gewicht nicht tragen',
		chairfast: 'Sesselfast',
		walksOccasionallyDesc: 'Geht gelegentlich spazieren: kann sehr kurze Strecken zurücklegen',
		walksOccasionally: 'Geht gelegentlich spazieren',
		walksFrequentlyDesc: 'Geht häufig: Geht mindestens zweimal am Tag außerhalb des Zimmers und kann ohne Hilfe gehen',
		walksFrequently: 'Geht häufig',
		completelyImmobileDesc: 'Völlig unbeweglich: kann nicht einmal leichte Bewegungen machen',
		completelyImmobile: 'Völlig unbeweglich',
		veryLimitedDesc:
			'Sehr eingeschränkt: kann gelegentlich die Körperhaltung ändern, kann aber keine wesentlichen Änderungen selbstständig vornehmen',
		veryLimitedTitle: 'Sehr eingeschränkt',
		slightlyLimitedDesc:
			'Leicht eingeschränkt: führt selbstständig Körperveränderungen durch, hat aber nicht die Kraft, dies häufig zu tun',
		slightlyLimitedTitle: 'Leicht eingeschränkt',
		noLimitationDesc: 'Keine Einschränkung: Der Patient kann seine Position ohne Hilfe ändern',
		noLimitation: 'Keine Einschränkung',
		veryPoorDesc:
			'Sehr schlecht: Der Patient isst nie eine vollständige Mahlzeit. Nimmt Flüssigkeit schlecht auf und kann nicht mehr als ⅓ der Nahrung zu sich nehmen',
		probablyInadequateDesc: 'Wahrscheinlich unzureichend: Der Patient isst nur etwa die Hälfte der angebotenen Nahrung',
		probablyInadequate: 'Wahrscheinlich unzureichend',
		adequateDesc: 'Ausreichend: Kann nicht die ganze Mahlzeit essen',
		adequate: 'Ausreichend',
		excellentDesc: 'Ausgezeichnet: Der Patient isst normalerweise insgesamt 4 oder mehr Portionen',
		problemDesc: 'Problem: Heben ohne Gleiten gegen Bleche ist unmöglich',
		problem: 'Problem',
		potentialProblemDesc:
			'Potenzielles Problem: Der Patient benötigt nur minimale Unterstützung, um Reibung und Scherung zu minimieren',
		potentialProblem: 'Potenzielles Problem',
		noApparentProblemDesc:
			'Kein offensichtliches Problem: die Fähigkeit, sich unabhängig voneinander im Bett und im Stuhl zu bewegen. Fähigkeit, eine gute Position im Bett und im Stuhl beizubehalten.',
		noApparentProblem: 'Kein offensichtliches Problem',
		rateCallQuality: 'Bewerte die Anrufqualität',
		rateCallQualityDesc: 'Dies ermöglicht es den Teilnehmern, am Ende des Anrufs Feedback zu geben.',
		veryPoor: 'Sehr schlecht',
		poor: 'Schlecht',
		veryGood: 'Sehr gut',
		excellent: 'Ausgezeichnet',
		howWasQuality: 'Wie war die Anrufqualität?',
		rateCall: 'Anruf bewerten',
		roundingCareEvents: 'Runden von Pflegeereignissen',
		roundingCareEventsDesc:
			'Durch Aktivieren dieser Option können Sie den Grund des Anrufs vor dem Rundruf oder während des Anrufs festlegen.',
		excludeCpmPatients: ' CPM-Patienten ausschließen',
		removeFromRpm: 'Aus RPM entfernen',
		removePatient: 'Patienten entfernen',
		areYouSureRemovePatient: 'Möchten Sie den Patienten wirklich aus dem RPM-Programm entfernen?',
		makeHost: 'Gastgeber machen',
		noPinGenerate: 'Sie haben keine bestehende PIN. Bitte erstellen Sie eine.',
		missedCallsNotifications: 'Benachrichtigungen über verpasste Anrufe',
		missedCallFrom: 'Verpasster Anruf von',
		questionProvider: 'Frage an Ihren Anbieter',
		safetyPatientNeeds: 'Sicherheitswarnungen/Patientenbedürfnisse',
		newMedicationAndSideEffects: 'Neue Medikamente und Nebenwirkungen',
		whatMattersToMe: 'Was mir Wichtig ist',
		planForToday: 'Plan für Heute',
		painMedAvailable: 'Schmerzmedikament verfügbar',
		lastDoseGiven: 'Letzte gegebene Dosis',
		nextDose: 'Nächste Dosis',
		generatePin: 'PIN erneuern',
		aiDetectionDrawingsDescription:
			'Wenn dies aktiviert ist, wird der Betreuer Zeichnungen um Personen und Objekte sehen, die vom AI-Modell erkannt wurden.',
		soundOnAiAlertDescription:
			'Wenn dies aktiviert ist, wird der Betreuer bei Auslösung eines KI-Alarms einen Alarmton hören. Der Ton wird auf dem Gerät abgespielt, das sie zur Überwachung der Patienten verwenden.',
		caseManager: 'Fallmanager',
		caseManagerPhone: 'Telefon des Fallmanagers',
		showStats: 'Statistik anzeigen',
		mrn: 'MRN',
		sharedView: 'Geteilte Ansicht',
		sharedBy: 'Geteilt von {value1} {value2}',
		logisticsDescription: 'Wenn dies aktiviert ist, kann {value} den Logistik-Tab sehen und verwenden.',
		thisMonth: 'Diesen Monat',
		thisWeek: 'Diese Woche',
		sharedInChat: 'Im Chat geteilt',
		handoverTo: 'Übergabe an',
		selectTheSomething: 'Wählen Sie {value}',
		handoverSession: 'Übergeben Sie die Sitzung',
		mobileApp: 'Mobile App',
		noSnooze: 'Kein Aufschub',
		triggerAlertsMoreThanOnePerson: 'Auslösen von Warnmeldungen, wenn sich mehr als 1 Person im Raum befindet',
		thresholdValues: 'Schwellenwerte',
		thresholdsForRailDown: 'Schwellen für die Senkung der Schiene',
		thresholdsForRailUp: 'Schwellen für das Anheben der Schiene',
		aiModelCheckTimer: 'KI-Modellprüfzeitgeber',
		triggerAlertsRailsCovered: 'Alarm auslösen, wenn die Schienen bedeckt sind',
		setAsDefault: 'Als Standard festlegen',
		enterNumber: 'Voer het nummer in',
		writeAndClickEnter: 'Schreiben und klicken Sie auf Enter',
		selectOneCheckbox: 'Bitte wählen Sie mindestens eine Option aus, wobei nur eine als Standard festgelegt ist',
		provideThreshold: 'Bitte geben Sie einen Wert für jeden der Schwellenwerte an',
		pleaseSelectOption: 'Bitte wählen Sie eine Option aus',
		fillRequiredFieldsAi: 'Bitte füllen Sie die erforderlichen Felder auf der Registerkarte "KI-Einstellungen" aus',
		preferredLanguage: 'Bevorzugte Sprache',
		updateRpmList: 'Für die RPM-Patientenliste sind neue Updates verfügbar',
		roundingWhiteboardDescription:
			'Wenn diese Option aktiviert ist, wird die Option zum Anzeigen und Aktualisieren des Whiteboards des Patienten während des Anrufs angezeigt.',
		sleepScheduleTitle: 'Schlafenszeitplan',
		sleepScheduleDescription:
			'Der Schlafplan schaltet den Bildschirm basierend auf den untenstehenden Einstellungen automatisch aus, und während dieser Zeit kann er durch Antippen des Bildschirms wieder eingeschaltet werden. Der Bildschirm geht basierend auf der Bildschirm-Zeitbegrenzung wieder in den Schlafmodus.',
		addSleepSchedule: 'Schlafplan hinzufügen',
		bodyPositionToSendAlert: 'Körperposition zum Senden von Alarmen {value}',
		side: 'Seite',
		down: 'Unten',
		inactiveTime: 'Inaktive Zeitbenachrichtigungs-Timer',
		aiAlertsDescription:
			'Wenn dies aktiviert ist, werden Übersichtsdaten für KI-Warnungen basierend auf Anwendungsfällen angezeigt.',
		patientInterventionsReport: 'Bericht über Patienteninterventionen',
		patientInterventionsReportDesc:
			'Wenn diese Funktion aktiviert ist, kann der Administrator/Superuser die von den Pflegeanbietern auf der hellocare-Plattform durchgeführten Interventionen gegenüber dem Patienten einsehen und die entsprechenden Interventionen in ein CSV-Dokument herunterladen.',
		dashboardHandHygiene: 'Händehygiene',
		hHDashboardDesc: 'Wenn dieses Feature aktiviert ist, kann der Administrator/Superuser Daten zur Händehygiene einsehen.',
		liveCaptions: 'Live-Untertitel',
		liveCaptionsEmptyMessage: 'Die Teilnehmer müssen sprechen, um Live-Untertitel zu erstellen.',
		scheduleNightVision: 'Nachtsicht planen',
		removeSchedule: 'Zeitplan entfernen',
		nightVisionError: 'Bitte geben Sie Start- und Endzeit ein, um fortzufahren!',
		assignDocModalDesc: `Die Funktion "Arzt hinzufügen" ermöglicht es dem Administrator, nach Ärzten zu suchen und diese dem Pool des Krankenhauses für die Zuordnung zu Patienten hinzuzufügen. Wenn der Arzt noch nicht im Pool ist, können Sie ihn hinzufügen, indem Sie seinen Namen aus der Dropdown-Liste auswählen. Nach dem Hinzufügen erscheinen die Ärzte im Tab "Arztpool" und sind während der Telemedizin-Sitzungen in der Patientenansicht zur Auswahl verfügbar.`,
		visitingHoursModalDesc: `Die Funktion "Besuchszeiten" ermöglicht es dem Administrator, die Zeiten zu verwalten und zu steuern, zu denen Besucher mit Patienten kommunizieren können. Administratoren können Besuchszeiten für jeden Wochentag konfigurieren, dieselben Zeiten für alle Tage anwenden und mehrere Zeitfenster pro Tag hinzufügen. Die Funktion bietet auch Flexibilität, um Besuchszeiten an bestimmten Tagen ein- oder auszuschalten und Einstellungen auf verschiedene Ebenen wie Krankenhaus oder Zimmer anzuwenden.`,
		pinLockModalDesc: `Benutzer können die Einstellungen des Hello-Geräts sichern, indem sie eine 4-stellige PIN festlegen, wodurch nur autorisierte Personen Änderungen vornehmen können. Diese Funktion ist über das Menü des Geräts zugänglich.`,
		timeZoneModalDesc: `Administratoren können die Zeitzone für alle Hello-Geräte in einem Krankenhaus festlegen. Nach der Festlegung werden alle Geräteaktivitäten gemäß der gewählten Zeitzone protokolliert, was eine genaue und konsistente Zeitmessung sicherstellt.`,
		unitPNModalDesc: `Die Funktion "Einheitstelefonnummer" ermöglicht es dem Administrator, eine gültige US-Telefonnummer für eine bestimmte Einheit (Abteilung) zuzuweisen und anzuzeigen. Diese Telefonnummer ist in den Abteilungsdetails sichtbar und ermöglicht dem Personal einen einfachen Zugriff zur Kontaktaufnahme mit der Einheit.`,
		nightVisionModalDesc: `Die Funktion "Nachtmodus planen" ermöglicht es Benutzern, eine bestimmte Startzeit für den Nachtmodus in einem Raum festzulegen. Der Nachtmodus wird automatisch zur geplanten Zeit basierend auf der lokalen Zeitzone des Raumes aktiviert, was die Funktionalität des Raums und die Patientenversorgung verbessert.`,
		boardTypeModalDesc: `Die Funktion "Tafeltyp" ermöglicht es dem Administrator, den passenden Whiteboard-Typ auszuwählen, um die Benutzeroberfläche/Erfahrung an ein bestimmtes Fachgebiet anzupassen. Dies stellt sicher, dass die Benutzeroberfläche den Bedürfnissen der ausgewählten Abteilung entspricht.`,
		assignPatientModalDesc: `Diese Funktion ermöglicht es Administratoren, einen Patienten einem bestimmten Raum zuzuweisen, was erforderlich ist, um bestimmte Funktionen zu aktivieren. Administratoren können einen bestehenden Patienten zuweisen oder hinzufügen, um sicherzustellen, dass alle relevanten Funktionen für den zugewiesenen Raum verfügbar sind.`,
		assignFamilyMemberModalDesc: `Die Funktion "Familienmitglied zuweisen" ermöglicht es Administratoren oder Gesundheitsdienstleistern, ein Familienmitglied einem Patientenraum zuzuordnen. Familienmitglieder können per E-Mail eingeladen werden, um in Kontakt zu bleiben und über die Plattform mit dem Patienten zu kommunizieren.`,
		assignVisitorModalDesc: `Die Funktion "Besucher zuweisen" ermöglicht es Administratoren oder Gesundheitsdienstleistern, einen Besucher einem Patientenraum zuzuordnen. Besucher können Patienten nur während der festgelegten Besuchszeiten kontaktieren. Einladungen werden per E-Mail gesendet, und nach Annahme wird der Besucher dem Patientenraum zugewiesen.`,
		assignedSessionFrom: 'Du hast eine zugewiesene Sitzung von',
		disregardOpenHospital: 'Wenn Sie es ignorieren und andere Räume überwachen möchten, bitte öffnen Sie ein Krankenhaus,',
		chooseDepartmentFloorRoom: 'wählen Sie die Abteilung, klicken Sie auf einen Stock und wählen Sie einen Raum aus.',
		whiteboardTooltip: 'Mit dieser Option können Sie das Whiteboard des Patienten anzeigen und bearbeiten.',
		callsNotifications: 'Anrufbenachrichtigungen',
		answeredCalls: 'Beantwortete Anrufe',
		missedCalls: 'Verpasste Anrufe',
		callBack: 'Rückruf',
		openSession: 'Sitzung öffnen',
		silentModeDescription:
			'Wenn dies aktiviert ist, können wir die KI ohne Überwachung des Patienten einschalten und Benachrichtigungen im lautlosen Modus zu Trainings- und Berichterstattungszwecken erhalten.',
		whiteboardDisplayControl: 'Whiteboard-Anzeigesteuerung',
		selectPatientPosition: ' Wählen Sie die Position des Patienten, um einen Alarm zu senden',
		patientPositions: 'Patientenposition',
		sendPatientPositionAlert: 'Für welche Patientenposition soll ein Alarm ausgelöst werden',
		upInAChair: 'Im Stuhl sitzen',
		supine: 'In Rückenlage',
		prone: 'In Bauchlage',
		lateral: 'Seitlich',
		screenTimeout: 'Bildschirm-Timeout',
		useRestroom: 'Musst du die Toilette benutzen?',
		needDrink: 'Brauchst du ein Getränk?',
		sorryDidNotHear: 'Es tut mir leid, ich habe dich nicht gehört. Bitte wiederhole deine Antwort.',
		needMeToRepeat: 'Möchtest du, dass ich wiederhole, was ich gesagt habe?',
		allowPatientSignUp: 'Patientenanmeldung ermöglichen',
		auditLog: 'Prüfprotokoll',
		waitingForResult: 'Warten auf Ergebnis',
		galaxyWatch: 'Galaxy Watch',
		monitoringFeature: 'Überwachungsfunktion',
		clientThemeColor: 'Kunden-Themenfarbe',
		twoFactorProviderType: 'Zweifaktor-Anbietertyp',
		lengthUnit: 'Längeneinheit',
		patientPrivateMode: 'Patientenprivatmodus',
		nICUChat: 'NICU-Chat',
		rpm: 'RPM',
		bloodGlucoseUnit: 'Blutzuckereinheit',
		heightUnit: 'Höheneinheit',
		temperatureUnit: 'Temperatureinheit',
		weightUnit: 'Gewichtseinheit',
		talkToPatientRoute: 'Gespräch mit dem Patientenroute',
		monitoringRoute: 'Überwachungsroute',
		viewPatientRoute: 'Patientenroute anzeigen',
		switchE2EE: 'Zu E2EE wechseln',
		pTZCam: 'PTZ-Kamera',
		helloCameraName: 'Hallo Kameraname',
		externalCameraName: 'Externer Kameraname',
		multipartyCalls: 'Mehrparteienanrufe',
		roundingSummary: 'Rundungsübersicht',
		screensharing: 'Bildschirmfreigabe',
		roundingNightVision: 'Rundungsnachtsicht',
		digitalClinicianMode: 'Digitaler Klinikmodus',
		liveTVWidget: 'Live-TV-Widget',
		roundingCareEventsForSafetyCompanions: 'Rundungspflegeereignisse für Sicherheitsbegleiter',
		healthSystemDefaultBackground: 'Standardhintergrund des Gesundheitssystems',
		callBackground: 'Anrufhintergrund',
		providerMicrophone: 'Anbietermikrofon',
		helloDefaultBackground: 'Hallo Standardhintergrund',
		patientAdtDashboard: 'Patienten-ADT-Dashboard',
		virtualPatientAdmission: 'Virtuelle Patientenaufnahme',
		manualPatientAdmission: 'Manuelle Patientenaufnahme',
		ehrPatientAdmission: 'Patientenaufnahme im EHR',
		isAudioCall: 'Ist es ein Audioanruf?',
		alertsCenterDashboard: 'Alarmzentrum-Dashboard',
		aiDetectionDrawings: 'KI-Erkennungszeichnungen',
		staffDuress: 'Mitarbeiter in Not',
		allowDataCollection: 'Daten­erfassung erlauben',
		verbalRedirection: 'Verbale Umleitung',
		hillRoom: 'Bergzimmer',
		patientsOverview: 'Übersicht über Patienten',
		patientsFiles: 'Patientenakten',
		patientsHealth: 'Gesundheit der Patienten',
		patientsPhysicalTherapy: 'Physiotherapie der Patienten',
		patientsVisits: 'Besuche der Patienten',
		patientsAlertHistory: 'Alarmhistorie der Patienten',
		isNewUserExperience: 'Ist es eine neue Benutzererfahrung?',
		isExperienceHealthSystemChangeable: 'Ist das Erlebnis des Gesundheitssystems änderbar?',
		providerCentricReport: 'Anbieterzentrierter Bericht',
		patientCentricReport: 'Patientenzentrierter Bericht',
		userAutoGeneratedPIN: 'Vom Benutzer automatisch generierter PIN',
		vcpRoleDisplayName: 'Anzeigename der VCP-Rolle',
		patientInfoBox: 'Patienteninformationsfeld',
		whiteboardBox: 'Pinnwandkasten',
		aiDetectionDrawing: 'KI-Erkennungszeichnung',
		soundOnAIAlert: 'Ton bei KI-Alarm',
		patientAdmission: 'Patientenaufnahme',
		whiteboardWidgetSettings: 'Einstellungen für Pinnwand-Widget',
		monitoringMessage: 'Überwachungsnachricht',
		whiteboardPiP: 'Pinnwand-PiP',
		whiteboardBoxRounding: 'Abrunden des Pinnwandkastens',
		careNotifications: 'Betreuungs Nachrichten',
		ir: 'IR',
		ct: 'CT',
		xray: 'Röntgen',
		orAcronym: 'OP',
		swallowTest: 'Schlucktest',
		mri: 'MRT',
		inHallway: 'Im Flur',
		cathLab: 'Katheterlabor',
		restroom: 'Toilette',
		restroomByRn: 'Toilette bei RN',
		s529: 'S529',
		j516: 'J516',
		dc: 'Entlassung',
		nuclearMed: 'Nuklearmedizin',
		epLab: 'EP-Labor',
		liveSitterUntilAm: 'Live-Sitter bis AM',
		s732: 'S732',
		giLab: 'GI-Labor',
		tee: 'TEE',
		pegTubePlacement: 'PEG-Schlauchplatzierung',
		moveTo527: 'Umzug nach 527',
		eeg: 'EEG',
		additionalDiet: 'Zusätzliche Ernährung',
		discontinueMonitoring: 'Überwachung beenden',
		behaviorsImproved: 'Verhalten verbessert',
		requiresOneOnOneObservation: 'Enge Einzelbeobachtung erforderlich',
		leftAma: 'Gegen ärztlichen Rat entlassen',
		familyPatientRefuses: 'Familie/Patient lehnt ab',
		shiftChange: 'Schichtwechsel',
		stopVideoMonitoring: 'Warum möchten Sie die Überwachung dieses Patienten beenden.',
		closingMonitoring: 'Videoüberwachung beenden',
		discontinuePatientMonitoring: 'Patientenüberwachung abbrechen',
		discontinuingMonitoringReason: 'Grund für die Beendigung der Überwachung',
		transferToAnotherUnit: 'Transfer zu einer anderen Einheit',
		selectedIncorrectRoom: 'Falscher Patient; wechsle zum richtigen Patienten',
		technicalIssue: 'Raum wegen technischer Probleme getrennt',
		patientDischargedDiscontinue: 'Patient entlassen. Bitte beenden Sie die Überwachung.',
		patientTransferredDiscontinue: 'Patient übertragen. Bitte überwachen Sie nicht mehr.',
		patientDischarged: 'Patient entlassen',
		workflow: 'Arbeitsablauf',
		aiNotifications: 'KI-Benachrichtigungen',
		whiteboardControls: 'Whiteboard-Steuerung',
		whiteboardControlsDesc: 'Wenn dies aktiviert ist, können Anbieter das Whiteboard über die Web-Benutzeroberfläche steuern',
		discontinueMonitoringDescription:
			'When this is enabled, providers will have to select a reason for discontinuing monitoring.',
		roomSign: 'Zimmerschild',
		roundingRoomSignDescription:
			'Wenn dies aktiviert ist, wird die Option angezeigt, das Zimmerschild des Patienten während und außerhalb des Anrufs anzuzeigen und zu aktualisieren.',
		careConsiderations: 'Pflegeüberlegungen',
		selectLevelofBradenScore: 'Wählen Sie das Niveau des Braden-Scores',
		hearingImpaired: 'Ist der Patient schwerhörig?',
		interpreterNeeded: 'Wird ein Dolmetscher benötigt?',
		whatLanguageIsNeeded: 'Welche Sprache wird benötigt?',
		noWhiteboardAssigned: 'Kein Begleitgerät in den Raum zugewiesen',
		invitedParticipants: 'Eingeladene Teilnehmer',
		cancelAllInvites: 'Alle Einladungen stornieren',
		cancelInviteDescription:
			'Wenn dies aktiviert ist, kann der Benutzer eine einzelne Einladung oder alle Einladungen stornieren.',
		invalidConferenceInvitation: 'Diese Einladung ist nicht mehr gültig.',
		invalidConferenceLink: 'Dieser Link ist nicht mehr gültig.',
		invalidConferenceParameters: 'Die Konferenzinformationen sind falsch.',
		addPeople: 'Personen Hinzufügen',
		sentInvites: 'Gesendete Einladungen',
		multipartyCalling: 'Mehrparteiengespräch',
		hospitalAtHome: 'Krankenhaus zu Hause',
		patientNameDescription:
			'Wenn dies aktiviert ist, sieht {roleNameNurse} "Patientenname" als Eingabe im Abschnitt Patientendaten.',
		patientAgeDescription:
			'Wenn dies aktiviert ist, sieht {roleNameNurse} "Patientenalter" als Eingabe im Abschnitt Patientendaten.',
		nurseNameNumberDescription:
			'Wenn dies aktiviert ist, sieht {roleNameNurse} "{roleNameNurse} Name/Nummer" als Eingabe im Abschnitt Patientendaten.',
		nurseAssistantNameNumberDescription:
			'Wenn dies aktiviert ist, sieht {roleNameNurse} "{roleNameNurse} Assistent Name/Nummer" als Eingabe im Abschnitt Patientendaten.',
		tlNameNumberDescription:
			'Wenn dies aktiviert ist, sieht {roleNameNurse} "Teamleiter Name/Nummer" als Eingabe im Abschnitt Patientendaten.',
		primaryAdverseEventDescription:
			'Wenn dies aktiviert ist, sieht {roleNameNurse} "Primäres unerwünschtes Ereignis" als Eingabe im Abschnitt Patientendaten.',
		primaryPatientConditionDescription:
			'Wenn dies aktiviert ist, sieht {roleNameNurse} "Primäre Patientenbedingung" als Eingabe im Abschnitt Patientendaten.',
		primaryPatientRiskFactorDescription:
			'Wenn dies aktiviert ist, sieht {roleNameNurse} "Primärer Risikofaktor des Patienten" als Eingabe im Abschnitt Patientendaten.',
		preferredLanguageDescription:
			'Wenn dies aktiviert ist, sieht {roleNameNurse} "Bevorzugte Sprache" als Eingabe im Abschnitt Patientendaten.',
		titleBarNotesDescription:
			'Wenn dies aktiviert ist, sieht {roleNameNurse} "Notizen zur Titelleiste" als Eingabe im Abschnitt Patientendaten.',
		additionalPatientNotesDescription:
			'Wenn dies aktiviert ist, sieht {roleNameNurse} "Zusätzliche Patientennotizen" als Eingabe im Abschnitt Patientendaten.',
		patientInfoWidget: 'Patienteninfo-Widget',
		patientInfoConfigsTitle:
			'Die folgenden Felder werden im Patienteninformationsfeld angezeigt, wenn sie aktiviert sind. Bitte aktivieren Sie die Felder, die Sie anzeigen möchten.',
		chooseEhrHellocare:
			'Verwenden Sie außerdem die Dropdown-Optionen für jedes Feld, um die Datenquelle zu konfigurieren (EHR oder Hellocare).',
		imbalance: 'Ungleichgewicht',
		imbalanceType: 'Ungleichgewichtstyp',
		recentMetrics: 'Aktuelle Messwerte',
		orders: 'Aufträge',
		schedule: 'Zeitplan',
		painScale: 'Schmerzskala',
		assignedSomething: 'Zugewiesen {value}',
		product: 'Produkt',
		sponsor: 'Sponsor',
		payer: 'Zahlerin',
		phaseOfCare: 'Phase der Pflege',
		admitDate: 'Aufnahmedatum',
		appointmentWith: 'Termin mit',
		timeADay: 'Zeit am Tag',
		dateOfBirthAge: 'Geburtsdatum (Alter)',
		timeAday: 'zeit am Tag',
		hospitalAtHomeDescription:
			'Durch die Aktivierung dieser Funktion können Pflegekräfte ihre Patienten zu Hause betrachten und eine anpassbare Schnittstelle zur effektiven Verwaltung von Patienteninformationen, Behandlungsplänen und Kommunikation bereitstellen.',
		currentPosition: 'Aktuelle Position',
		configuredFor: 'Konfiguriert für',
		configuredPositions: 'Konfigurierte Positionen',
		patientPositionMonitoring: 'Patientenposition',
		pressureInjuryStats: 'Druckverletzungsstatistiken',
		standing: 'Stehen',
		rightLateral: 'Rechtsseitig',
		leftLateral: 'Linksseitig',
		pressureInjuryHistoryDescription: 'Person wurde inaktiv erkannt für {value1} in Position {value2}.',
		pressureInjuryHistoryTime: 'Von {value1} bis {value2}',
		noHistoryFound: 'Es wurde keine Historie für diesen Patienten aufgezeichnet.',
		writeNewVerbalRedirection: 'Neue verbale Weiterleitung schreiben...',
		atLeastOne: 'Mindestens 1 muss ausgewählt werden.',
		onState: 'Ein',
		offState: 'Aus',
		isEnabledMessage: '{value1} gedreht {value2} {value3}',
		sensitivityOptionsMessage: '{value1} aktualisiert die Empfindlichkeit von {value2} zu {value3} für {value4}',
		snoozeTimeMessage: '{value1} aktualisiert den Schlummertimer von {value2} zu {value3} für {value4}',
		notificationOptionsMessage: '{value1} aktualisiert Alarme von {value2} zu {value3} für {value4}',
		statAlarmMessage: '{value1} aktualisiert Sofortalarm von {value2} zu {value3} für {value4}',
		railsOptionsMessage: '{value1} aktualisiert {value2} von {value3} zu {value4}',
		pressureInjuryPositionsMessage: '{value1} aktualisiert Patientenpositionen von {value2} zu {value3}',
		ivBagValueMessage: '{value1} aktualisiert Warnwert für die IV von {value2} zu {value3}',
		pressureInjuryTimerMessage: '{value1} aktualisierter Druckverletzungs-Timer von {value2} zu {value3}',
		atLeastOneMustBeSet: 'Mindestens eins muss festgelegt werden.',
		sleepScheduleTimeError: 'Die Endzeit muss mindestens 1 Minute nach der Startzeit liegen.',
		allHealthSystems: 'Alle Gesundheitssysteme',
		tomorrow: 'Morgen',
		never: 'Niemals',
		gobConfigurationMessage: '{value1} Konfiguration: Empfindlichkeit {value2}',
		alertConfigurationMessage: '{value1} Konfiguration: {value2}',
		lowFallRisk: 'Niedriges Sturzrisiko',
		moderateFallRisk: 'Mittleres Sturzrisiko',
		highFallRisk: 'Hohes Sturzrisiko',
		compliant: 'Konform',
		nonCompliant: 'Nicht Konform',
		totalIdentifications: 'Gesamtidentifizierungen',
		method: 'Methode',
		evidence: 'Beweis',
		handHygiene: 'Händehygiene',
		rn: 'RN',
		primaryDisplayLogo: 'Primäres Anzeige-Logo',
		primaryDisplayLogoDescription:
			'Laden Sie das Hauptlogo Ihres Krankenhauses hoch. Dies wird prominent auf der gesamten Plattform angezeigt.',
		secondaryDisplayLogo: 'Sekundäres Anzeige-Logo',
		secondaryDisplayLogoDescription:
			'Laden Sie ein sekundäres Logo hoch, das in bestimmten Bereichen oder Anzeigen verwendet wird.',
		compliance: 'Einhaltung',
		subjectIdentifications: 'Betreffidentifikationen',
		addTranslationService: 'Übersetzungsdienst hinzufügen',
		healthSystemMustBeSet: 'Gesundheitssystem muss eingestellt werden',
		evidenceReport: 'Beweisbericht',
		stats: 'Statistiken',
		skinTemperature: 'Hauttemperatur',
		customRingtoneDuration: 'Benutzerdefinierte Klingeltondauer',
		customRingtoneDurationDesc: 'Legen Sie die Klingeltondauer fest (in Sekunden)',
		ringtoneDuration: 'Klingelton Dauer',
		ringtone: 'Klingelton',
		addRingtoneConfig: 'Klingelton Konfiguration Hinzufügen',
		missingInformation: 'Konnte dem Anruf aufgrund fehlender Informationen nicht beitreten.',
		addUser: 'Benutzer Hinzufügen',
		employeeId: 'Mitarbeiter-ID',
		employeeIdCannotBeEmpty: 'Mitarbeiter-ID darf nicht leer sein',
		selectReasonPlease: 'Bitte wählen Sie einen Grund aus!',
		ecm: 'eCM',
		smartFHIR: 'SMART auf FHIR',
		somethingCalling: '{value} Ruft an',
		allPatients: 'Alle Patienten',
		aiVoiceAnalysis: 'KI-Sprachanalyse',
		aiVoiceDescription:
			'Wenn dies aktiviert ist, können Benutzer über KI benachrichtigt werden, wenn Krankenhausstimmen erkannt werden.',
		sendAiVoiceAlert: 'Für welche Stimmereignisse Benachrichtigungen erhalten',
		snoring: 'Schnarchen',
		coughing: 'Husten',
		cryingBaby: 'Weinendes Baby',
		screaming: 'Schreien',
		lowPriorityAlarm: 'Niedrigprioritätsalarm',
		mediumPriorityAlarm: 'Mittelprioritätsalarm',
		highPriorityAlarm: 'Hochprioritätsalarm',
		alarmOff: 'Alarm Aus',
		aiAudioAnalysisEvents: '{value1} Aktualisierte AI-Sprachereignisse {value2} zu {value3}',
		sneezingDetected: 'Niesen wurde erkannt.',
		snoringDetected: 'Schnarchen wurde erkannt.',
		coughingDetected: 'Husten wurde erkannt.',
		cryingBabyDetected: 'Babyweinen wurde erkannt.',
		screamingDetected: 'Schreien wurde erkannt.',
		helpDetected: 'Patientenhilfe wurde erkannt.',
		lowPriorityAlarmDetected: 'Niedrigprioritätsalarm wurde erkannt.',
		mediumPriorityAlarmDetected: 'Mittelprioritätsalarm wurde erkannt.',
		highPriorityAlarmDetected: 'Hochprioritätsalarm wurde erkannt.',
		alarmOffDetected: 'Alarm ausgeschaltet wurde erkannt.',
		vitalSignDetected: 'Sofortige Aufmerksamkeit: Vitalzeichenalarm erkannt.',
		vitalSignAlarm: 'Vitalzeichenalarm',
		alertDetected: '{value} wurde erkannt',
		alertsEvidenceConfigurations: 'Alarmbeweise Konfigurationen',
		alertsEvidenceDescription:
			'Wenn dies aktiviert ist, können Benutzer Beweisbilder im Zusammenhang mit einem ausgelösten KI-Alarm anzeigen.',
		otherOptions: 'Weitere optionen',
		helloAudioCallBackground: 'Hello-Audioanruf Hintergrund',
		helloAudioCallBackgroundDes:
			'Wählen Sie ein Bild aus, das während des Audiogesprächs im Hintergrund von Hello angezeigt wird',
		patientInfotainment: 'Patienten-Infotainment',
		movies: 'Filme',
		liveTvChannels: 'Live-TV-Kanäle',
		music: 'Musik',
		thumbnail: 'Miniaturbild',
		content: 'Inhalt',
		end: 'Ende',
		audio: 'Audio',
		stop: 'Stopp',
		liveExam: 'Live-Prüfung',
		noAddedReasons: 'Keine zusätzlichen Gründe',
		handsNotDisinfected: 'Hände nicht desinfiziert',
		railWasDetectedDown: 'Bettgitter wurde unten erkannt',
		patientWasDetectedFalling: 'Beim Patienten wurde ein Sturz festgestellt',
		patientWasDetectedGettingOutOfBed: 'Beim Aufstehen des Patienten wurde erkannt',
		forwardedToSomething: 'Weitergeleitet an {value}',
		addNewReason: 'Neue Option hinzufügen',
		maxNumberOfOptionsReached: 'Sie haben die maximale Anzahl an Optionen erreicht',
		aiAlertTypes: 'KI-Alarmtypen',
		aiAlertStats: 'KI-Alarm-Statistiken',
		falseAlerts: 'Falsche Alarme',
		optionAlreadyExists: 'Diese Option existiert bereits.',
		partnerName: 'Partnername',
		partnerNameCannotBeEmpty: 'Partnername darf nicht leer sein',
		enterValidUrl: 'Bitte geben Sie eine gültige URL ein',
		supportedFormats: `Unterstützte Formate sind: {value}`,
		link: 'Verknüpfung',
		virtualNurseAvatarDescription:
			'Die Avatar-Funktion „Virtuelle Krankenschwester“ ermöglicht es Patienten, mit einer KI-gestützten virtuellen Krankenschwester auf dem Bildschirm zu interagieren.',
		safeEnvironment: 'Sichere Umgebung',
		moderateEnvironment: 'Moderate Umgebung',
		loudEnvironment: 'Laute Umgebung',
		veryLoudEnvironment: 'Sehr laute Umgebung',
		calculatingDecibelLevel: 'Berechnung des Dezibelpegels',
		inviteInterpreter: 'Dolmetscher einladen',
		requestInterpreterServices: 'Dolmetscherdienste anfordern',
		inviteInterpreterDesc:
			'Sobald Sie einen Dolmetscher einladen, werden Sie mit einem Vertreter verbunden, der Ihnen bei der Auswahl Ihrer bevorzugten Sprache hilft.',
		languageLineDesc:
			'Bitte wählen Sie die Sprache, für die Sie Übersetzungsdienste benötigen. Nach der Anfrage wird ein Übersetzer zum Gespräch eingeladen.',
		noAccessFollowingRooms: 'hat keinen Zugang zu den folgenden Räumen:',
		accessMessageNotification:
			'Der ausgewählte {roleName} hat keinen Zugriff auf alle Räume in dieser Sitzung. Sie können Ihren Administrator benachrichtigen oder trotzdem mit der Übergabe fortfahren. Der {roleName} kann keine Räume überwachen, auf die er keinen Zugriff hat.',
		ptzConnected: 'Verbunden',
		ptzDisconnected: 'Getrennt',
		alertEmailsForOfflineDevices:
			'Geben Sie die E-Mail-Adressen ein, an die wir eine Warnung senden, wenn das Gerät 7 Minuten lang offline bleibt.',
		dispenserTimer: 'Spender-Timer',
		sinkTimer: 'Waschbecken-Timer',
		configureDisinfectTimer:
			'Konfiguriere einen Timer, wie lange die Hände in {value} desinfiziert werden sollen, damit die KI einen Alarm auslöst',
		helloBackground: 'Hallo Hintergrund',
		setHelloBackground: 'Wählen Sie ein Bild aus, das vor Beginn der Sitzung in der Ansicht des Patienten angezeigt wird.',
		transgenderFemale: 'Transgender Frau',
		transgenderMale: 'Transgender Mann',
		choseNotToDisclose: 'Möchte nicht angeben',
		nonBinaryOrGenderQueer: 'Nicht-binär oder Gender Queer',
		genderIdentity: 'Geschlechtsidentität',
		unableToConnectInterpreter: 'Verbindung zum Dolmetscherdienst nicht möglich. Bitte versuchen Sie es erneut.',
		addTranslatorInCall:
			'Zeitüberschreitung der Verbindung: Es konnte keine Verbindung zum Dolmetscherdienst hergestellt werden. Bitte überprüfen Sie Ihr Netzwerk und versuchen Sie es erneut oder kontaktieren Sie den Support, falls das Problem weiterhin besteht.',
		features: 'Funktionen',
		showFeatures: 'Funktionen anzeigen',
		genderIdentityDescription:
			'Wenn dies aktiviert ist, sieht {roleNameNurse} die Geschlechtsidentität des Patienten im Abschnitt Patientendaten.',
		patientGenderIdentity: 'Geschlechtsidentität des Patienten',
		evidenceNurse: '{roleNameNurse}/{roleNameVS}',
		evidenceNurseDescription:
			'Wenn dies aktiviert ist, sieht {roleNameNurse}/{roleNameVS} den Beweis, wenn ein Alarm ausgelöst wird.',
		evidenceAdminDescription: 'Wenn dies aktiviert ist, wird der Administrator die Beweise sehen, wenn ein Alarm ausgelöst wird.',
		vendor: 'Verkäufer',
		showAllPatientInfo: 'Alle Patienteninformationen anzeigen',
		hellocare: 'hellocare',
		communicateWithPatient: 'Um mit einem Patienten zu kommunizieren, wählen Sie bitte ein Gerät aus.',
		indicationForVideoMonitoring: 'Indication for Video Monitoring',
		indicationForVideoMonitoringDescription:
			'When this is enabled, nurses will see Indication for Video Monitoring within Patient Info Section',
		tvSetup: 'TV-Einrichtung',
		telehealthProfile: 'Telehealth-Profil',
		hellocareRingtone: 'hellocare Klingelton',
		tabletName: 'Tablet Name',
		nurseStation: 'Pflegestation',
		nurseStationDescription:
			'Wenn dies aktiviert ist, kann der Benutzer alle Tablets der Pflegestation für das ausgewählte Gesundheitssystem sehen.',
		assignNurseStation: 'Pflegestation zuweisen',
		searchByNameOrSn: 'Nach Name oder SN suchen',
		assignNewDevice: 'Neues Gerät zuweisen',
		selectDevice: 'Gerät auswählen',
		roomsWhichWillBeMonitored: 'Räume, die überwacht werden',
		unassignNST: 'Nurse Station Tablet abmelden?',
		unassignNSTConfirmation: 'Sind Sie sicher, dass Sie dieses Nurse Station Tablet abmelden möchten?',
		addToNurseStation: 'Zum Pflegeplatz hinzufügen',
		mustSelectDevice: 'Sie müssen ein Gerät auswählen.',
		mustSetName: 'Sie müssen einen Gerätenamen festlegen.',
		roomAmountError: 'Sie können nicht mehr als 32 Räume haben.',
		dataInput: 'Dateneingabe',
		pressureInjuryEvidenceTimer:
			'Konfiguriere den Timer für die Verlaufshistorie der Beweisführung bei Druckverletzungswarnungen',
	},
};
export default keys;
